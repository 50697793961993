import React, { useState } from 'react';
import { Droppable } from '@hello-pangea/dnd';
import { TaskCard } from './TaskCard.tsx';
import { Column, Task } from './types.ts';
import { UniqueIdGenerator } from '../../../utils/idGenerator.ts';
import { AddTaskModal } from './AddTaskModal.tsx';

interface KanbanColumnProps {
  column: Column;
  onEdit: (task: Task) => void;
}

export const KanbanColumn: React.FC<KanbanColumnProps> = ({ onEdit, column }) => {
  return (
    <div className="flex-1 min-w-[300px] flex flex-col bg-[#FAFAFA]/90 backdrop-blur-sm rounded-lg border border-[#E6A5B5] shadow-sm" style={{ isolation: 'isolate' }}>
      <h3 className="p-3 font-semibold text-gray-700 flex items-center justify-between border-b border-[#E6A5B5] bg-[#F7F3FB] rounded-t-lg">
        {column.title}
        <span className="bg-gray-200 text-gray-600 px-2 py-0.5 rounded text-sm">
          {column.tasks.length}
        </span>
      </h3>
      
      <Droppable droppableId={column.id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`
              flex-1 p-3 overflow-y-auto space-y-2 min-h-[100px]
              ${snapshot.isDraggingOver ? 'bg-pink-50/80' : 'bg-transparent'}
            `}
            style={{ 
              touchAction: 'none',
              position: 'relative',
              zIndex: snapshot.isDraggingOver ? -1 : 'auto'
            }}
          >
            {column.tasks.map((task, index) => (
              <>
                <TaskCard
                  key={task.id.toString()}
                  task={task}
                  index={index}
                  onEdit={onEdit}
                />
              </>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};