import React, { useEffect, useState } from 'react';
import { BudgetDonut } from './BudgetDonut.tsx';
import { BudgetDetails } from './BudgetDetails.tsx';
import { LocationSearch } from './LocationSearch.tsx';
import { BudgetPercentageEditor } from './BudgetPercentageEditor.tsx'
import { useBudgetContext } from '../../../context/BudgetContext.tsx';
import { getBudget, updateBudgetSections, updateTotalBudget } from '../../../utils/budget.ts';
import { Settings } from 'lucide-react';
import { BudgetCategory } from './types.ts';


export const BudgetView: React.FC = () => {
  const { budgetData, selectedCategory, updateBudget, selectCategory, updateLocation } = useBudgetContext();
  const [budget, setBudget] = useState<number>(0);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [categories, setCategories] = useState<BudgetCategory[]>(budgetData.categories);

  const colorDictionary = budgetData.categories.reduce((acc, category) => {
    acc[category.name] = category.color; // Map name to color
    return acc;
  }, {} as Record<string, string>); // Initialize as an empty object with string keys and values

  const factorsDictionary = budgetData.categories.reduce((acc, category) => {
    acc[category.name] = category.factors; // Map name to factors
    return acc;
  }, {} as Record<string, any[]>); // Initialize as an empty object with string keys and array values

  useEffect(() => {
    const fetchBudgetData = async () => {
      try {
        const budgetSections = await getBudget();
        const totalCost = budgetSections.reduce((acc, item) => acc + (item.cost || 0), 0);
        
        // Transform budgetSections to match BudgetCategory type
        const transformedCategories = budgetSections.map(item => ({
            id: item.id, // Assuming id should be a string
            name: item.name,
            amount: item.cost, // Assuming cost corresponds to amount
            percentage: item.percentage,
            color: colorDictionary[item.name], // Set a default color or modify as needed
            factors: factorsDictionary[item.name] // Set an empty array or populate as needed
        }) as BudgetCategory); // Cast to BudgetCategory

        setCategories(transformedCategories); // Set categories with the transformed data

        if (totalCost !== null) {
          setBudget(totalCost);
        }

      } catch (error) {
        console.error('Error fetching budget:', error);
      }
    };

    fetchBudgetData();
  }, []);

  const handleTotalBudgetChange = async (newTotal: number) => {
    setBudget(newTotal);
    await updateTotalBudget(newTotal)
  };

  const updateCategories = async (updatedCategories: BudgetCategory[]) => {
    // Calculate the total amount from updatedCategories
    const totalAmount = updatedCategories.reduce((acc, category) => {
      return acc + (category.amount || 0); // Sum up the amount property of each category
    }, 0);
    // Set the budget to the total amount
    setBudget(totalAmount);
    setCategories(updatedCategories)
    await updateBudgetSections(updatedCategories)

  };

  return (
    <div className="flex flex-col md:flex-row gap-8 p-4">
      <div className="w-full md:w-1/2">
        <div className="text-center mb-6">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3 sm:gap-2 mb-4 sm:mb-2">
          
            <button
              onClick={() => setIsEditorOpen(true)}
              className="w-full sm:w-auto flex items-center justify-center px-3 py-1.5 text-xs text-white font-medium
                bg-[#6E497E] rounded-full 
                hover:bg-[#5d3d6b]
                shadow-sm hover:shadow-md
                transition-all duration-300"
            >
              <Settings className="w-3.5 h-3.5 mr-1.5 text-white" />
              Edit Budgets
            </button>
          </div>
         
        </div>
        <BudgetDonut
          categories={categories}
          budget={budget}
          selectedCategory={selectedCategory}
          onSelectCategory={selectCategory}
          onUpdateBudget={updateBudget}
          onTotalBudgetChange={handleTotalBudgetChange}
        />
      </div>
      <div className="w-full md:w-1/2">
        <BudgetDetails
          category={selectedCategory}
          totalBudget={budget}
        />
      </div>

      <BudgetPercentageEditor
        isOpen={isEditorOpen}
        onClose={() => setIsEditorOpen(false)}
        categories={categories}
        totalBudget={budget}
        onSave={async (updatedCategories) => await updateCategories(updatedCategories)}
      />
    </div>
  );
};