import { Task } from './types';

type TaskColumns = {
  todo: Task[];
  inProgress: Task[];
  done: Task[];
};

export const initialTasks: TaskColumns = {
  todo: [
    {
      id: 'initial_task_1',
      title: 'Choose Wedding Venue',
      description: 'Research and visit potential wedding venues',
      dueDate: '2024-06-01',
      priority: 'high',
      status: 'todo',
      category_id: 'new',
      is_completed: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    },
    {
      id: 'initial_task_2',
      title: 'Book Photographer',
      description: 'Find and book wedding photographer',
      dueDate: '2024-05-15',
      priority: 'medium',
      status: 'todo',
      category_id: 'new',
      is_completed: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    },
    {
      id: 'initial_task_3',
      title: 'Select Wedding Dress',
      description: 'Schedule bridal boutique appointments',
      dueDate: '2024-04-30',
      priority: 'high',
      status: 'todo',
      category_id: 'new',
      is_completed: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    },
  ],
  inProgress: [
    {
      id: 'initial_task_4',
      title: 'Create Guest List',
      description: 'Compile names and addresses of guests',
      dueDate: '2024-03-20',
      priority: 'medium',
      status: 'inProgress',
      category_id: 'new',
      is_completed: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    },
    {
      id: 'initial_task_5',
      title: 'Design Invitations',
      description: 'Work with designer on invitation suite',
      dueDate: '2024-03-15',
      priority: 'medium',
      status: 'inProgress',
      category_id: 'new',
      is_completed: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    },
  ],
  done: [
    {
      id: 'initial_task_6',
      title: 'Set Wedding Date',
      description: 'Choose and confirm wedding date',
      dueDate: '2024-02-01',
      priority: 'high',
      status: 'done',
      category_id: 'new',
      is_completed: true,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    },
  ],
};