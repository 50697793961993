import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, Twitter, Mail } from 'lucide-react';

const Footer = () => {
  const openEmailClient = (event) => {
    event.preventDefault();
    
    var email = 'admin@wedify.ai';
    var subject = 'Support';
    var body = 'Hello Wedify Support,\n\nI need assistance with...';

    // Attempt to open Gmail compose window
    var gmailUrl = 'https://mail.google.com/mail/?view=cm&fs=1&to=' + encodeURIComponent(email) + '&su=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
    var gmailWindow = window.open(gmailUrl, '_blank');

    // If Gmail window fails to open, fall back to mailto link
    setTimeout(function() {
        if (!gmailWindow || gmailWindow.closed || typeof gmailWindow.closed == 'undefined') {
            var mailtoLink = 'mailto:' + email + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
            window.location.href = mailtoLink;
        }
    }, 500);
  }

  return (
    <footer className="bg-gradient-to-r from-gray-900 to-gray-800 text-gray-300">
      {/* Main Footer Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-16">
        <div className="grid grid-cols-3 gap-4 sm:gap-12">
          {/* Quick Links Section */}
          <div className="space-y-3 sm:space-y-4">
            <h3 className="text-gray-100 font-semibold text-sm sm:text-lg mb-2 sm:mb-4">Quick Links</h3>
            <ul className="space-y-1.5 sm:space-y-2">
            <li>
                <Link to="/design" className="text-gray-400 hover:text-gray-200 transition-all duration-300 hover:translate-x-1 inline-block text-xs sm:text-base">
               Plan
                </Link>
              </li>

              <li>
                <Link to="/design" className="text-gray-400 hover:text-gray-200 transition-all duration-300 hover:translate-x-1 inline-block text-xs sm:text-base">
                  Design
                </Link>
              </li>
              <li>
                <Link to="/explore" className="text-gray-400 hover:text-gray-200 transition-all duration-300 hover:translate-x-1 inline-block text-xs sm:text-base">
                  Explore
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal Section */}
          <div className="space-y-3 sm:space-y-4">
            <h3 className="text-gray-100 font-semibold text-sm sm:text-lg mb-2 sm:mb-4">Legal</h3>
            <ul className="space-y-1.5 sm:space-y-2">
              <li>
                <Link to="/privacy-policy" className="text-gray-400 hover:text-gray-200 transition-all duration-300 hover:translate-x-1 inline-block text-xs sm:text-base">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service" className="text-gray-400 hover:text-gray-200 transition-all duration-300 hover:translate-x-1 inline-block text-xs sm:text-base">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/pricing-plan" className="text-gray-400 hover:text-gray-200 transition-all duration-300 hover:translate-x-1 inline-block text-xs sm:text-base">
                  Pricing Plan
                </Link>
              </li>
            </ul>
          </div>

          {/* Connect Section */}
          <div className="space-y-3 sm:space-y-4">
            <h3 className="text-gray-100 font-semibold text-sm sm:text-lg mb-2 sm:mb-4">Connect With Us</h3>
            <div className="flex gap-2 sm:gap-4">
              <a 
                href="https://facebook.com/people/Wedifyai/61565660383511" 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-gray-800/50 hover:bg-gray-700/50 p-1.5 sm:p-2.5 rounded-lg border border-gray-700/50 hover:border-gray-600 transition-all duration-300 group"
              >
                <Facebook className="w-3.5 h-3.5 sm:w-5 sm:h-5 text-gray-400 group-hover:text-white transition-colors" />
              </a>
              <a 
                href="https://instagram.com/wedify.ai" 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-gray-800/50 hover:bg-gray-700/50 p-1.5 sm:p-2.5 rounded-lg border border-gray-700/50 hover:border-gray-600 transition-all duration-300 group"
              >
                <Instagram className="w-3.5 h-3.5 sm:w-5 sm:h-5 text-gray-400 group-hover:text-white transition-colors" />
              </a>
              <a 
                href="https://twitter.com/wedify_ai" 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-gray-800/50 hover:bg-gray-700/50 p-1.5 sm:p-2.5 rounded-lg border border-gray-700/50 hover:border-gray-600 transition-all duration-300 group"
              >
                <Twitter className="w-3.5 h-3.5 sm:w-5 sm:h-5 text-gray-400 group-hover:text-white transition-colors" />
              </a>
              <button 
                onClick={openEmailClient}
                className="bg-gray-800/50 hover:bg-gray-700/50 p-1.5 sm:p-2.5 rounded-lg border border-gray-700/50 hover:border-gray-600 transition-all duration-300 group"
                title="Contact Support"
              >
                <Mail className="w-3.5 h-3.5 sm:w-5 sm:h-5 text-gray-400 group-hover:text-white transition-colors" />
              </button>
            </div>
            <p className="text-gray-400 text-[10px] sm:text-sm mt-2 sm:mt-4">
              Have questions? We're here to help!
            </p>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="border-t border-gray-700/50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6">
          <p className="text-xs sm:text-sm text-gray-400 text-center">
            &copy; {new Date().getFullYear()} Wedify, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;