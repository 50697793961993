// Create a custom event emitter for credits
const creditEventEmitter = {
    subscribe: (callback) => {
      document.addEventListener('creditUpdate', (e) => callback(e.detail));
    },
    
    emit: (credits) => {
      document.dispatchEvent(new CustomEvent('creditUpdate', { detail: credits }));
    },
  
    unsubscribe: (callback) => {
      document.removeEventListener('creditUpdate', callback);
    }
  };
  
  export default creditEventEmitter;