import React, { createContext, useContext, useState, useCallback } from 'react';
import { Task, Column } from '../pages/plan/kanban/types.ts';
import { initialTasks } from '../pages/plan/kanban/initialData.ts';

interface TaskContextType {
  columns: Column[];
  setColumns: (columns: Column[]) => void;
  todoTasks: Task[];
  inProgressTasks: Task[];
  completedTasks: Task[];
  updateTask: (taskId: string, updates: Partial<Task>) => void;
  getTasksByDate: (date: Date) => Task[];
  weddingPlanTasks: Record<string, string[]>;
}

const TaskContext = createContext<TaskContextType | undefined>(undefined);

export const TaskProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [columns, setColumns] = useState<Column[]>([
    { id: 'todo', title: 'To Do', tasks: initialTasks.todo },
    { id: 'inProgress', title: 'In Progress', tasks: initialTasks.inProgress },
    { id: 'done', title: 'Done', tasks: initialTasks.done },
  ]);

  // Wedding plan tasks organized by category
  const weddingPlanTasks: Record<string, string[]> = {
    venue: [
      'Research and estimate costs for major budget items',
      'Discover your wedding style',
      'Explore and tour venues',
      'Book your reception venue',
      'Decide which vendors you\'ll need',
      'Research rehearsal dinner venues',
      'Schedule rehearsal dinner venue tours',
      'Book your rehearsal dinner venue',
      'Order rentals'
    ],
    website: [
      'Choose your wedding website platform',
      'Gather photos and content',
      'Create wedding website',
      'Add registry information',
      'Share website with guests',
      'Update website with event details'
    ],
    photos: [
      'Research photographers',
      'Meet with photographers',
      'Book your photographer',
      'Schedule engagement photos',
      'Research videographers',
      'Meet with videographers',
      'Book your videographer',
      'Create a list of photos you\'ll want captured'
    ],
    food: [
      'Research caterers',
      'Schedule tastings',
      'Book your caterer',
      'Choose your menu',
      'Plan bar service',
      'Research cake bakers',
      'Schedule cake tastings',
      'Order wedding cake'
    ],
    attire: [
      'Research bridal shops',
      'Schedule dress appointments',
      'Buy your wedding dress',
      'Choose wedding party attire',
      'Schedule fittings',
      'Purchase accessories',
      'Order wedding rings',
      'Final dress fitting'
    ],
    music: [
      'Research wedding bands/DJs',
      'Watch performers live',
      'Book ceremony musicians',
      'Book reception entertainment',
      'Create must-play song list',
      'Create do-not-play song list',
      'Choose special dance songs'
    ],
    flowers: [
      'Research and meet florists',
      'Book your florist',
      'Choose ceremony decorations',
      'Choose reception decorations',
      'Order wedding party flowers',
      'Plan centerpieces',
      'Create seating chart display'
    ],
    invites: [
      'Order save-the-dates',
      'Send save-the-dates',
      'Order wedding invitations',
      'Address invitations',
      'Mail invitations',
      'Track RSVPs',
      'Order ceremony programs',
      'Order menu cards',
      'Order thank you cards'
    ],
    planning: [
      'Create wedding budget',
      'Make guest list',
      'Choose wedding party',
      'Book hotel room blocks',
      'Plan rehearsal dinner',
      'Create day-of timeline',
      'Get marriage license',
      'Change name (if applicable)'
    ],
    honeymoon: [
      'Choose honeymoon destination',
      'Research accommodations',
      'Book flights',
      'Book hotels',
      'Plan activities',
      'Get passports/visas if needed',
      'Make dinner reservations',
      'Pack for honeymoon'
    ]
  };

  const todoTasks = columns.find(col => col.id === 'todo')?.tasks || [];
  const inProgressTasks = columns.find(col => col.id === 'inProgress')?.tasks || [];
  const completedTasks = columns.find(col => col.id === 'done')?.tasks || [];

  const updateTask = useCallback((taskId: string, updates: Partial<Task>) => {
    setColumns(prevColumns => 
      prevColumns.map(column => ({
        ...column,
        tasks: column.tasks.map(task => 
          task.id === taskId ? { ...task, ...updates } : task
        )
      }))
    );
  }, []);

  const getTasksByDate = useCallback((date: Date) => {
    const allTasks = [...todoTasks, ...inProgressTasks, ...completedTasks];
    return allTasks.filter(task => {
      if (!task.dueDate) return false;
      const taskDate = new Date(task.dueDate);
      return (
        taskDate.getFullYear() === date.getFullYear() &&
        taskDate.getMonth() === date.getMonth() &&
        taskDate.getDate() === date.getDate()
      );
    });
  }, [todoTasks, inProgressTasks, completedTasks]);

  return (
    <TaskContext.Provider value={{
      columns,
      setColumns,
      todoTasks,
      inProgressTasks,
      completedTasks,
      updateTask,
      getTasksByDate,
      weddingPlanTasks,
    }}>
      {children}
    </TaskContext.Provider>
  );
};

export const useTaskContext = () => {
  const context = useContext(TaskContext);
  if (!context) {
    throw new Error('useTaskContext must be used within a TaskProvider');
  }
  return context;
};