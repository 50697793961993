import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getAuth } from 'firebase/auth';
import './DiscordModal.css';

const DiscordModal = ({ isOpen, onClose, onSubmit, category, subcategory }) => {
  const [suggestion, setSuggestion] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      await onSubmit({
        category,
        subcategory,
        suggestion,
        userEmail: user?.email || 'Anonymous',
        additionalNotes
      });
      
      onClose();
    } catch (err) {
      setError('Failed to submit suggestion. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white w-full max-w-md rounded-2xl shadow-xl">
        <div className="p-6 space-y-6">
          <h3 className="text-2xl font-bold text-[#FF4D8D] text-center">
            Suggest New {subcategory}
          </h3>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="suggestion" className="block text-sm font-medium text-gray-700">
                Your Suggestion
              </label>
              <input
                id="suggestion"
                type="text"
                value={suggestion}
                onChange={(e) => setSuggestion(e.target.value)}
                placeholder={`Enter your ${subcategory.toLowerCase()} suggestion...`}
                className="w-full px-4 py-3 rounded-lg border border-[#FF4D8D]/20 focus:outline-none focus:border-[#FF4D8D] focus:ring-2 focus:ring-[#FF4D8D]/20 transition-colors"
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="additionalNotes" className="block text-sm font-medium text-gray-700">
                Additional Notes (Optional)
              </label>
              <textarea
                id="additionalNotes"
                value={additionalNotes}
                onChange={(e) => setAdditionalNotes(e.target.value)}
                placeholder="Any additional details or context..."
                rows={3}
                className="w-full px-4 py-3 rounded-lg border border-[#FF4D8D]/20 focus:outline-none focus:border-[#FF4D8D] focus:ring-2 focus:ring-[#FF4D8D]/20 transition-colors"
              />
            </div>
            {error && (
              <div className="text-red-500 text-sm">
                {error}
              </div>
            )}
            <div className="flex gap-3 pt-2">
              <button
                type="button"
                onClick={onClose}
                disabled={isSubmitting}
                className="flex-1 px-6 py-3 border border-[#FF4D8D]/20 text-[#FF4D8D] rounded-lg font-medium hover:bg-[#FF4D8D]/5 transition-colors disabled:opacity-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting || !suggestion.trim()}
                className="flex-1 px-6 py-3 bg-gradient-to-r from-[#FF69B4] to-[#FF7F50] text-white rounded-lg font-medium disabled:opacity-50 transition-all duration-300 hover:shadow-lg"
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

DiscordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  subcategory: PropTypes.string.isRequired
};

export default DiscordModal;