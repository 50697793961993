import { generateAuthToken } from '../utils/encryptExternalID'

export const fetchCredits = async (currentUser) => {
    if (!currentUser) return;
    
    try {
      const token = await generateAuthToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/login/generation/credits/left?authService=firebase&externalClientId=${currentUser.uid}`,
        {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'X-Auth-Token': token
          }
        }
      );

      if (response.ok) {
        return await response.text();
      }
    } catch (error) {
      console.error('Error fetching credits:', error);
    }
  };