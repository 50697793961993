import React, { useState, useEffect, useCallback } from 'react';
import { CircleUserRound, Bookmark, Heart, Trash2 } from 'lucide-react';
import Masonry from 'react-masonry-css';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import './UserProfile.css';
import Spinner from '../../components/spinner/Spinner';
import ImagePopup from '../explore/components/ImagePopup';
import { generateToken } from '../../services/shared/generateToken';
import { UserProfileAPI } from '../../services/api/userProfileAPI';

/**
 * UserProfile Component
 * Displays user's profile information, generated images, and liked images
 * Supports image management (viewing, deleting) and infinite scrolling
 */

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState('generated');
  const [userData, setUserData] = useState(null);
  const [bookmarks, setBookmarks] = useState([]);
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreImages, setHasMoreImages] = useState(true);
  const [likedImages, setLikedImages] = useState([]);
  const [likedImagesPage, setLikedImagesPage] = useState(0);
  const [hasMoreLikedImages, setHasMoreLikedImages] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1
  };

  /**
   * Initial user profile data fetch
   * Runs once on component mount
   * 1. Gets current user from Firebase Auth
   * 2. Fetches user document from Firestore
   * 3. Updates userData state or shows error
   */
  useEffect(() => {
    const fetchUserProfile = async () => {
      const auth = getAuth();
      const db = getFirestore();
      
      if (auth.currentUser) {
        try {
          const userDocRef = doc(db, "users", auth.currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserData(userData);
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
          setError(error.message);
        } finally {
          setIsInitialLoading(false);
        }
      }
    };

    fetchUserProfile();
  }, []);

  /**
   * Fetches user's generated images
   * Supports pagination and infinite scroll
   * 1. Generates authentication token
   * 2. Fetches images for current page
   * 3. Updates bookmarks state, either replacing or appending based on page
   * 4. Updates hasMoreImages flag for infinite scroll
   */
  const fetchUserData = useCallback(async () => {
    setIsContentLoading(true);
    setError(null);
    const auth = getAuth();
    
    try {
      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      const response = await UserProfileAPI.getUserImages(token, {
        externalClientId: auth.currentUser.uid,
        page: currentPage
      });

      if (Array.isArray(response)) {
        setBookmarks(prevBookmarks => currentPage === 0 ? response : [...prevBookmarks, ...response]);
        setHasMoreImages(response.length > 0);
      } else {
        console.error("Received data structure is unexpected:", response);
        setBookmarks([]);
        setHasMoreImages(false);
      }
    } catch (error) {
      console.error("Error fetching bookmarks:", error);
      setError(error.message);
    } finally {
      setIsContentLoading(false);
    }
  }, [currentPage]);

  /**
   * Fetches user's liked images
   * Similar to fetchUserData but for liked images
   * Supports pagination and infinite scroll
   */
  const fetchLikedImages = useCallback(async () => {
    setIsContentLoading(true);
    setError(null);
    const auth = getAuth();
    
    try {
      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      const response = await UserProfileAPI.getLikedImages(token, {
        externalClientId: auth.currentUser.uid,
        page: likedImagesPage
      });

      if (Array.isArray(response)) {
        setLikedImages(prevLikedImages => likedImagesPage === 0 ? response : [...prevLikedImages, ...response]);
        setHasMoreLikedImages(response.length > 0);
      } else {
        console.error("Received data structure is unexpected:", response);
        setLikedImages([]);
        setHasMoreLikedImages(false);
      }
    } catch (error) {
      console.error("Error fetching liked images:", error);
      setError(error.message);
    } finally {
      setIsContentLoading(false);
    }
  }, [likedImagesPage]);

  /**
   * Handles deletion of user-generated images
   * 1. Shows confirmation dialog
   * 2. Generates auth token
   * 3. Calls API to delete image
   * 4. Updates local state on success
   * @param {string} imageId - ID of image to delete
   */
  const handleDeleteGeneratedImage = async (imageId) => {
    if (!window.confirm('Are you sure you want to delete this image?')) {
      return;
    }

    try {
      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      const response = await UserProfileAPI.deleteGeneratedImage(token, imageId);

      if (response.status === 200) {
        setBookmarks(prevBookmarks => prevBookmarks.filter(bookmark => bookmark.id !== imageId));
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      alert(`Failed to delete the image. Error: ${error.message}`);
    }
  };

  /**
   * Handles removal of liked images
   * Similar to handleDeleteGeneratedImage but for liked images
   * @param {string} imageId - ID of image to unlike
   */
  const handleDeleteLikedImage = async (imageId) => {
    if (!window.confirm('Are you sure you want to remove this liked image?')) {
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('User not authenticated');
      return;
    }

    try {
      const token = await generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      const response = await UserProfileAPI.deleteLikedImage(token, {
        externalClientId: user.uid,
        imageId
      });

      if (response.status === 200) {
        setLikedImages(prevLikedImages => prevLikedImages.filter(image => image.id !== imageId));
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error removing liked image:', error);
    }
  };

  /**
   * Extracts hashtags from image metadata
   * 1. Parses selectedOptions (handles both string and object formats)
   * 2. Filters for relevant metadata keys
   * 3. Formats values as hashtags
   * @param {object|string} selectedOptions - Image metadata
   * @returns {string[]} Array of formatted hashtags
   */
  const extractHashtags = (selectedOptions) => {
    if (!selectedOptions) {
      return [];
    }

    let parsedOptions;
    if (typeof selectedOptions === 'string') {
      try {
        parsedOptions = JSON.parse(selectedOptions);
      } catch (error) {
        console.error('Error parsing selectedOptions:', error);
        return [];
      }
    } else {
      parsedOptions = selectedOptions;
    }

    // For Wedding Attire, we want to directly use the selections
    if (parsedOptions.attireType) {
      const attireSelections = Object.entries(parsedOptions)
        .filter(([key]) => key !== 'metadataValuesList') // Exclude metadataValuesList if present
        .map(([key, value]) => {
          if (key === 'color') {
            const colorValue = typeof value === 'object' ? value.name : value;
            return `#${colorValue.replace(/\s+/g, '')}`;
          }
          const cleanValue = String(value).replace(/[/\s]+/g, '');
          return `#${cleanValue}`;
        });
      return attireSelections;
    }

    let metadataValuesList = parsedOptions.metadataValuesList;
    
    if (!Array.isArray(metadataValuesList)) {
      return [];
    }
    
    const relevantKeys = [
        'theme', 
        'color', 
        'flowers', 
        'seating', 
        'venue',
        'greenery',
        'size',
        'shape',
        'flavor',
        'decoration',
        'fit', 
        'lapel', 
        'material', 
        'pattern', 
        'jacket',
        'style', 
        'neckline', 
        'fabric', 
        'length', 
        'details',
        'location', 
        'scene', 
        'decor', 
        'style'
    ];
    return metadataValuesList
      .filter(option => relevantKeys.includes(option.key))
      .map(option => {
        const capitalizedValue = option.value
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join('');
        const cleanedValue = option.key === 'seating' ? capitalizedValue.replace(/\(|\)/g, '') : capitalizedValue;
        return `#${cleanedValue.replace(/\s+/g, '')}`;
      });
  };

  /**
   * Renders individual image card with overlay
   * Includes hashtags, creation date, and delete button
   * @param {object} image - Image data to render
   * @param {boolean} isLiked - Whether image is from liked section
   * @returns {JSX.Element} Image card component
   */
  const renderBookmarkImage = (image, isLiked = false) => {
    const hashtags = extractHashtags(image.selectedOptions);
    
    return (
      <div key={image.id} className="grid-item">
        {image.s3Url ? (
          <img 
            src={image.s3Url} 
            alt={`Image ${image.id}`}
            onClick={() => setSelectedImage(image)}
            onError={(e) => {
              console.error(`Failed to load image: ${image.s3Url}`);
              e.target.style.display = 'none';
            }}
          />
        ) : (
          <div className="image-placeholder">Image not available</div>
        )}
        <div className="item-overlay">
          <div className="item-details">
            <div className="hashtags">
              {hashtags.length > 0 ? (
                hashtags.map((tag, index) => (
                  <span key={index} className="hashtag">{tag}</span>
                ))
              ) : (
                <span className="hashtag">#NoTagsAvailable</span>
              )}
            </div>
            <p>Created: {new Date(image.createdAt).toLocaleDateString()}</p>
          </div>
          <button 
            className="delete-button" 
            onClick={() => isLiked ? handleDeleteLikedImage(image.id) : handleDeleteGeneratedImage(image.id)}
          >
            <Trash2 size={16} />
          </button>
        </div>
      </div>
    );
  };

  /**
   * Pagination handlers
   * Increment page counters for infinite scroll
   */
  const loadMoreImages = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const loadMoreLikedImages = () => {
    setLikedImagesPage(prevPage => prevPage + 1);
  };

  /**
   * Effect to fetch appropriate data when tab changes
   * Calls either fetchUserData or fetchLikedImages based on activeTab
   */
  useEffect(() => {
    if (activeTab === 'generated') {
      fetchUserData();
    } else if (activeTab === 'likes') {
      fetchLikedImages();
    }
  }, [fetchUserData, fetchLikedImages, activeTab]);

  if (isInitialLoading) {
    return <Spinner text="Loading Profile..." />;
  }

  if (error && !userData) {
    return <div className="error">Error: {error}</div>;
  }

  if (!userData) {
    return <div className="not-found">User not found</div>;
  }

  /**
   * Main render
   * Displays:
   * 1. User profile header with user info
   * 2. Tab navigation between generated and liked images
   * 3. Masonry grid of images with infinite scroll
   * 4. Image popup for detailed view
   */
  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="profile-info">
          <div className="profile-avatar">
            <CircleUserRound size={40} className="avatar-icon" />
          </div>
          <div className="profile-details">
            <h1>{userData.name}</h1>
            <p className="email">{userData.email}</p>
            <p className="user-type">{userData.userType === 'vendor' ? 'Vendor' : 'Couple'}</p>
            <p className="bio">{userData.bio}</p>
            {userData.userType === 'vendor' && (
              <>
                <p><strong>Company Name:</strong> {userData.companyName}</p>
                <p><strong>Location:</strong> {userData.locationName}</p> {/* Changed from location to locationName */}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="tabs">
        <button
          className={`tab ${activeTab === 'generated' ? 'active' : ''}`}
          onClick={() => setActiveTab('generated')}
        >
          <Bookmark className="tab-icon" size={20} />
          Created By You
        </button>
        <button
          className={`tab ${activeTab === 'likes' ? 'active' : ''}`}
          onClick={() => setActiveTab('likes')}
        >
          <Heart className="tab-icon heart-icon" size={20} />
          Likes
        </button>
      </div>

      <div className="content-area">
        {isContentLoading ? (
          <div className="content-spinner">
            <Spinner text="Loading content..." />
          </div>
        ) : (
          <>
            {activeTab === 'generated' && (
              <>
                <Masonry
                  key={bookmarks.length}
                  breakpointCols={breakpointColumnsObj}
                  className="masonry-grid"
                  columnClassName="masonry-grid_column"
                >
                  {bookmarks.map((bookmark) => renderBookmarkImage(bookmark, false))}
                </Masonry>
                {hasMoreImages && (
                  <button className="load-more-button" onClick={loadMoreImages}>
                    Load More
                  </button>
                )}
              </>
            )}

            {activeTab === 'likes' && (
              <>
                <Masonry
                  key={likedImages.length}
                  breakpointCols={breakpointColumnsObj}
                  className="masonry-grid"
                  columnClassName="masonry-grid_column"
                >
                  {likedImages.map((image) => renderBookmarkImage(image, true))}
                </Masonry>
                {hasMoreLikedImages && (
                  <button className="load-more-button" onClick={loadMoreLikedImages}>
                    Load More Liked Images
                  </button>
                )}
              </>
            )}
          </>
        )}
      </div>

      {showPopup && selectedImage && (
        <ImagePopup
          image={selectedImage}
          onClose={() => {
            setShowPopup(false);
            setSelectedImage(null);
          }}
        />
      )}
    </div>
  );
};

export default UserProfile;
