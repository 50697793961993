import React, { useState } from 'react';
import { X, MapPin } from 'lucide-react';
import { CalendarEvent } from './types.ts';
import { createPortal } from 'react-dom';


interface AddEventModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddEvent: (task: CalendarEvent) => Promise<void>;
}

export const AddEventModal: React.FC<AddEventModalProps> = ({
  isOpen,
  onClose,
  onAddEvent,
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [location, setLocation] = useState('');
  const [isAllDay, setIsAllDay] = useState(false);
  const [priority, setPriority] = useState<'LOW' | 'MEDIUM' | 'HIGH'>('MEDIUM');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      if (!date) {
        throw new Error('Date is required');
      }

      // Create a new Date object for the selected date
      const eventDate = new Date(date);

      // Format start time if provided and not all day
      let formattedStartTime: string | undefined;
      if (!isAllDay && startTime) {
        const [hours, minutes] = startTime.split(':');
        const startDateTime = new Date(eventDate);
        startDateTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);
        formattedStartTime = startDateTime.toISOString();
      }

      // Format end time if provided and not all day
      let formattedEndTime: string | undefined;
      if (!isAllDay && endTime) {
        const [hours, minutes] = endTime.split(':');
        const endDateTime = new Date(eventDate);
        endDateTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);
        formattedEndTime = endDateTime.toISOString();
      }

      const newEvent: CalendarEvent = {
        id: Date.now().toString(),
        title,
        description,
        date: eventDate.toISOString().split('T')[0], // Format date as YYYY-MM-DD
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        location,
        isAllDay,
        priority,
      };

      await onAddEvent(newEvent);
      
      // Reset form
      setTitle('');
      setDescription('');
      setDate('');
      setStartTime('');
      setEndTime('');
      setLocation('');
      setIsAllDay(false);
      setPriority('MEDIUM');
      
      // Close modal
      onClose();
    } catch (error) {
      console.error('Error creating event:', error);
      // You might want to show an error message to the user here
      alert(error instanceof Error ? error.message : 'Failed to create event');
    }
  };

  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="w-[calc(100%-2rem)] sm:w-full max-w-[500px] relative animate-fadeIn rounded-2xl overflow-hidden
        bg-gradient-to-b from-[#E7D8F0] to-[#D0E3FF]
        backdrop-filter backdrop-blur-[12px]
        border border-[rgba(255,255,255,0.3)]
        shadow-[0px_4px_12px_rgba(231,216,240,0.2)]"
      >
        <div className="flex items-center justify-between px-4 sm:px-6 py-3 sm:py-4">
          <h2 className="text-base sm:text-[1.25rem] font-semibold text-[#3E3E3E]">Add New Event</h2>
          <button
            onClick={onClose}
            className="p-1 sm:p-1.5 hover:bg-white/10 rounded-full transition-colors"
          >
            <X className="w-4 h-4 sm:w-5 sm:h-5 text-[#3E3E3E]" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="px-4 sm:px-6 pb-4 sm:pb-6 space-y-3 sm:space-y-5">
          <div>
            <label className="block text-sm sm:text-[0.95rem] font-medium text-[#3E3E3E] mb-1.5 sm:mb-2">
              Title
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-3 sm:px-4 py-2 sm:py-3 bg-white/20 border border-[rgba(255,245,240,0.3)] 
                rounded-lg sm:rounded-xl focus:outline-none focus:ring-2 focus:ring-[#D0E3FF] focus:border-transparent
                text-[#3E3E3E] placeholder-[#3E3E3E]/50 text-sm sm:text-base"
              required
            />
          </div>

          <div>
            <label className="block text-sm sm:text-[0.95rem] font-medium text-[#3E3E3E] mb-1.5 sm:mb-2">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-3 sm:px-4 py-2 sm:py-3 bg-white/20 border border-[rgba(255,245,240,0.3)]
                rounded-lg sm:rounded-xl focus:outline-none focus:ring-2 focus:ring-[#D0E3FF] focus:border-transparent
                text-[#3E3E3E] placeholder-[#3E3E3E]/50 text-sm sm:text-base"
              rows={2}
            />
          </div>

          <div className="grid grid-cols-2 gap-3 sm:gap-4">
            <div>
              <label className="block text-sm sm:text-[0.95rem] font-medium text-[#3E3E3E] mb-1.5 sm:mb-2">
                Start Time
              </label>
              <input
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="w-full px-3 sm:px-4 py-2 sm:py-3 bg-white/20 border border-[rgba(255,245,240,0.3)]
                  rounded-lg sm:rounded-xl focus:outline-none focus:ring-2 focus:ring-[#D0E3FF] focus:border-transparent
                  text-[#3E3E3E] [color-scheme:light] text-sm sm:text-base"
                disabled={isAllDay}
              />
            </div>
            <div>
              <label className="block text-sm sm:text-[0.95rem] font-medium text-[#3E3E3E] mb-1.5 sm:mb-2">
                End Time
              </label>
              <input
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                className="w-full px-3 sm:px-4 py-2 sm:py-3 bg-white/20 border border-[rgba(255,245,240,0.3)]
                  rounded-lg sm:rounded-xl focus:outline-none focus:ring-2 focus:ring-[#D0E3FF] focus:border-transparent
                  text-[#3E3E3E] [color-scheme:light] text-sm sm:text-base"
                disabled={isAllDay}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm sm:text-[0.95rem] font-medium text-[#3E3E3E] mb-1.5 sm:mb-2">
              Date
            </label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="w-full px-3 sm:px-4 py-2 sm:py-3 bg-white/20 border border-[rgba(255,245,240,0.3)]
                rounded-lg sm:rounded-xl focus:outline-none focus:ring-2 focus:ring-[#D0E3FF] focus:border-transparent
                text-[#3E3E3E] [color-scheme:light] text-sm sm:text-base"
              required
            />
          </div>

          <div>
            <label className="block text-sm sm:text-[0.95rem] font-medium text-[#3E3E3E] mb-1.5 sm:mb-2">
              Location
            </label>
            <div className="relative">
              <MapPin className="absolute left-3 sm:left-4 top-1/2 -translate-y-1/2 w-4 h-4 sm:w-5 sm:h-5 text-[#D0E3FF]" />
              <input
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="w-full pl-10 sm:pl-12 pr-3 sm:pr-4 py-2 sm:py-3 bg-white/20 border border-[rgba(255,245,240,0.3)]
                  rounded-lg sm:rounded-xl focus:outline-none focus:ring-2 focus:ring-[#D0E3FF] focus:border-transparent
                  text-[#3E3E3E] placeholder-[#3E3E3E]/50 text-sm sm:text-base"
                placeholder="Add location"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="allDay"
                checked={isAllDay}
                onChange={(e) => setIsAllDay(e.target.checked)}
                className="h-3.5 w-3.5 sm:h-4 sm:w-4 text-[#D0E3FF] focus:ring-[#D0E3FF] border-[rgba(255,245,240,0.3)] rounded"
              />
              <label htmlFor="allDay" className="ml-2 text-sm sm:text-[0.95rem] text-[#3E3E3E]">
                All day
              </label>
            </div>

            <div className="flex items-center space-x-2 sm:space-x-3">
              <label className="text-sm sm:text-[0.95rem] text-[#3E3E3E]">Priority:</label>
              <select
                value={priority}
                onChange={(e) => setPriority(e.target.value as 'LOW' | 'MEDIUM' | 'HIGH')}
                className="px-2 sm:px-4 py-1.5 sm:py-2 bg-white/20 border border-[rgba(255,245,240,0.3)]
                  rounded-lg sm:rounded-xl focus:outline-none focus:ring-2 focus:ring-[#D0E3FF] focus:border-transparent
                  text-[#3E3E3E] text-sm sm:text-base"
              >
                <option value="LOW">Low</option>
                <option value="MEDIUM">Medium</option>
                <option value="HIGH">High</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end space-x-2 sm:space-x-3 pt-3 sm:pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-3 sm:px-4 py-1.5 sm:py-2 text-xs sm:text-sm bg-[#E7D8F0] text-[#3E3E3E] rounded-lg sm:rounded-[16px] 
                border border-[rgba(255,255,255,0.3)]
                hover:bg-[#E7D8F0]/80 transition-all duration-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-3 sm:px-4 py-1.5 sm:py-2 text-xs sm:text-sm bg-gradient-to-r from-[#FF7F8E] to-[#FFD6B6] text-white 
                rounded-lg sm:rounded-[16px] hover:shadow-[0px_4px_8px_rgba(255,127,142,0.3)] 
                transition-all duration-200 font-medium"
            >
              Add Event
            </button>
          </div>
        </form>
      </div>
    </div>,
    document.body
  );
};