import React from 'react';
import { Trash2 } from 'lucide-react';
import { Droppable } from '@hello-pangea/dnd';

interface DeleteZoneProps {
  isDraggingTask: boolean;
}

export const DeleteZone: React.FC<DeleteZoneProps> = ({ isDraggingTask }) => {
  return (
    <Droppable droppableId="delete-zone">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={`
            inline-flex items-center justify-center px-3 py-2 rounded-lg border-2 border-dashed
            transition-colors duration-200 ease-in-out
            ${isDraggingTask 
              ? 'border-red-300 bg-red-50' 
              : 'border-transparent bg-transparent pointer-events-none'
            }
            ${snapshot.isDraggingOver 
              ? 'border-red-500 bg-red-100' 
              : ''
            }
          `}
          style={{
            width: '100px',
            height: '40px',
            position: 'relative',
          }}
        >
          <div 
            className={`
              absolute inset-0 flex items-center justify-center
              transition-transform duration-200
              ${snapshot.isDraggingOver ? 'scale-110' : 'scale-100'}
            `}
          >
            <Trash2 className={`w-4 h-4 transition-colors duration-200 ${
              snapshot.isDraggingOver ? 'text-red-600' : 'text-red-400'
            }`} />
            <span className={`ml-1 text-xs font-medium transition-colors duration-200 ${
              snapshot.isDraggingOver ? 'text-red-600' : 'text-red-400'
            }`}>
              Delete
            </span>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};