import React from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth } from 'date-fns';
import { DisplayableItem } from './types';

interface MonthCalendarProps {
  month: Date;
  events: DisplayableItem[];
  isCurrentDay: (date: Date) => boolean;
  getEventsForDate: (date: Date) => DisplayableItem[];
  getPriorityColor: (priority: 'LOW' | 'MEDIUM' | 'HIGH') => string;
}

const WEEKDAYS = [
  { key: 'sunday', label: 'S' },
  { key: 'monday', label: 'M' },
  { key: 'tuesday', label: 'T' },
  { key: 'wednesday', label: 'W' },
  { key: 'thursday', label: 'T' },
  { key: 'friday', label: 'F' },
  { key: 'saturday', label: 'S' },
];

export const MonthCalendar: React.FC<MonthCalendarProps> = ({ 
  month, 
  events,
  isCurrentDay,
  getEventsForDate,
  getPriorityColor
}) => {
  const days = eachDayOfInterval({
    start: startOfMonth(month),
    end: endOfMonth(month)
  });

  return (
    <div className="bg-white rounded-lg shadow-lg border border-gray-100 h-full flex flex-col">
      <div className="text-center p-2 border-b border-gray-100">
        <h2 className="text-xl font-semibold text-gray-800">
          {format(month, 'MMMM yyyy')}
        </h2>
      </div>
      
      <div className="grid grid-cols-7 gap-px flex-1 overflow-y-auto">
        {WEEKDAYS.map(day => (
          <div key={day.key} className="text-center text-xs font-medium text-gray-500 py-1 border-b border-gray-100">
            {day.label}
          </div>
        ))}
        
        {days.map(day => {
          const dayItems = getEventsForDate(day);
          
          return (
            <div
              key={day.toISOString()}
              className={`
                relative h-14 p-0.5 border-b border-r border-gray-200
                ${isCurrentDay(day) ? 'bg-gradient-to-br from-[#B8A7E9]/10 to-[#D5CCF1]/10' : ''}
              `}
            >
              <div className={`
                text-[10px] font-medium mb-0.5 
                ${isCurrentDay(day) 
                  ? 'bg-gradient-to-r from-[#B8A7E9] to-[#D5CCF1] text-white w-4 h-4 rounded-full flex items-center justify-center' 
                  : 'text-gray-700'}
              `}>
                {format(day, 'd')}
              </div>
              <div className="space-y-0.5 max-h-[calc(100%-1.5rem)]">
                {dayItems.map(item => (
                  <div
                    key={item.id}
                    className={`text-[8px] px-0.5 py-0.5 rounded truncate ${getPriorityColor(item.priority)} ${
                      item.isTask ? 'bg-opacity-20' : 'bg-opacity-10'
                    }`}
                  >
                    <div className="flex items-center gap-0.5">
                      <div className={`w-1 h-1 rounded-full ${getPriorityColor(item.priority)}`} />
                      <span className="font-medium text-gray-900">
                        {item.isTask ? '📋 ' : ''}{item.title}
                      </span>
                    </div>
                    {!item.isAllDay && item.startTime && (
                      <div className="text-[7px] text-gray-500 ml-1.5">
                        {format(new Date(item.startTime), 'h:mm a')}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};