import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DesktopDesign } from '../../../components/design/desktopDesign.tsx';
import { MobileDesign } from '../../../components/design/mobileDesign.tsx';
import getImageUrl from '../../../utils/imageUtils';
import { getAuth } from 'firebase/auth';
import { fetchUserCredits, updateCreditsAfterGeneration } from '../../../services/creditsService';
import { generateToken } from '../../../services/shared/generateToken';
import { generateImage, ModelOptions } from '../../../services/api/designApi';
import { uploadBookmark } from '../../../services/api/bookmarkApi';
import { useSharedDesignState } from '../../../hooks/useSharedDesignState';

const Florals = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // Use shared state
  const {
    selections,
    subSelections,
    previewItems,
    setSelections,
    setSubSelections,
    setPreviewItems,
  } = useSharedDesignState('Florals');

  // Local state
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [credits, setCredits] = useState(null);

  const greeneryOptions = [
    { name: 'Eucalyptus', image: getImageUrl('leaves/Eucalyptus.jpg') },
    { name: 'Ruscus', image: getImageUrl('leaves/Ruscus.jpg') },
    { name: 'Dusty Miller', image: getImageUrl('leaves/DustyMiller.jpg') },
    { name: 'Salal Lemon Leaf', image: getImageUrl('leaves/SalalLemonLeaf.jpg') },
    { name: 'Leaf Fern', image: getImageUrl('leaves/LeafFern.jpg') },
    { name: 'Olive Branches', image: getImageUrl('leaves/OliveBranches.jpg') },
    { name: 'Myrtle', image: getImageUrl('leaves/Myrtle.jpg') },
    { name: 'Ivy', image: getImageUrl('leaves/Ivy.jpg') },
    { name: 'Aspidistra', image: getImageUrl('leaves/Aspidistra.jpg') },
  ];

  const flowerOptions = [
    { name: 'Wisteria', image: getImageUrl('wisteria.jpg') },
    { name: 'Hydrangeas', image: getImageUrl('hydrangea.jpg') },
    { name: 'Roses', image: getImageUrl('roses.jpg') },
    { name: 'Tulips', image: getImageUrl('tulips.jpg') },
    { name: "Baby's Breath", image: getImageUrl('BabysBreath.jpg') },
    { name: 'Orchids', image: getImageUrl('Orchids.jpg') },
    { name: 'Marigolds', image: getImageUrl('Marigolds.jpg') },
    { name: 'Lilies', image: getImageUrl('Lilies.jpg') },
    { name: 'Peonies', image: getImageUrl('Peonies.jpg') },
    { name: 'Sunflowers', image: getImageUrl('Sunflowers.jpg') },

  ];

  const colorPresets = [
    { name: 'Gold', hex: '#dcc282' },
    { name: 'Silver', hex: '#C0C0C0' },
    { name: 'Blush Pink', hex: '#FFB6C1' },
    { name: 'Sage Green', hex: '#9DC183' },
    { name: 'Navy Blue', hex: '#000080' },
    { name: 'Burgundy', hex: '#800020' },
    { name: 'Dusty Blue', hex: '#6699CC' },
    { name: 'Champagne', hex: '#F7E7CE' },
    { name: 'Lavender', hex: '#E6E6FA' },
    { name: 'Pearl White', hex: '#F0EAD6' },
    { name: 'Terracotta', hex: '#E2725B' },
    { name: 'Rust', hex: '#B7410E' },
    { name: 'Gray', hex: '#808080' },
    { name: 'Emerald', hex: '#50C878' },
    { name: 'Coral', hex: '#FF7F50' },
    { name: 'Mint', hex: '#98FF98' },
    { name: 'Peach', hex: '#FFE5B4' },
  ];

  const steps = [
    {
      id: 'flowers',
      title: 'Select Main Flowers',
      type: 'grid',
      options: flowerOptions,
      allowCustom: true,
      allowMultiple: true,
      maxSelections: 2,
    },
    {
      id: 'greenery',
      title: 'Choose Your Greenery',
      type: 'grid',
      options: greeneryOptions,
      allowCustom: true,
      allowMultiple: true,
      maxSelections: 2,
    },
    {
      id: 'color',
      title: 'Choose Your Color Scheme',
      type: 'color-picker',
      options: colorPresets,
      allowMultiple: true,
      maxSelections: 3,
    },
  ];

  const handleSelectionChange = (stepId, value) => {
    if (stepId === 'color' || stepId === 'flowers' || stepId === 'greenery') {
      // Handle multiple selections
      setSelections((prev) => {
        const currentSelections = prev[stepId] || [];
        const updatedSelections = currentSelections.includes(value)
          ? currentSelections.filter((item) => item !== value)
          : [...currentSelections, value];

        const maxSelections = steps.find((step) => step.id === stepId)?.maxSelections || Infinity;
        if (updatedSelections.length > maxSelections) {
          setError(`You can only select up to ${maxSelections} ${stepId}.`);
          return prev;
        }

        return { ...prev, [stepId]: updatedSelections };
      });
    } else {
      setSelections((prev) => ({ ...prev, [stepId]: value }));
    }
  };

  const handleSubSelectionChange = (stepId, value) => {
    setSubSelections((prev) => ({ ...prev, [stepId]: value }));
  };

  const generateVisualizations = async () => {
    const isAllSelectionsComplete = steps.every((step) => selections[step.id]);

    if (!isAllSelectionsComplete) {
      setError('Please complete all design options');
      return;
    }

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      setError('Please log in to generate designs');
      return;
    }

    const userCredits = await fetchUserCredits(currentUser.uid);
    if (userCredits < 30) {
      setError('Out of credits, Please Purchase Credits');
      return;
    }

    setLoading(true);
    setProgress(0);
    setError('');

    try {


      // Handle color properly - it might be an array or a single color
      let colorScheme;
      if (Array.isArray(selections.color)) {
        // If it's an array of colors
        colorScheme = selections.color.map(color => 
          typeof color === 'object' ? color.name : color
        ).join(' and ');
      } else if (typeof selections.color === 'object') {
        // If it's a single color object
        colorScheme = selections.color.name || selections.color.hex;
      } else {
        // If it's a single color string
        colorScheme = selections.color;
      }


      const bouquetPrompt = `A beautiful wedding bouquet with ${selections.flowers.join(' and ')} and ${selections.greenery.join(' and ')} in a ${colorScheme} color scheme, professional floral photography`;
      const boutonnierePromptText = `A wedding boutonniere with ${selections.greenery.join(' and ')} greenery and ${selections.flowers.join(' and ')} flowers in ${colorScheme} color scheme, professional photography, high end floral design`;


      setProgress(10);

      const token = await generateToken();

      const [bouquetImageUrl, boutonniereImageUrl] = await Promise.all([
        generateImage(bouquetPrompt, token, currentUser.uid, ModelOptions.LIGHTING),
        generateImage(boutonnierePromptText, token, currentUser.uid, ModelOptions.LIGHTING),
      ]);

      setPreviewItems([
        { url: bouquetImageUrl, label: 'Bridal Bouquet', prompt: bouquetPrompt },
        { url: boutonniereImageUrl, label: 'Boutonniere', prompt: boutonnierePromptText },
      ]);

      setProgress(100);

      const updatedCredits = await updateCreditsAfterGeneration(currentUser.uid);
      setCredits(updatedCredits);
    } catch (error) {
      console.error('Error generating visualizations:', error);
      setError('Failed to generate visualizations');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (item) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        setError('Please log in to save designs');
        return;
      }

      await uploadBookmark(item, 'Florals', selections, currentUser);
    } catch (error) {
      console.error('Error saving design:', error);
      setError('Failed to save design');
    }
  };

  return (
    <>
      {isMobile ? (
        <MobileDesign
          steps={steps}
          title="Design Your Wedding Florals"
          selections={selections}
          subSelections={subSelections}
          previewItems={previewItems}
          loading={loading}
          progress={progress}
          error={error}
          credits={credits}
          onSelectionChange={handleSelectionChange}
          onSubSelectionChange={handleSubSelectionChange}
          onGenerate={generateVisualizations}
          onSave={handleSave}
        />
      ) : (
        <DesktopDesign
          steps={steps}
          title="Design Your Wedding Florals"
          selections={selections}
          subSelections={subSelections}
          previewItems={previewItems}
          loading={loading}
          progress={progress}
          error={error}
          credits={credits}
          onSelectionChange={handleSelectionChange}
          onSubSelectionChange={handleSubSelectionChange}
          onGenerate={generateVisualizations}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default Florals;