import { generateToken } from '../../services/shared/generateToken';
import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Fetches paginated images from the explore endpoint
 * @param {Object} options - Query options
 * @param {number} [options.page=0] - Page number to fetch (defaults to 0)
 * @returns {Promise<Object>} - Returns paginated image data with metadata
 * 
 */
export const getExploreImages = async ({ page = 0 } = {}) => {
  try {
    // Get authentication token for API request
    const token = await generateToken();

    // Make GET request to explore images endpoint
    const response = await axios.get(`${API_BASE_URL}/api/images/get/explore/pagination`, {
      params: { page, size: 12}, // 12 images per page
      headers: {
        'X-Auth-Token': token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    // Handle array response type
    if (!Array.isArray(response.data)) {
      throw new Error('Invalid response structure from API');
    }
    return {
      data: response.data,
      pagination: {
        isLastPage: response.headers['x-last-page'] === 'true'
      }
    };
    
  } catch (error) {
    throw error;
  }
};

/**
 * ExploreAPI object containing all explore-related API functions
 */
export const ExploreAPI = {
  getExploreImages,

  /**
   * Adds an image to user's favorites
   * @param {string} token - Authentication token
   * @param {Object} params - Request parameters
   * @param {string} params.externalClientId - User's Firebase ID
   * @param {string} params.imageId - ID of image to favorite
   * @returns {Promise<Object>} - API response
   */
  addToFavorites: async (token, { externalClientId, imageId }) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/favorites/add/external`,
        null, // No body needed
        {
          params: {
            externalClientId,
            imageId,
            authenticationService: 'firebase'
          },
          headers: { 
            'X-Auth-Token': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Removes an image from user's favorites
   * @param {string} token - Authentication token
   * @param {Object} params - Request parameters
   * @param {string} params.externalClientId - User's Firebase ID
   * @param {string} params.imageId - ID of image to unfavorite
   * @returns {Promise<Object>} - API response
   */
  removeFromFavorites: async (token, { externalClientId, imageId }) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/api/favorites/delete/external`,
        {
          params: {
            externalClientId,
            imageId,
            authenticationService: 'firebase'
          },
          headers: { 
            'X-Auth-Token': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default ExploreAPI;
