import React, { useState } from 'react';
import { 
  X, 
  Palette, 
  Flower2 as Flower, 
  Armchair as Chair, 
  MapPin, 
  Sparkles, 
  Heart, 
  Share2, 
  Twitter, 
  Facebook, 
  Instagram, 
  Square, 
  Utensils, 
  Scissors, 
  Shirt, 
  Tag,
  ArrowRight,
  ArrowLeft
} from 'lucide-react';
import './ImageDetailsModal.css';

const ImageDetailsModal = ({ image, onClose, onLike, isLiked }) => {
  const [localLiked, setLocalLiked] = useState(isLiked);

  // Share text template
  const shareText = `Check out this amazing wedding decor inspiration! ${image.category || 'Wedding Decor'} - Style: ${image.style || 'Modern'}`;
  const shareUrl = window.location.href; // Current URL

  // Social share handlers
  const handleFacebookShare = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`;
    window.open(url, '_blank', 'width=600,height=400');
  };

  const handleTwitterShare = () => {
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank', 'width=600,height=400');
  };

  const handleInstagramShare = () => {
    // Instagram doesn't support direct sharing via URL
    // We can copy the share text to clipboard instead
    navigator.clipboard.writeText(`${shareText}\n${shareUrl}`).then(() => {
      alert('Share text copied to clipboard! Open Instagram to share.');
    });
  };

  const handleGenericShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Wedding Decor Inspiration',
          text: shareText,
          url: shareUrl,
        });
      } catch (error) {
        console.log('Error sharing:', error);
      }
    } else {
      // Fallback to copying to clipboard
      navigator.clipboard.writeText(`${shareText}\n${shareUrl}`).then(() => {
        alert('Share link copied to clipboard!');
      });
    }
  };

  const parseMetadata = (selectedOptions) => {
    let metadataValuesList;

    if (typeof selectedOptions === 'string') {
      try {
        const parsedOptions = JSON.parse(selectedOptions);
        metadataValuesList = parsedOptions.metadataValuesList;
      } catch (error) {
        console.error('Error parsing selectedOptions:', error);
        return [];
      }
    } else if (typeof selectedOptions === 'object') {
      metadataValuesList = selectedOptions.metadataValuesList;
    }

    if (!Array.isArray(metadataValuesList)) {
      console.warn('metadataValuesList is not an array:', metadataValuesList);
      return [];
    }

    // Group keys by category for better organization
    const categoryGroups = {
      decor: ['theme', 'color', 'flowers', 'seating', 'venue', 'greenery', 'decor'],
      cake: ['size', 'shape', 'flavor', 'decoration'],
      suit: ['fit', 'lapel', 'material', 'pattern', 'jacket'],
      dress: ['style', 'neckline', 'fabric', 'length', 'details'],
      proposal: ['location', 'scene', 'style']
    };

    // Flatten all valid keys
    const relevantKeys = Object.values(categoryGroups).flat();

    return metadataValuesList.filter(option => 
      relevantKeys.includes(option.key.toLowerCase())
    );
  };

  const metadata = parseMetadata(image.selectedOptions);

  const getIconForKey = (key) => {
    // Expanded icon mapping
    switch (key.toLowerCase()) {
      // Decor category
      case 'venue':
      case 'location':
        return <MapPin size={20} />;
      case 'theme':
      case 'style':
        return <Sparkles size={20} />;
      case 'color':
        return <Palette size={20} />;
      case 'flowers':
      case 'greenery':
        return <Flower size={20} />;
      case 'seating':
        return <Chair size={20} />;
      
      // Cake category
      case 'size':
      case 'shape':
        return <Square size={20} />;
      case 'flavor':
        return <Utensils size={20} />;
      case 'decoration':
        return <Sparkles size={20} />;
      
      // Suit & Dress category
      case 'fabric':
      case 'material':
        return <Scissors size={20} />;
      case 'fit':
      case 'style':
        return <Shirt size={20} />;
      
      // Default icon for other categories
      default:
        return <Tag size={20} />;
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4" 
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-2xl max-w-3xl w-full flex flex-col shadow-xl translate-y-12" 
        onClick={(e) => e.stopPropagation()}
      >
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 p-2.5 bg-white/90 backdrop-blur-sm rounded-full shadow-lg hover:bg-[#FF69B4]/10 transition-colors"
        >
          <X className="w-5 h-5 text-[#FF69B4]" />
        </button>
        <div className="relative flex-shrink-0">
          <img 
            src={image.s3Url} 
            alt="Wedding decoration" 
            className="w-full object-cover rounded-t-2xl" 
          />
        </div>

        <div className="p-1 md:p-6 flex-shrink-0 md:flex-1 md:overflow-y-auto">
          <div className="flex items-center justify-between px-4 py-3 border-b border-[#FF69B4]/10">
            <div className="flex items-center gap-3 ml-auto">
              <button 
                onClick={() => {
                  setLocalLiked(!localLiked);
                  onLike(image.id);
                }}
                className={`flex items-center gap-1.5 rounded-full text-sm ${
                  localLiked 
                    ? 'text-[#FF69B4]' 
                    : 'text-[#FF7F50]'
                }`}
              >
                <Heart 
                  className={`w-5 h-5 ${localLiked ? 'fill-current' : ''}`} 
                />
                <span className="font-medium">
                  {localLiked ? 'Saved' : 'Save'}
                </span>
              </button>
            </div>
          </div>

          <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-1.5 sm:gap-4 mt-4">
            {metadata.map((item) => (
              <div key={`${item.key}-${item.value}`} 
                className="bg-gradient-to-br from-[#FF69B4]/5 via-transparent to-[#FF7F50]/5 rounded-xl p-3 sm:p-4 hover:shadow-md transition-all duration-300"
              >
                <div className="text-xs sm:text-sm text-[#FF69B4] mb-1 flex items-center gap-1.5">
                  {getIconForKey(item.key)}
                  {item.key.charAt(0).toUpperCase() + item.key.slice(1)}
                </div>
                <div className="font-medium text-[#FF7F50] text-sm sm:text-base truncate">
                  {item.value}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="p-2 sm:p-3 border-t border-gray-100 sm:-mt-4">
          <button 
            className="w-full px-3 sm:px-4 py-1.5 sm:py-2 bg-gradient-to-r from-[#FF69B4] to-[#FF7F50] text-white 
              rounded-full shadow-md hover:shadow-lg transition-all duration-300 flex items-center justify-center gap-1.5 sm:gap-2
              text-sm"
            onClick={onClose}
          >
             <ArrowLeft className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
            <span>Back to Explore</span>
           
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageDetailsModal;
