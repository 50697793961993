import React, { createContext, useContext, useState, useCallback } from 'react';
import { BudgetBreakdown, BudgetCategory } from './types.ts';
import { budgetData as initialBudgetData } from './data.ts';

interface BudgetContextType {
  budgetData: BudgetBreakdown;
  selectedCategory: BudgetCategory | null;
  updateBudget: (newTotal: number) => void;
  selectCategory: (category: BudgetCategory | null) => void;
  updateLocation: (location: string) => void;
}

const BudgetContext = createContext<BudgetContextType | undefined>(undefined);

export const BudgetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [budgetData, setBudgetData] = useState<BudgetBreakdown>(initialBudgetData);
  const [selectedCategory, setSelectedCategory] = useState<BudgetCategory | null>(null);

  const updateBudget = useCallback((newTotal: number) => {
    setBudgetData(prev => {
      const ratio = newTotal / prev.totalBudget;
      const updatedCategories = prev.categories.map(category => ({
        ...category,
        amount: Math.round(category.amount * ratio),
      }));

      return {
        ...prev,
        totalBudget: newTotal,
        categories: updatedCategories,
      };
    });
  }, []);

  const updateLocation = useCallback((location: string) => {
    setBudgetData(prev => ({
      ...prev,
      location,
    }));
  }, []);

  const selectCategory = useCallback((category: BudgetCategory | null) => {
    setSelectedCategory(category);
  }, []);

  return (
    <BudgetContext.Provider value={{
      budgetData,
      selectedCategory,
      updateBudget,
      selectCategory,
      updateLocation,
    }}>
      {children}
    </BudgetContext.Provider>
  );
};

export const useBudgetContext = () => {
  const context = useContext(BudgetContext);
  if (!context) {
    throw new Error('useBudgetContext must be used within a BudgetProvider');
  }
  return context;
};