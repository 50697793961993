import React from 'react';
import { useNavigate } from 'react-router-dom';

const DesignOptions = () => {
  const navigate = useNavigate();

  const options = [
    {
      title: "Wedding Decor",
      description: "Design Your Perfect Wedding Venue",
      path: "/design/decor",
      image: "/themesOption/Rustic.jpg",
      available: true
    },
    {
      title: "Florals",
      description: "Create Your Dream Floral Arrangement, from Bouquets to Boutonnieres",
      path: "/design/florals",
      image: "/themesOption/Bouquet.jpg",
      available: true
    },
    {
      title: "Wedding Cake",
      description: "Craft Your Dream Wedding Cake",
      path: "/design/weddingcake",
      image: "/themesOption/Cake.jpg",
      available: true
    },
    {
      title: "Proposal",
      description: "Bring Your Ultimate Proposal Vision to Life",
      path: "/design/proposals",
      image: "/themesOption/Proposal.jpg",
      available: true
    },
    {
      title: "Wedding Attire",
      description: "Elevate Your Style with Tailored Outfits for the Bride and Groom",
      path: "/design/attire",
      image: "/themesOption/Attire.jpg",
      available: true
    },
    {
      title: "More Categories Coming Soon",

      path: "/design/morecategories",
      image: "/themesOption/Invitation.jpg",
      available: false
    },
  ];

  return (
    <div className="min-h-screen bg-white pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24">
        <div className="text-center">
          <h1 className="text-3xl sm:text-5xl md:text-6xl font-sans text-gray-900 mb-2 sm:mb-6 px-4 leading-tight">
            Create Your <span className="font-normal italic text-[#FF4D8D]">Perfect</span> Day
          </h1>
          <p className="text-xs sm:text-base text-[#3E3E3E]/80 font-light tracking-[0.15em] sm:tracking-[0.2em] uppercase max-w-xl mx-auto leading-relaxed mb-6 sm:mb-8 px-4">
            AI-powered wedding design studio
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 px-4 sm:px-6 mt-8">
          {options.map((option) => (
            <div 
              key={option.title} 
              onClick={() => option.available && navigate(option.path)}
              className={`group relative overflow-hidden rounded-2xl transition-all duration-300 ${
                option.available 
                  ? 'cursor-pointer hover:shadow-[0_0_15px_5px_rgba(255,127,80,0.3)] hover:scale-[1.02]' 
                  : 'opacity-75'
              }`}
            >
              <div className="aspect-[4/3] relative">
                <img 
                  src={option.image} 
                  alt={option.title} 
                  className="w-full h-full object-cover rounded-2xl"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent rounded-2xl" />
                
                {!option.available && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black/40 backdrop-blur-sm rounded-2xl">
                    <span className="px-3 py-1.5 sm:px-4 sm:py-2 bg-white/90 rounded-lg text-[#FF4D8D] text-sm sm:text-base font-semibold">
                      Coming Soon
                    </span>
                  </div>
                )}
                
                <div className="absolute inset-x-0 bottom-0 p-4 sm:p-6">
                  <h2 className="text-lg sm:text-xl font-bold text-white mb-1 sm:mb-2">
                    {option.title}
                  </h2>
                  <p className="text-white/90 text-xs sm:text-sm line-clamp-2">
                    {option.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DesignOptions; 