import React, { useState, useEffect, useRef } from 'react';
import './MobileHeader.css';
import { fetchUserCredits } from '../../services/creditsService';
import  CreditsDisplay  from '../../components/credits/CreditsDisplay'
import creditEventEmitter from '../../components/credits/creditEventEmitter'

const MobileHeader = ({ 
  setShowCreditsModal, 
  showCreditsModal,
  menuOpen, 
  toggleMenu, 
  user 
}) => {
  const [localCredits, setLocalCredits] = useState(0);

  useEffect(() => {
    if (user) {
      fetchUserCredits(user.uid)
          .then(credits => setLocalCredits(credits))
          .catch(console.error);
  }

    // Subscribe to credit updates
    const handleCreditUpdate = (newCredits) => {
      setLocalCredits(newCredits);
    };

    creditEventEmitter.subscribe(handleCreditUpdate);

    // Cleanup subscription
    return () => creditEventEmitter.unsubscribe(handleCreditUpdate);
  });

  return (
    <>
      {user && (
        <CreditsDisplay
          credits={localCredits}
          showCreditsModal={showCreditsModal}
          setShowCreditsModal={setShowCreditsModal}
          user={user}
          fetchCredits={fetchUserCredits}
          isDesktop={false}
        />
      )}
      
      <div className="mobile-header">
        
        <button 
          className={`menu-toggle ${menuOpen ? 'open' : ''}`} 
          onClick={toggleMenu}
        >
          <div className="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
    </>
  );
};

export default MobileHeader; 