export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

// (TODO) Look into nearest-color package for this
export function getClosestColor(hexColor) {
  let closestColor = null;
  let closestDistance = Infinity;

  for (const [hex, name] of Object.entries(colorMap)) {
    const distance = calculateColorDistance(hexColor, hex);
    if (distance < closestDistance) {
      closestColor = name;
      closestDistance = distance;
    }
  }

  return closestColor;
}
export const getColorInfo = (hexColor) => {
  const rgb = hexToRgb(hexColor);
  const namedColor = getClosestColor(hexColor);
  return {
    namedColor,
    rgb
  };
}; 

const colorMap = {
  // Basic Colors
  '#FF0000': 'red',
  '#00FF00': 'green',
  '#0000FF': 'blue',
  '#FFFF00': 'yellow',
  '#FF00FF': 'magenta',
  '#00FFFF': 'cyan',
  '#800000': 'maroon',
  '#808000': 'olive',
  '#008000': 'dark green',
  '#800080': 'purple',
  '#008080': 'teal',
  '#000080': 'navy',
  '#FFA500': 'orange',
  '#FFC0CB': 'pink',
  '#A52A2A': 'brown',
  '#808080': 'gray',
  '#FFFFFF': 'white',
  '#000000': 'black',

  // Wedding Dress Colors
  '#FFFFF0': 'ivory',
  '#FFFDD0': 'cream',
  '#FAF9F6': 'off-white',
  '#FEC5E5': 'blush pink',
  '#F7E7CE': 'champagne',
  '#F0EAD6': 'pearl white',
  '#B76E79': 'rose gold',
  '#C0C0C0': 'silver',
  '#B29700': 'light gold',
  '#8BA9A5': 'dusty blue',
  '#E6E6FA': 'lavender',
  '#DE6FA1': 'blush',
  '#FFE4E1': 'misty rose',
  '#FAEBD7': 'antique white',
  '#FFF5EE': 'seashell',

  // Wedding Cake
  '#B87333': 'copper',
  '#FFB6C1': 'blush pink',
  '#9DC183': 'sage green',
  '#44D7A8': 'eucalyptus',
  '#0072B5': 'french blue',
  '#6A5ACD': 'slate blue',
  '#6699CC': 'dusty blue',
  '#CCCCFF': 'periwinkle',
  '#800020': 'burgundy',
  '#C54B8C': 'mulberry',
  '#8E4585': 'plum',
  '#E0B0FF': 'mauve',
  '#E2725B': 'terracotta',
  '#B7410E': 'rust',
  '#483C32': 'taupe',
  '#50C878': 'emerald',
  '#FF7F50': 'coral',
  '#98FF98': 'mint',
  '#FFE5B4': 'peach',
  '#DCC282': 'gold'
};



function calculateColorDistance(hex1, hex2) {
  const r1 = parseInt(hex1.slice(1, 3), 16);
  const g1 = parseInt(hex1.slice(3, 5), 16);
  const b1 = parseInt(hex1.slice(5, 7), 16);

  const r2 = parseInt(hex2.slice(1, 3), 16);
  const g2 = parseInt(hex2.slice(3, 5), 16);
  const b2 = parseInt(hex2.slice(5, 7), 16);

  return Math.sqrt(
    Math.pow(r1 - r2, 2) +
    Math.pow(g1 - g2, 2) +
    Math.pow(b1 - b2, 2)
  );
}

