import React from 'react';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  gradientFrom?: string;
  gradientTo?: string;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  disabled = false,
  gradientFrom = '#FF69B4',
  gradientTo = '#FF7F50',
  className = '',
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`w-full px-6 py-3 text-white rounded-lg font-medium transition-all flex items-center justify-center gap-2 
        bg-gradient-to-r from-[${gradientFrom}] to-[${gradientTo}] hover:shadow-lg hover:scale-[1.02] disabled:opacity-50 ${className}`}
      style={{
        background: `linear-gradient(to right, ${gradientFrom}, ${gradientTo})`,
      }}
    >
      {children}
    </button>
  );
}; 