import React from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { Calendar, Flag, Pencil } from 'lucide-react';
import { Task } from './types';

interface TaskCardProps {
  task: Task;
  index: number;
  onEdit: (task: Task) => void;
}

export const TaskCard: React.FC<TaskCardProps> = ({ task, index, onEdit }) => {
  return (
    <Draggable draggableId={task.id.toString()} index={index}>
      {(provided, snapshot) => {
        const style = {
          ...provided.draggableProps.style,
          left: 'auto',
          top: 'auto',
        };
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={style}
            className={`
              group bg-white rounded-lg shadow-sm border border-gray-200 mb-2 select-none
              ${snapshot.isDragging 
                ? 'shadow-lg ring-2 ring-pink-500 ring-opacity-50 cursor-grabbing' 
                : 'hover:shadow-md hover:border-pink-200 cursor-grab'
              }
            `}
          >
            <div className="p-3 relative">
              <button
                type="button"
                onClick={() => onEdit(task)}
                className="absolute top-2 right-2 p-1 text-gray-400 hover:text-gray-600 transition-colors rounded-full hover:bg-gray-100"
              >
                <Pencil className="w-4 h-4" />
              </button>
              <h4 className="font-medium text-gray-800 mb-1.5 text-sm group-hover:text-pink-700">
                {task.name}
              </h4>
              {task.description && (
                <p className="text-xs text-gray-600 mb-2">{task.description}</p>
              )}
              
              <div className="flex items-center justify-between text-xs">
                {task.dueDate && (
                  <div className="flex items-center text-gray-500">
                    <Calendar className="w-3 h-3 mr-1" />
                    <span>{task.dueDate}</span>
                  </div>
                )}
                <div className={`px-1.5 py-0.5 rounded-full text-xs ${getPriorityColor(task.priority)}`}>
                  <Flag className="w-3 h-3 inline mr-1" />
                  {task.priority}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

const getPriorityColor = (priority: string) => {
  switch (priority.toLowerCase()) {
    case 'high':
      return 'bg-[#FFE5E5] text-[#D64D5C]';
    case 'medium':
      return 'bg-[#FFF2E5] text-[#E67E22]';
    case 'low':
      return 'bg-[#E5F6FF] text-[#3498DB]';
    default:
      return 'bg-gray-100 text-gray-600';
  }
};
