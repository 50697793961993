import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { generateAuthToken } from './encryptExternalID.js';
import { BudgetCategory } from '../pages/plan/budget/types.js';
import { budgetData } from '../context/data.ts';

// When the totalBudget is updated, each subsection has to be updated based on its percentage
export const updateTotalBudget = async (budget: number) => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
        console.log("User needs to log in before planning");
        return;
    }

    const token = await generateAuthToken();
    const headers = {
        'content-type': 'application/json',
        'X-Auth-Token': token,
        'externalClientId': currentUser.uid,
        'authenticationService': 'firebase',
    };

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/budget-sections`, { headers });
        if (response.data && response.data.length > 0) {
            const newBudgetData = response.data.map(element => ({
                id: element.id,
                name: element.name,
                cost: element.percentage * (budget/100.0) // Calculate cost based on percentage and budget
            }));
            const updateResponse = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/v1/budget-sections/bulk`, newBudgetData
                , { headers });

            console.log("Budget updated successfully:", updateResponse.data);
        }
    } catch (error) {
        console.error('Error fetching budget data:', error);
        throw error;
    }
};

export const updateBudgetSections = async (budget: BudgetCategory[]) => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
        console.log("User needs to log in to update budget sections");
        return;
    }

    const token = await generateAuthToken();
    const headers = {
        'X-Auth-Token': token,
        'externalClientId': currentUser.uid,
        'authenticationService': 'firebase',
        'Content-Type': 'application/json',
    };
    console.log(budget)
    // Create newBudgetData from the budget array
    const newBudgetData = budget.map(item => ({
        id: item.id, // Assuming BudgetCategory has an id property
        name: item.name, // Assuming BudgetCategory has a name property
        percentage: item.percentage, // Assuming BudgetCategory has a percentage property
        cost: item.amount
    }));

    try {
        const updateResponse = await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/budget-sections/bulk`,
            newBudgetData,
            { headers }
        );

        console.log('Budget sections updated successfully:', updateResponse.data);
        return updateResponse.data; // Return the response data if needed
    } catch (error) {
        console.error('Error updating budget sections:', error);
        throw error; // Rethrow the error for further handling if needed
    }
};

export const getBudget = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
        console.log("User needs to log in before planning");
        return;
    }

    const token = await generateAuthToken();

    try {
        const headers = {
            'content-type': 'application/json',
            'X-Auth-Token': token,
            'externalClientId': currentUser.uid,
            'authenticationService': 'firebase',
        };
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/budget-sections`, { headers });
        if (response.data && response.data.length > 0) {

            return response.data
        } else {
            console.log("No budget data found in the response.");
            return null;
        }
    } catch (error) {
        console.error('Error fetching budget data:', error);
        throw error;
    }
};

