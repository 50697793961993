import React from 'react';
import { BudgetCategory } from './types';
import { ArrowRight } from 'lucide-react';

interface BudgetDetailsProps {
  category: BudgetCategory | null;
  totalBudget: number;
}

export const BudgetDetails: React.FC<BudgetDetailsProps> = ({ category, totalBudget }) => {
  
  if (!category) {
    return (
      <div className="flex items-center justify-center h-full text-center p-8">
        <div className="text-gray-500">
          <p className="text-lg mb-2">Click on a section of the chart</p>
          <p className="text-sm">to see detailed cost breakdown and factors</p>
        </div>
      </div>
    );
  }

  const amount = Math.round((category.percentage / 100) * totalBudget);

  return (
    <div className="bg-white rounded-xl p-4 sm:p-6 shadow-sm border border-gray-100 animate-fadeIn w-[85vw] sm:w-auto -ml-7 sm:mx-0 border">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-xl font-semibold text-gray-800">{category.name}</h3>
        <div className="text-right">
          <div className="text-2xl font-bold text-gray-800">
            ${amount.toLocaleString()}
          </div>
          <div className="text-sm text-gray-500">
            {category.percentage.toFixed(1)}% of total budget
          </div>
        </div>
      </div>
      
      <div className="mt-6">
        <h4 className="font-medium text-gray-800 mb-4">Factors that impact price</h4>
        <ul className="space-y-3">
          {category.factors.map((factor, index) => (
            <li 
              key={index} 
              className="flex items-start space-x-3 bg-gray-50 p-3 rounded-lg hover:bg-gray-100 transition-colors"
            >
              <span className="text-xl flex-shrink-0">{factor.icon}</span>
              <span className="text-gray-600 text-sm">{factor.text}</span>
            </li>
          ))}
        </ul>
      </div>
      
      <div className="mt-6 pt-6 border-t border-gray-100">
        <button className="text-pink-600 text-sm hover:text-pink-700 flex items-center group">
          Learn more about {category.name.toLowerCase()} costs
          <ArrowRight className="w-4 h-4 ml-1 transform group-hover:translate-x-1 transition-transform" />
        </button>
      </div>
    </div>
  );
};