export const budgetData = {
    totalBudget: 30853,
    location: 'Dallas, TX',
    categories: [
      {
        id: 'venue',
        name: 'Venue',
        percentage: 32,
        amount: 9574,
        color: 'rgb(236, 72, 153)',
        factors: [
          { icon: '👥', text: 'Guest count (larger venues can cost more)' },
          { icon: '📅', text: 'Peak vs. off-peak date and time (month, day of the week and time of day)' },
          { icon: '⏱️', text: 'Number of hours of access' },
          { icon: '🍽️', text: "What's included (catering, rentals, etc)" },
          { icon: '💰', text: 'Taxes and gratuity' }
        ]
      },
      {
        id: 'catering',
        name: 'Catering',
        percentage: 24,
        amount: 8050,
        color: 'rgb(249, 168, 212)',
        factors: [
          { icon: '👥', text: 'Guest count (catering is typically priced per person)' },
          { icon: '🍽️', text: 'Packages and serving style (ex. plated, buffet, etc)' },
          { icon: '👨‍🍳', text: 'Number of staffers needed' },
          { icon: '💰', text: 'Service charges, taxes and gratuity' },
          { icon: '🍴', text: 'Number of vendor meals required' }
        ]
      },
      {
        id: 'photographer',
        name: 'Photographer',
        percentage: 7,
        amount: 2653,
        color: 'rgb(253, 186, 116)',
        factors: [
          { icon: '📸', text: 'Type of photography package chosen and add-ons' },
          { icon: '📅', text: 'Events covered (ex. ceremony, engagement shoots, etc) and number of hours booked' },
          { icon: '👥', text: 'Number of photographers hired (ex. second shooter)' },
          { icon: '💰', text: 'Travel fees and gratuity' }
        ]
      },
      {
        id: 'florist',
        name: 'Florist',
        percentage: 7,
        amount: 2673,
        color: 'rgb(134, 239, 172)',
        factors: [
          { icon: '🌺', text: 'Seasonality and what\'s in bloom' },
          { icon: '📍', text: 'Locally sourced flowers typically cost less than imported' },
          { icon: '✨', text: 'Size and level of detail of the designs' },
          { icon: '💐', text: 'Number of personal flowers (ex. bouquets) needed' },
          { icon: '🚚', text: 'Delivery, set-up fees and gratuity' }
        ]
      },
      {
        id: 'rentals',
        name: 'Event rentals',
        percentage: 5,
        amount: 1726,
        color: 'rgb(147, 197, 253)',
        factors: [
          { icon: '👥', text: 'Guest count (fewer people means fewer tables, chairs, etc)' },
          { icon: '🪑', text: 'Type of items rented (ex. tables, tent, etc)' },
          { icon: '✨', text: 'High-end or specialty items may have higher price tags' },
          { icon: '🚚', text: 'Delivery, set-up fees and gratuity' }
        ]
      },
      {
        id: 'planner',
        name: 'Wedding Planner',
        percentage: 5,
        amount: 2053,
        color: 'rgb(167, 139, 250)',
        factors: [
          { icon: '📋', text: 'Scope of planning services (ex. full service, month-of and day-of)' },
          { icon: '💰', text: 'Planning package fee structure (flat fee, hourly or percentage)' },
          { icon: '👥', text: 'Number of team members involved with your event' },
          { icon: '🚗', text: 'Travel fees and gratuity' }
        ]
      },
      {
        id: 'dress',
        name: 'Wedding Dress',
        percentage: 5,
        amount: 1967,
        color: 'rgb(251, 146, 60)',
        factors: [
          { icon: '🧵', text: 'Type of fabric (ex. silk, lace, etc) and embellishments (ex. beading)' },
          { icon: '✂️', text: 'Level of personalization (off-the-rack vs. custom)' },
          { icon: '📏', text: 'Scope of dress alterations needed' },
          { icon: '👗', text: 'Cleaning and dress preservation post-wedding' }
        ]
      },
      {
        id: 'videographer',
        name: 'Videographer',
        percentage: 6,
        amount: 2176,
        color: 'rgb(94, 234, 212)',
        factors: [
          { icon: '🎥', text: 'Selected package and edits (ex. highlight reel)' },
          { icon: '📅', text: 'Events covered (ex. rehearsal, ceremony, etc) and number of hours booked' },
          { icon: '👥', text: 'Number of videographers hired (ex. second shooter)' },
          { icon: '💰', text: 'Travel fees and gratuity' }
        ]
      },
      {
        id: 'dj',
        name: 'DJ',
        percentage: 4,
        amount: 1318,
        color: 'rgb(252, 231, 243)',
        factors: [
          { icon: '🎵', text: 'Type of package selected and number of hours booked' },
          { icon: '🔊', text: 'Extras like lighting, speakers and other sound equipment' },
          { icon: '💿', text: 'Potential fees to purchase new music' },
          { icon: '💰', text: 'Travel fees and gratuity' }
        ]
      },
      {
        id: 'other',
        name: 'Other',
        percentage: 5,
        amount: 1543,
        color: 'rgb(226, 232, 240)',
        factors: [
          { icon: '👥', text: 'Guest count' },
          { icon: '📅', text: 'Peak vs. off-peak date and time' },
          { icon: '🍽️', text: 'Packages and serving style (ex. plated, buffet, etc)' },
          { icon: '👥', text: 'Number of staffers needed' },
          { icon: '✂️', text: 'Level of personalization (off-the-rack vs. custom)' }
        ]
      }
    ]
  };