import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Create a store to share state between components
const store = {
  listeners: new Set(),
  state: {
    selections: {},
    subSelections: {},
    previewItems: [],
    category: null
  },
  
  // Method to update state
  setState(newState) {
    this.state = { ...this.state, ...newState };
    this.notify();
  },
  
  // Method to notify listeners of state changes
  notify() {
    this.listeners.forEach(listener => listener(this.state));
  },
  
  // Method to clear state
  clearState() {
    this.state = {
      selections: {},
      subSelections: {},
      previewItems: [],
      category: null
    };
    this.notify();
  }
};

export const useSharedDesignState = (category) => {
  const [localState, setLocalState] = useState(store.state);
  
  useEffect(() => {
    // Subscribe to store changes
    const handleChange = (newState) => {
      setLocalState(newState);
    };
    
    store.listeners.add(handleChange);
    
    // Set initial category if not already set
    if (!store.state.category) {
      store.setState({ category });
    }
    
    // Clear previous category's state when switching
    if (store.state.category !== category) {
      store.clearState();
      store.setState({ category });
    }
    
    // Handle auth state changes
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        store.clearState();
      }
    });
    
    return () => {
      store.listeners.delete(handleChange);
      unsubscribe();
    };
  }, [category]);
  
  // Only return/update state if we're in the correct category
  const isCurrentCategory = localState.category === category;
  
  const setSelections = (newSelections) => {
    if (isCurrentCategory) {
      store.setState({
        selections: typeof newSelections === 'function' 
          ? newSelections(store.state.selections)
          : newSelections
      });
    }
  };
  
  const setSubSelections = (newSubSelections) => {
    if (isCurrentCategory) {
      store.setState({
        subSelections: typeof newSubSelections === 'function'
          ? newSubSelections(store.state.subSelections)
          : newSubSelections
      });
    }
  };
  
  const setPreviewItems = (newPreviewItems) => {
    if (isCurrentCategory) {
      store.setState({
        previewItems: typeof newPreviewItems === 'function'
          ? newPreviewItems(store.state.previewItems)
          : newPreviewItems
      });
    }
  };
  
  return {
    selections: isCurrentCategory ? localState.selections : {},
    subSelections: isCurrentCategory ? localState.subSelections : {},
    previewItems: isCurrentCategory ? localState.previewItems : [],
    setSelections,
    setSubSelections,
    setPreviewItems
  };
};

// Helper hook to just clear state (can be used in auth components)
export const useClearDesignState = () => {
  return () => store.clearState();
};