import React, { useState } from 'react';
import { ListFilter, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface Category {
  id: string;
  title: string;
  icon: string;
}

interface CategorySelectorProps {
  categories: Category[];
  selectedCategory: string;
  onSelectCategory: (categoryId: string) => void;
}

export const CategorySelector: React.FC<CategorySelectorProps> = ({
  categories,
  selectedCategory,
  onSelectCategory,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedCategoryData = categories.find(cat => cat.id === selectedCategory);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center px-3 py-2 sm:px-4 sm:py-2.5 bg-white rounded-lg shadow-sm 
          border border-[#E6A5B5] hover:bg-[#FFF7FA] transition-all duration-300 
          text-xs sm:text-sm"
      >
        <ListFilter className="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-2 text-[#6E497E]" />
        <span className="text-[#6E497E] flex items-center gap-2">
          {selectedCategoryData?.icon} {selectedCategoryData?.title}
        </span>
        <ChevronDown className={`w-3.5 h-3.5 sm:w-4 sm:h-4 ml-2 transition-transform text-[#6E497E] ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute left-0 top-full mt-1 bg-white rounded-lg shadow-lg 
              border border-[#E6A5B5] py-1.5 w-48 sm:w-56 z-50"
          >
            {categories.map(category => (
              <button
                key={category.id}
                onClick={() => {
                  onSelectCategory(category.id);
                  setIsOpen(false);
                }}
                className={`w-full px-3 py-2 sm:px-4 sm:py-2.5 text-left flex items-center gap-2 
                  transition-all duration-200 text-xs sm:text-sm
                  hover:bg-[#FFF7FA] group
                  ${selectedCategory === category.id 
                    ? 'bg-[#FFF7FA]' 
                    : ''
                  }`}
              >
                <span>{category.icon}</span>
                <span className={`transition-colors duration-200
                  ${selectedCategory === category.id 
                    ? 'text-[#6E497E] font-medium' 
                    : 'text-gray-700 group-hover:text-[#6E497E]'
                  }`}>
                  {category.title}
                </span>
              </button>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};