import CryptoJS from 'crypto-js';
import { getAuth } from 'firebase/auth';

export const encrypt = (plaintext, key) => {
  try {
    if (!key) {
      throw new Error('Encryption key is missing');
    }
    
    const keyBytes = CryptoJS.enc.Hex.parse(key);
    const encrypted = CryptoJS.AES.encrypt(plaintext, keyBytes, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    
    return encrypted.toString();
  } catch (error) {
    console.error('Encryption error:', error);
    throw error;
  }
};

export const generateAuthToken = () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('No user is currently authenticated.');
  }

  try {
    const externalId = user.uid;
    const currentUtcTime = new Date().toISOString();
    const combinedString = `${externalId}----------${currentUtcTime}`;
    

    const secretKey = process.env.REACT_APP_WEDIFY_APP_SECRET_KEY;
    if (!secretKey) {
      throw new Error('Secret key is not configured');
    }

    const encryptedId = encrypt(combinedString, secretKey);
    
    return encryptedId;
  } catch (error) {
    console.error('Error generating auth token:', error);
    throw error;
  }
};
