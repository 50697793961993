import React, { useEffect } from 'react';
import { MessageSquare, Calendar, Target, BarChart2 } from 'lucide-react';

const CRMFeatures = () => {
  const features = [
    {
      icon: <Target className="w-5 h-5 sm:w-8 sm:h-8 text-white/90" />,
      title: "Smart Lead Management",
      description: "AI-powered lead scoring and automated follow-ups to never miss an opportunity.",
      iconBg: "bg-gradient-to-br from-pink-500/80 via-pink-500/50 to-pink-600/30",
      glowColor: "rgba(236, 72, 153, 0.3)" // pink-500
    },
    {
      icon: <Calendar className="w-5 h-5 sm:w-8 sm:h-8 text-white/90" />,
      title: "Automated Scheduling",
      description: "Smart calendar management with AI-powered scheduling suggestions.",
      iconBg: "bg-gradient-to-br from-blue-500/80 via-blue-500/50 to-blue-600/30",
      glowColor: "rgba(59, 130, 246, 0.3)" // blue-500
    },
    {
      icon: <MessageSquare className="w-5 h-5 sm:w-8 sm:h-8 text-white/90" />,
      title: "Smart Communication",
      description: "AI-assisted messaging and email templates personalized for each client.",
      iconBg: "bg-gradient-to-br from-pink-500/80 via-pink-500/50 to-pink-600/30",
      glowColor: "rgba(236, 72, 153, 0.3)" // pink-500
    },
    {
      icon: <BarChart2 className="w-5 h-5 sm:w-8 sm:h-8 text-white/90" />,
      title: "Analytics Dashboard",
      description: "Real-time insights and predictive analytics to grow your business.",
      iconBg: "bg-gradient-to-br from-blue-500/80 via-blue-500/50 to-blue-600/30",
      glowColor: "rgba(59, 130, 246, 0.3)" // blue-500
    }
  ];

  useEffect(() => {
    // Load Typeform embed script
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="relative w-full overflow-hidden bg-[#1a1a2e] py-12 sm:py-24 px-2 sm:px-4 md:px-8">
      {/* Gradient overlays */}
      <div 
        className="absolute inset-0"
        style={{
          background: `
            radial-gradient(circle at 100% 0%, rgba(45, 212, 191, 0.15) 0%, rgba(45, 212, 191, 0.03) 25%, transparent 50%),
            radial-gradient(circle at 0% 100%, rgba(168, 85, 247, 0.15) 0%, rgba(168, 85, 247, 0.03) 25%, transparent 50%)
          `
        }}
      />
      
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center mb-8 sm:mb-16">
          <div className="inline-block bg-black/20 backdrop-blur-xl rounded-full px-3 sm:px-4 py-1 mb-4 sm:mb-6">
            <span className="text-pink-400 text-xs sm:text-sm font-medium">Coming Soon</span>
          </div>
          <h2 className="text-2xl sm:text-4xl md:text-5xl font-bold text-white mb-3 sm:mb-6">
            AI-Powered CRM for Wedding Vendors
          </h2>
          <p className="text-sm sm:text-lg text-gray-300 max-w-2xl mx-auto px-2">
            Join now to be among the first to access our revolutionary AI-powered CRM tool,
            designed specifically for wedding vendors.
          </p>
        </div>

        <div className="grid grid-cols-2 gap-2 sm:gap-8 mb-12 sm:mb-16">
          {features.map((feature, index) => (
            <div
              key={index}
              className="group relative rounded-xl sm:rounded-2xl p-3 sm:p-8 transition-all duration-300"
              style={{
                background: 'rgba(26, 26, 46, 0.75)',
                backdropFilter: 'blur(24px)',
                border: '1px solid rgba(255, 255, 255, 0.05)',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)'
              }}
            >
              <div 
                className={`relative ${feature.iconBg} rounded-lg sm:rounded-2xl w-9 h-9 sm:w-12 sm:h-12 flex items-center justify-center mb-2.5 sm:mb-6 transition-all duration-300 group-hover:scale-105`}
                style={{
                  backdropFilter: 'blur(12px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  boxShadow: `0 4px 24px -1px ${feature.glowColor}`,
                  background: `
                    linear-gradient(135deg, 
                      rgba(255, 255, 255, 0.1) 0%, 
                      rgba(255, 255, 255, 0.05) 50%,
                      rgba(255, 255, 255, 0) 100%
                    )
                  `
                }}
              >
                <div className="absolute inset-0 rounded-lg sm:rounded-2xl opacity-50" style={{ background: feature.iconBg }} />
                {feature.icon}
              </div>
              <h3 className="text-sm sm:text-xl font-semibold text-white mb-1 sm:mb-3">{feature.title}</h3>
              <p className="text-xs sm:text-base text-gray-400/90 leading-relaxed line-clamp-3 sm:line-clamp-none">
                {feature.description}
              </p>
              <div 
                className="absolute inset-0 rounded-xl sm:rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"
                style={{
                  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.03) 0%, transparent 100%)'
                }}
              />
            </div>
          ))}
        </div>

        {/* CTA Section with Typeform */}
        <div className="text-center">
          <h3 className="text-2xl sm:text-3xl font-bold text-white mb-3">
            Join Wedify Marketplace Now
          </h3>
          <p className="text-sm sm:text-lg text-gray-300 mb-8">
            Transform your wedding business with our AI-powered tools and reach more couples
          </p>
          <div className="flex justify-center">
            <div data-tf-live="01JM0B946SQY8MYFYF9ZZG7W18"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRMFeatures; 