import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronRight, Check, ArrowRight } from 'lucide-react';
import { Task } from '../kanban/types';

// Add keyframe animations for the button
const buttonGlowStyles = `
  @keyframes buttonGradientGlow {
    0% {
      background-position: 0% 50%;
      box-shadow: 0px 0px 8px rgba(255, 127, 142, 0.4);
    }
    50% {
      background-position: 100% 50%;
      box-shadow: 0px 0px 12px rgba(255, 214, 182, 0.5);
    }
    100% {
      background-position: 0% 50%;
      box-shadow: 0px 0px 8px rgba(231, 216, 240, 0.5);
    }
  }

  .gradient-button {
    background: #FF4D8D;
    background-size: 200% 200%;
    color: white;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }

  .gradient-button:hover {
    animation: buttonGradientGlow 4s infinite alternate ease-in-out;
  }
`;

interface CategoryChecklistProps {
  category: {
    id: string;
    title: string;
    icon: string;
    tasks: Task[]; // Assuming tasks is now an array of Task objects
  };
  isExpanded: boolean;
  onToggle: () => void;
  completedTasks: Task[]; // Change to an array of Task objects
  onToggleTask: (task: Task, isCompleted: boolean) => void; // Change to accept a Task object and a boolean
}

export const CategoryChecklist: React.FC<CategoryChecklistProps> = ({
  category,
  isExpanded,
  onToggle,
  completedTasks,
  onToggleTask,
}) => {
  const completedCount = category.tasks.filter(task => completedTasks.includes(task)).length;
  const progress = category.tasks.length === 0 ? 0 : Math.round((completedCount / category.tasks.length) * 100);

  React.useEffect(() => {
    // Add the styles to the document
    const styleSheet = document.createElement("style");
    styleSheet.innerText = buttonGlowStyles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <div className="bg-white/90 rounded-2xl shadow-sm border border-gray-100/50 overflow-hidden backdrop-blur-md">
      <div className="relative">
        <button
          onClick={onToggle}
          className="w-full px-4 py-3.5 hover:bg-gray-50/30 transition-colors"
        >
          <div className="flex items-center">
            <div className="flex items-center space-x-3 min-w-0">
              <span className="text-lg sm:text-2xl flex-shrink-0">{category.icon}</span>
              <div className="min-w-0">
                <span className="font-medium text-gray-800 text-sm sm:text-base block truncate">
                  {category.title}
                </span>
                <div className="flex items-center mt-2">
                  <div className="w-16 sm:w-24 h-2 bg-gradient-to-r from-[#E7D8F0]/30 to-[#D0E3FF]/30 rounded-full overflow-hidden">
                    <motion.div 
                      className="h-2 rounded-full bg-gradient-to-r from-[#FF7F8E] to-[#FFD6B6]"
                      initial={{ width: 0 }}
                      animate={{ width: `${progress}%` }}
                      transition={{ duration: 0.5 }}
                    />
                  </div>
                  <span className="ml-2 text-xs text-[#3E3E3E]/70">
                    {completedCount}/{category.tasks.length}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </button>

        <div className="absolute right-4 top-1/2 -translate-y-1/2 z-10">
          <motion.button 
            type="button"
            className="gradient-button px-3 py-2 sm:px-4 sm:py-2.5 text-xs sm:text-sm font-medium rounded-[20px]
              backdrop-blur-[8px] border border-white/20
              shadow-sm hover:shadow-[0px_4px_8px_rgba(255,127,142,0.3)]
              transform transition-all duration-200
              flex items-center justify-center gap-1.5 sm:gap-2
              flex-shrink-0 whitespace-nowrap"
            whileHover={{ 
              x: 5,
              transition: { duration: 0.2 }
            }}
            whileTap={{ 
              scale: 0.97,
              opacity: 0.8 
            }}
            onClick={onToggle}
            aria-label={isExpanded ? "Hide details" : "View details"}
          >
            <span>{isExpanded ? "Hide Details" : "View Details"}</span>
            <ArrowRight className="w-3.5 h-3.5 sm:w-4 sm:h-4 transition-transform duration-200" />
          </motion.button>
        </div>
      </div>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="px-4 py-3 bg-[rgba(255,245,240,0.4)] backdrop-blur-md border-t border-[#F7E2E2]">
              <div className="space-y-1">
                {category.tasks.map((task, index) => {
                  const isCompleted = completedTasks.includes(task);
                  
                  return (
                    <motion.button 
                      key={index}
                      onClick={() => onToggleTask(task, !isCompleted)}
                      className="w-full flex items-center space-x-3 p-3 rounded-xl
                        hover:bg-white/50 transition-all duration-200
                        border-b border-[#F7E2E2] last:border-b-0"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <div className={`w-6 h-6 rounded-xl border-2 flex items-center justify-center cursor-pointer
                        ${isCompleted 
                          ? 'border-[#E74C88] bg-[#E74C88]' 
                          : 'border-[#B0A9B5] hover:border-[#E74C88]'}
                        transition-all duration-200
                      `}>
                        {isCompleted && <Check className="w-4 h-4 text-white" />}
                      </div>
                      <span className={`text-sm text-left ${
                        isCompleted 
                          ? 'text-gray-400 line-through' 
                          : 'text-[#6E497E] hover:text-[#5d3d6b]'
                      }`}>
                        {task.name}
                      </span>
                    </motion.button>
                  );
                })}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};