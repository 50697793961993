import React, { useState } from 'react';
import { Filter, ListFilter, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface EventFilterProps {
  selectedFilter: 'all' | 'tasks' | 'events';
  onFilterChange: (filter: 'all' | 'tasks' | 'events') => void;
}

export const EventFilter: React.FC<EventFilterProps> = ({
  selectedFilter,
  onFilterChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const filters = [
    { id: 'all', label: 'All Items' },
    { id: 'tasks', label: 'Tasks Only' },
    { id: 'events', label: 'Events Only' },
  ] as const;

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center px-3 py-1.5 text-xs bg-white rounded-lg shadow-sm 
          border border-pink-100 hover:bg-pink-50 transition-colors"
      >
        <ListFilter className="w-3.5 h-3.5 mr-1.5 text-pink-600" />
        <span className="text-gray-700 text-xs flex items-center">
          {selectedFilter === 'all' && 'All Items'}
          {selectedFilter === 'tasks' && 'Tasks'}
          {selectedFilter === 'events' && 'Events'}
        </span>
        <ChevronDown className="w-3.5 h-3.5 ml-1.5 transition-transform" />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute left-0 top-full mt-2 bg-white rounded-lg shadow-lg 
              border border-[#B8A7E9]/20 py-2 min-w-[160px] z-10"
          >
            {filters.map((filter) => (
              <button
                key={filter.id}
                onClick={() => {
                  onFilterChange(filter.id);
                  setIsOpen(false);
                }}
                className={`w-full px-4 py-2 text-left text-sm transition-all duration-300 ${
                  selectedFilter === filter.id 
                    ? 'bg-gradient-to-r from-[#B8A7E9]/10 to-[#D5CCF1]/10 text-[#B8A7E9]' 
                    : 'text-gray-700 hover:bg-[#B8A7E9]/5'
                }`}
              >
                {filter.label}
              </button>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};