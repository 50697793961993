import React from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, startOfWeek, endOfWeek } from 'date-fns';
import { DisplayableItem } from './types';

interface MiniCalendarProps {
  month: Date;
  events: DisplayableItem[];
  isCurrentDay: (date: Date) => boolean;
  getEventsForDate: (date: Date) => DisplayableItem[];
  getPriorityColor: (priority: 'LOW' | 'MEDIUM' | 'HIGH') => string;
}

const WEEKDAYS = [
  { key: 'sun', label: 'S' },
  { key: 'mon', label: 'M' },
  { key: 'tue', label: 'T' },
  { key: 'wed', label: 'W' },
  { key: 'thu', label: 'T' },
  { key: 'fri', label: 'F' },
  { key: 'sat', label: 'S' },
];

export const MiniCalendar: React.FC<MiniCalendarProps> = ({ 
  month, 
  events,
  isCurrentDay,
  getEventsForDate,
  getPriorityColor
}) => {
  // Get the start and end of the month
  const monthStart = startOfMonth(month);
  const monthEnd = endOfMonth(month);
  
  // Get the start of the week containing the first day of the month
  const calendarStart = startOfWeek(monthStart);
  // Get the end of the week containing the last day of the month
  const calendarEnd = endOfWeek(monthEnd);

  // Generate all days that should appear in the calendar
  const days = eachDayOfInterval({
    start: calendarStart,
    end: calendarEnd
  });

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-1">
      <div className="grid grid-cols-7 gap-px text-center">
        {WEEKDAYS.map(day => (
          <div key={day.key} className="text-[10px] font-medium text-gray-400">
            {day.label}
          </div>
        ))}
        
        {days.map(day => {
          const dayItems = getEventsForDate(day);
          const hasHighPriority = dayItems.some(item => item.priority === 'HIGH');
          const hasMediumPriority = dayItems.some(item => item.priority === 'MEDIUM');
          const hasLowPriority = dayItems.some(item => item.priority === 'LOW');
          
          return (
            <div
              key={day.toISOString()}
              className={`relative h-5 flex items-center justify-center ${
                isSameMonth(day, month) ? 'text-gray-700' : 'text-gray-300'
              }`}
            >
              <span 
                className={`text-[10px] z-10 w-5 h-5 flex items-center justify-center
                  ${isCurrentDay(day) && day.getMonth() === month.getMonth() 
                    ? 'bg-gradient-to-r from-[#B8A7E9] to-[#D5CCF1] text-white rounded-full' 
                    : ''}
                `}
              >
                {format(day, 'd')}
              </span>
              {dayItems.length > 0 && (
                <div className="absolute inset-1 rounded-full opacity-20">
                  {hasHighPriority && <div className={`absolute inset-0 ${getPriorityColor('HIGH')}`} />}
                  {hasMediumPriority && <div className={`absolute inset-0 ${getPriorityColor('MEDIUM')}`} />}
                  {hasLowPriority && <div className={`absolute inset-0 ${getPriorityColor('LOW')}`} />}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MiniCalendar;