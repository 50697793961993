import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import '../Page.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getAuth } from 'firebase/auth';
import { generateAuthToken } from '../../../utils/encryptExternalID';
import { useSharedDesignState } from '../../../hooks/useSharedDesignState';
import { DesktopDesign } from '../../../components/design/desktopDesign.tsx';
import { MobileDesign } from '../../../components/design/mobileDesign.tsx';
import { generateImage, ModelOptions } from '../../../services/api/designApi';
import { uploadBookmark } from '../../../services/api/bookmarkApi';
import { fetchUserCredits, updateCreditsAfterGeneration } from '../../../services/creditsService';
import creditEventEmitter from '../../../components/credits/creditEventEmitter';

const ATTIRE_TYPES = {
  SUIT: 'Suit/Tuxedo',
  DRESS: 'Wedding Dress'
};

const normalizeAttireType = (value) => {
  if (!value) return null;
  return value.toLowerCase().includes('suit') ? ATTIRE_TYPES.SUIT : ATTIRE_TYPES.DRESS;
};

const getStepOptions = (stepId, attireType, selections = {}) => {
  const normalizedAttireType = normalizeAttireType(attireType);
  
  const options = {
    attireType: [
      { name: ATTIRE_TYPES.SUIT, image: '/wedding/tux.jpg' },
      { name: ATTIRE_TYPES.DRESS, image: '/wedding/dress.jpg' }
    ],
    style: {
      [ATTIRE_TYPES.SUIT]: [
        { name: 'Single-Breasted', image: '/wedding/singleBreasted.jpg' },
        { name: 'Double-Breasted', image: '/wedding/doubleBreasted.jpg' },
        { name: 'Dinner Jacket', image: '/wedding/dinnerJacket.jpg' },
        { name: 'Morning Coat', image: '/wedding/morningCoat.jpg' },
        { name: 'Blazer', image: '/wedding/blazer.jpg' },
      ],
      [ATTIRE_TYPES.DRESS]: [
        { name: 'A-Line', image: '/wedding/aLine.jpg' },
        { name: 'Ball Gown', image: '/wedding/ballGown.jpg' },
        { name: 'Mermaid', image: '/wedding/mermaid.jpg' },
        { name: 'Sheath', image: '/wedding/sheath.jpg' },
        { name: 'Trumpet', image: '/wedding/trumpet.jpg' },
        { name: 'Empire Waist', image: '/wedding/empire.jpg' },
      ],
    },
    fit: {
      [ATTIRE_TYPES.SUIT]: [
        { name: 'Slim Fit', image: '/wedding/slimFit.jpg' },
        { name: 'Tailored Fit', image: '/wedding/tailoredFit.jpg' },
        { name: 'Regular Fit', image: '/wedding/regularFit.jpg' },
        { name: 'Relaxed Fit', image: '/wedding/relaxedFit.jpg' },
      ],
      [ATTIRE_TYPES.DRESS]: [
        { name: 'Floor Length', image: '/wedding/fullLength.jpg' },
        { name: 'Chapel Train', image: '/wedding/chapelTrain.jpg' },
        { name: 'Tea Length', image: '/wedding/teaLength.jpg' },
        { name: 'Knee Length', image: '/wedding/kneeLength.jpg' },
      ],
    },
    details: {
      [ATTIRE_TYPES.SUIT]: [
        { name: 'Notch Lapel', image: '/wedding/notchLapel.jpg' },
        { name: 'Peak Lapel', image: '/wedding/peakLapel.jpg' },
        { name: 'Shawl Lapel', image: '/wedding/shawLapel.jpg' },
        { name: 'Wide Lapel', image: '/wedding/wideLapel.jpg' },
        { name: 'Slim Lapel', image: '/wedding/slimLapel.jpg' },
      ],
      [ATTIRE_TYPES.DRESS]: [
        { name: 'Beading', image: '/wedding/beading.jpg' },
        { name: 'Embroidery', image: '/wedding/embroidery.jpg' },
        { name: 'Appliqués', image: '/wedding/appliques.jpg' },
        { name: 'Ruffles', image: '/wedding/ruffles.jpg' },
        { name: 'Sequins', image: '/wedding/sequins.jpg' },
        { name: 'Pearls', image: '/wedding/pearls.jpg' },
      ],
    },
    fabric: {
      [ATTIRE_TYPES.SUIT]: [
        { name: 'Wool', image: '/wedding/wool.jpg' },
        { name: 'Linen', image: '/wedding/linen.jpg' },
        { name: 'Velvet', image: '/wedding/velvet.jpg' },
        { name: 'Tweed', image: '/wedding/tweed.jpg' },
        { name: 'Silk Blend', image: '/wedding/silkBlend.jpg' },
      ],
      [ATTIRE_TYPES.DRESS]: [
        { name: 'Silk', image: '/wedding/silk.jpg' },
        { name: 'Lace', image: '/wedding/lace.jpg' },
        { name: 'Tulle', image: '/wedding/tulle.jpg' },
        { name: 'Satin', image: '/wedding/satin.jpg' },
        { name: 'Organza', image: '/wedding/organza.jpg' },
        { name: 'Chiffon', image: '/wedding/chiffon.jpg' },
      ],
    },
    pattern: {
      [ATTIRE_TYPES.SUIT]: [
        { name: 'Solid', image: '/wedding/solid.jpg' },
        { name: 'Pinstripe', image: '/wedding/pinstripe.jpg' },
        { name: 'Plaid', image: '/wedding/plaid.jpg' },
        { name: 'Windowpane', image: '/wedding/windowpane.jpg' },
        { name: 'Herringbone', image: '/wedding/herringbone.jpg' },
        { name: 'Checkered', image: '/wedding/checkered.jpg' },
      ],
      [ATTIRE_TYPES.DRESS]: [
        { name: 'Sweetheart', image: '/wedding/sweetheart.jpg' },
        { name: 'V-Neck', image: '/wedding/vLine.jpg' },
        { name: 'Off-Shoulder', image: '/wedding/offShoulder.jpg' },
        { name: 'Halter', image: '/wedding/halter.jpg' },
        { name: 'Strapless', image: '/wedding/strapless.jpg' },
      ],
    },
    color: {
      [ATTIRE_TYPES.SUIT]: [
        { name: 'Charcoal Grey', hex: '#36454F' },
        { name: 'Navy Blue', hex: '#000080' },
        { name: 'Black', hex: '#000000' },
        { name: 'Oxford Grey', hex: '#4E5754' },
        { name: 'Steel Blue', hex: '#4682B4' },
        { name: 'Dark Brown', hex: '#3C2A21' },
        { name: 'Slate Grey', hex: '#708090' },
        { name: 'Midnight Blue', hex: '#191970' },
        { name: 'Burgundy', hex: '#800020' },
        { name: 'Forest Green', hex: '#228B22' },
        { name: 'Royal Blue', hex: '#4169E1' },
        { name: 'Plum', hex: '#673147' },
        { name: 'Light Grey', hex: '#D3D3D3' },
        { name: 'Khaki', hex: '#C3B091' },
        { name: 'Tan', hex: '#D2B48C' },
        { name: 'Stone', hex: '#928E85' },
      ],
      [ATTIRE_TYPES.DRESS]: [
        { name: 'Pure White', hex: '#FFFFFF' },
        { name: 'Ivory', hex: '#FFFFF0' },
        { name: 'Cream', hex: '#FFFDD0' },
        { name: 'Off-White', hex: '#FAF9F6' },
        { name: 'Blush Pink', hex: '#FEC5E5' },
        { name: 'Champagne', hex: '#F7E7CE' },
        { name: 'Pearl', hex: '#F0EAD6' },
        { name: 'Rose Gold', hex: '#B76E79' },
        { name: 'Silver', hex: '#C0C0C0' },
        { name: 'Light Gold', hex: '#B29700' },
        { name: 'Dusty Blue', hex: '#8BA9A5' },
        { name: 'Soft Lavender', hex: '#E6E6FA' },
        { name: 'Blush', hex: '#DE6FA1' },
        { name: 'Misty Rose', hex: '#FFE4E1' },
        { name: 'Antique White', hex: '#FAEBD7' },
        { name: 'Seashell', hex: '#FFF5EE' },
      ],
    },
  };

  if (stepId === 'attireType') return options.attireType;

  if (stepId === 'fit' && normalizedAttireType === ATTIRE_TYPES.DRESS) {
    const dressStyle = selections.style;
    switch (dressStyle) {
      case 'Ball Gown':
        return [
          { name: 'Floor Length', image: '/wedding/fullLength.jpg' },
          { name: 'Chapel Train', image: '/wedding/chapelTrain.jpg' },
          { name: 'Add', image: '/themesOption/add-plus.jpg' },
        ];
      case 'Sheath':
        return [
          { name: 'Floor Length', image: '/wedding/fullLength.jpg' },
          { name: 'Tea Length', image: '/wedding/teaLength.jpg' },
          { name: 'Add', image: '/themesOption/add-plus.jpg' },
        ];
      case 'Mermaid':
        return [
          { name: 'Floor Length', image: '/wedding/fullLength.jpg' },
          { name: 'Chapel Train', image: '/wedding/chapelTrain.jpg' },
          { name: 'Add', image: '/themesOption/add-plus.jpg' },
        ];
      case 'Trumpet':
        return [
          { name: 'Floor Length', image: '/wedding/fullLength.jpg' },
          { name: 'Chapel Train', image: '/wedding/chapelTrain.jpg' },
          { name: 'Add', image: '/themesOption/add-plus.jpg' },
        ];
      case 'A-Line':
        return [
          { name: 'Floor Length', image: '/wedding/fullLength.jpg' },
          { name: 'Chapel Train', image: '/wedding/chapelTrain.jpg' },
          { name: 'Tea Length', image: '/wedding/teaLength.jpg' },
          { name: 'Add', image: '/themesOption/add-plus.jpg' },
        ];
      case 'Empire Waist':
        return [
          { name: 'Floor Length', image: '/wedding/fullLength.jpg' },
          { name: 'Tea Length', image: '/wedding/teaLength.jpg' },
          { name: 'Add', image: '/themesOption/add-plus.jpg' },
        ];
      default:
        return options[stepId]?.[normalizedAttireType] || [];
    }
  }

  if (stepId === 'details' && normalizedAttireType === ATTIRE_TYPES.SUIT) {
    const suitStyle = selections.style;
    switch (suitStyle) {
      case 'Single-Breasted':
        return [
          { name: 'Notch Lapel', image: '/wedding/notchLapel.jpg' },
          { name: 'Peak Lapel', image: '/wedding/peakLapel.jpg' },
          { name: 'Add', image: '/themesOption/add-plus.jpg' },
        ];
      case 'Double-Breasted':
        return [
          { name: 'Peak Lapel', image: '/wedding/peakLapel.jpg' },
          { name: 'Shawl Lapel', image: '/wedding/shawLapel.jpg' },
          { name: 'Add', image: '/themesOption/add-plus.jpg' },
        ];
      case 'Dinner Jacket':
        return [
          { name: 'Shawl Lapel', image: '/wedding/shawLapel.jpg' },
          { name: 'Peak Lapel', image: '/wedding/peakLapel.jpg' },
          { name: 'Add', image: '/themesOption/add-plus.jpg' },
        ];
      case 'Morning Coat':
        return [
          { name: 'Notch Lapel', image: '/wedding/notchLapel.jpg' },
          { name: 'Peak Lapel', image: '/wedding/peakLapel.jpg' },
          { name: 'Add', image: '/themesOption/add-plus.jpg' },
        ];
      case 'Blazer':
        return [
          { name: 'Notch Lapel', image: '/wedding/notchLapel.jpg' },
          { name: 'Peak Lapel', image: '/wedding/peakLapel.jpg' },
          { name: 'Shawl Lapel', image: '/wedding/shawLapel.jpg' },
          { name: 'Slim Lapel', image: '/wedding/slimLapel.jpg' },
          { name: 'Add', image: '/themesOption/add-plus.jpg' },
        ];
      default:
        return options[stepId]?.[normalizedAttireType] || [];
    }
  }

  return options[stepId]?.[normalizedAttireType] || [];
};

const initialStep = [
  {
    id: 'attireType',
    title: 'Choose Attire Type',
    type: 'grid',
    options: getStepOptions('attireType'),
    allowCustom: false,
  },
];

const suitSteps = (selections) => [
  initialStep[0],
  {
    id: 'style',
    title: 'Choose Style',
    type: 'grid',
    options: getStepOptions('style', ATTIRE_TYPES.SUIT, selections),
    allowCustom: true,
  },
  {
    id: 'fit',
    title: 'Select Fit',
    type: 'grid',
    options: getStepOptions('fit', ATTIRE_TYPES.SUIT, selections),
    allowCustom: true,
  },
  {
    id: 'details',
    title: 'Choose Details',
    type: 'grid',
    options: getStepOptions('details', ATTIRE_TYPES.SUIT, selections),
    allowCustom: true,
  },
  {
    id: 'fabric',
    title: 'Select Fabric',
    type: 'grid',
    options: getStepOptions('fabric', ATTIRE_TYPES.SUIT, selections),
    allowCustom: true,
  },
  {
    id: 'pattern',
    title: 'Choose Pattern',
    type: 'grid',
    options: getStepOptions('pattern', ATTIRE_TYPES.SUIT, selections),
    allowCustom: true,
  },
  {
    id: 'color',
    title: 'Choose Color',
    type: 'color-picker',
    options: getStepOptions('color', ATTIRE_TYPES.SUIT, selections),
    allowCustom: false,
  },
];

const dressSteps = (selections) => [
  initialStep[0],
  {
    id: 'style',
    title: 'Choose Dress Style',
    type: 'grid',
    options: getStepOptions('style', ATTIRE_TYPES.DRESS, selections),
    allowCustom: true,
  },
  {
    id: 'length',
    title: 'Choose Dress Length',
    type: 'grid',
    options: getStepOptions('fit', ATTIRE_TYPES.DRESS, selections),
    allowCustom: true,
  },
  {
    id: 'details',
    title: 'Choose Details',
    type: 'grid',
    options: getStepOptions('details', ATTIRE_TYPES.DRESS, selections),
    allowCustom: true,
  },
  {
    id: 'fabric',
    title: 'Select Fabric',
    type: 'grid',
    options: getStepOptions('fabric', ATTIRE_TYPES.DRESS, selections),
    allowCustom: true,
  },
  {
    id: 'neckline',
    title: 'Choose Neckline',
    type: 'grid',
    options: getStepOptions('pattern', ATTIRE_TYPES.DRESS, selections),
    allowCustom: true,
  },
  {
    id: 'color',
    title: 'Choose Color',
    type: 'color-picker',
    options: getStepOptions('color', ATTIRE_TYPES.DRESS, selections),
    allowCustom: false,
  },
];

const WeddingAttire = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // Use shared state
  const {
    selections = {}, // Initialize selections as an empty object
    subSelections,
    previewItems,
    setSelections,
    setSubSelections,
    setPreviewItems,
  } = useSharedDesignState('Wedding Attire');

  // Local state
  const [currentSteps, setCurrentSteps] = useState(initialStep);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [credits, setCredits] = useState(null);
  const [showCreditsModal, setShowCreditsModal] = useState(false);

  useEffect(() => {
    if (selections.attireType) {
      setCurrentSteps(selections.attireType === ATTIRE_TYPES.SUIT ? suitSteps(selections) : dressSteps(selections));
    }
  }, [selections.attireType, selections.style]);

  const handleSelectionChange = (stepId, value) => {
    if (stepId === 'attireType') {
      const normalizedValue = normalizeAttireType(value);
      setSelections({ attireType: normalizedValue });
    } else if (stepId === 'style') {
      setSelections(prev => ({
        ...prev,
        [stepId]: value,
        fit: undefined,
        details: undefined
      }));
    } else {
      setSelections(prev => ({ ...prev, [stepId]: value }));
    }
  };

  const generateVisualizations = async () => {
    const isAllSelectionsComplete = currentSteps.every((step) => selections[step.id]);

    if (!isAllSelectionsComplete) {
      setError('Please complete all design options');
      return;
    }

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      setError('Please log in to generate designs');
      return;
    }

    const userCredits = await fetchUserCredits(currentUser.uid);
    if (userCredits < 30) {
      setShowCreditsModal(true);
      setError('Insufficient credits, Please Purchase Credits');
      return;
    }

    setLoading(true);
    setProgress(0);
    setError('');

    try {
      const token = await generateAuthToken();
      
      // Fix color handling
      let colorName;
      if (typeof selections.color === 'object' && selections.color.name) {
        colorName = selections.color.name;
      } else if (typeof selections.color === 'object' && selections.color.hex) {
        colorName = selections.color.hex;
      } else {
        colorName = selections.color;
      }


      let attirePrompt;
      if (selections.attireType === ATTIRE_TYPES.DRESS) {

        attirePrompt = `A photorealistic depiction of a ${selections.style} wedding dress with a ${selections.neckline} neckline, displayed on a mannequin. The gown is made from luxurious ${selections.fabric} fabric in a ${colorName} shade, emphasizing its exquisite craftsmanship. The dress features a ${selections.length} length and is adorned with intricate ${selections.details}, adding to its elegance. The mannequin is centered against a plain, neutral background with soft, even lighting, showcasing the silhouette, fabric texture, and delicate details from top to bottom, highlighting the dress's timeless beauty and sophistication`;
      } else {
        attirePrompt = `A photorealistic depiction of a ${selections.fit} men's suit with a ${selections.details}, displayed on a mannequin. The suit is crafted from luxurious ${selections.fabric} fabric in a refined ${colorName} shade, featuring a ${selections.pattern} pattern. The ${selections.style} design showcases sharp tailoring and precise craftsmanship. The mannequin is centered against a plain, neutral background with soft, even lighting highlighting the texture of the fabric, the intricate details of the lapel, and the sleek, structured fit`;
      }

      const imageUrl = await generateImage(attirePrompt, token, currentUser.uid, ModelOptions.PHOENIX);

      setPreviewItems([{ url: imageUrl, label: 'Wedding Attire Design', prompt: attirePrompt }]);
      setProgress(100);

      const updatedCredits = await updateCreditsAfterGeneration(currentUser.uid);
      creditEventEmitter.emit(updatedCredits);

      setCredits(updatedCredits);
    } catch (error) {
      console.error('Error generating visualizations:', error);
      setError('Failed to generate visualizations');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (item) => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      setError('Please log in to save designs');
      return false;
    }

    try {
      await uploadBookmark(item, 'Wedding Attire', selections, currentUser);
      return true;
    } catch (error) {
      console.error('Error saving design:', error);
      setError('Failed to save design');
      return false;
    }
  };

  const progressSteps =
    currentSteps.length === 1
      ? []
      : currentSteps
          .filter((step) => step.id !== 'attireType')
          .map((step) => ({
            name: step.id.charAt(0).toUpperCase() + step.id.slice(1),
            isComplete: !!selections[step.id],
          }));

  const sharedProps = {
    steps: currentSteps,
    selections,
    subSelections,
    previewItems,
    title: "Design Your Wedding Attire",
    loading,
    progress,
    error,
    credits,
    onSelectionChange: handleSelectionChange,
    onSubSelectionChange: setSubSelections,
    onGenerate: generateVisualizations,
    onSave: handleSave,
    progressSteps,
    currentStep: currentSteps.findIndex((step) => !selections[step.id]),
  };

  return (
    <div className="decor-design-page">
      {isMobile ? (
        <MobileDesign {...sharedProps} />
      ) : (
        <DesktopDesign {...sharedProps} />
      )}
    </div>
  );
};

export default WeddingAttire;