import { Column, DragResult } from './types';

export const getPriorityColor = (priority: 'LOW' | 'MEDIUM' | 'HIGH'): string => {
  const colors = {
    LOW: 'bg-green-100 text-green-700',
    MEDIUM: 'bg-yellow-100 text-yellow-700',
    HIGH: 'bg-red-100 text-red-700',
  } as const;
  
  return colors[priority];
};

export const handleDragEnd = (result: DragResult, columns: Column[]): Column[] | null => {
  const { source, destination } = result;
  if (!destination) return null;

  if (source.droppableId === destination.droppableId) {
    // Reordering within the same column
    const column = columns.find(col => col.id === source.droppableId);
    if (!column) return null;

    const newTasks = Array.from(column.tasks);
    const [movedTask] = newTasks.splice(source.index, 1);
    newTasks.splice(destination.index, 0, { ...movedTask, status: column.id as any });

    return columns.map(col =>
      col.id === source.droppableId ? { ...col, tasks: newTasks } : col
    );
  } else {
    // Moving between columns
    const sourceColumn = columns.find(col => col.id === source.droppableId);
    const destColumn = columns.find(col => col.id === destination.droppableId);
    if (!sourceColumn || !destColumn) return null;

    const sourceTasks = Array.from(sourceColumn.tasks);
    const destTasks = Array.from(destColumn.tasks);
    const [movedTask] = sourceTasks.splice(source.index, 1);
    destTasks.splice(destination.index, 0, { ...movedTask, status: destColumn.id as any });

    return columns.map(col => {
      if (col.id === source.droppableId) return { ...col, tasks: sourceTasks };
      if (col.id === destination.droppableId) return { ...col, tasks: destTasks };
      return col;
    });
  }
};