import React from 'react';

interface BudgetVisualizationProps {
  categories: {
    id: string;
    name: string;
    percentage: number;
    color: string;
  }[];
}

export const BudgetVisualization: React.FC<BudgetVisualizationProps> = ({ categories }) => {
  const radius = 80;
  const circumference = 2 * Math.PI * radius;
  let currentOffset = 0;

  return (
    <div className="flex justify-center items-center mt-6">
      <div className="relative w-[200px] h-[200px]">
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          className="transform -rotate-90"
        >
          {categories.map((category) => {
            const strokeDasharray = (category.percentage / 100) * circumference;
            const strokeDashoffset = -currentOffset;
            currentOffset += strokeDasharray;

            return (
              <circle
                key={category.id}
                cx="100"
                cy="100"
                r={radius}
                fill="none"
                stroke={category.color}
                strokeWidth="24"
                strokeDasharray={`${strokeDasharray} ${circumference}`}
                strokeDashoffset={strokeDashoffset}
                className="transition-all duration-300"
              />
            );
          })}
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center">
            <div className="text-sm text-[#6E497E]/70">Total</div>
            <div className="text-xl font-semibold text-[#6E497E]">Categories</div>
            <div className="text-sm text-[#E74C88]">{categories.length}</div>
          </div>
        </div>
      </div>
    </div>
  );
};