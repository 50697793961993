import React, { useEffect, useState, useCallback } from 'react';
import { getAuth } from 'firebase/auth';
import Masonry from 'react-masonry-css';
import './Explore.css';
import Spinner from '../../components/spinner/Spinner';
import { generateToken } from '../../services/shared/generateToken';
import { ExploreAPI } from '../../services/api/getExploreImages';
import ImageCard from './ImageCard';
import ImageDetailsModal from './ImageDetailsModal';
import { useInView } from 'react-intersection-observer';

const Explore = () => {
  const [pictures, setPictures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 12;

  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
    rootMargin: '200px',
    triggerOnce: true,
  });

  // Function to fetch a single page
  const fetchPage = async (pageNumber) => {
    const response = await ExploreAPI.getExploreImages({
      page: pageNumber,
      size: pageSize
    });
    return response;
  };

  // Initial load - show page 0 immediately while loading page 1 in parallel
  useEffect(() => {
    const loadInitialPages = async () => {
      try {
        const [page0Response, page1Response] = await Promise.all([
          fetchPage(0),
          fetchPage(1)
        ]);

        const page0Data = page0Response.data.map(pic => ({ ...pic, liked: false }));
        const page1Data = page1Response.data.map(pic => ({ ...pic, liked: false }));
        
        setPictures([...page0Data, ...page1Data]);
        setHasMore(!page1Response.pagination.isLastPage);
        setCurrentPage(1);
        
      } catch (err) {
        setError(err.message);
        setHasMore(false);
      } finally {
        setInitialLoading(false);
      }
    };

    loadInitialPages();
  }, []);

  // Function to fetch subsequent pages
  const fetchNextPage = useCallback(async (pageNumber) => {
    if (pageNumber === undefined || pageNumber === null || loading) {
      return;
    }

    try {
      setLoading(true);
      const response = await fetchPage(pageNumber);
      setPictures(prev => [
        ...prev,
        ...response.data.map(pic => ({ ...pic, liked: false }))
      ]);
      setHasMore(!response.pagination.isLastPage);
    } catch (err) {
      setError(err.message);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [loading, pageSize]);

  // Infinite scroll effect
  useEffect(() => {
    if (inView && !loading && hasMore) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchNextPage(nextPage);
    }
  }, [inView, loading, hasMore, currentPage, fetchNextPage]);

  const handleLike = async (imageId) => {
    const auth = getAuth();
    if (!auth.currentUser) {
      console.error('User not authenticated');
      return;
    }

    const picture = pictures.find((p) => p.id === imageId);
    if (!picture) return;

    try {
      // Optimistic UI update
      setPictures((prevPictures) =>
        prevPictures.map((p) =>
          p.id === imageId ? { ...p, liked: !p.liked } : p
        )
      );

      // Sync selected image modal if open
      if (selectedImage?.id === imageId) {
        setSelectedImage((prev) => ({
          ...prev,
          liked: !prev.liked,
        }));
      }

      // Call API to update server
      const token = await generateToken();
      const params = {
        externalClientId: auth.currentUser.uid,
        imageId,
        authenticationService: 'firebase',
      };

      if (!picture.liked) {
        await ExploreAPI.addToFavorites(token, params);
      } else {
        await ExploreAPI.removeFromFavorites(token, params);
      }
    } catch (error) {
      console.error('Error updating like status:', error);
      // Revert changes on error
      setPictures((prevPictures) =>
        prevPictures.map((p) =>
          p.id === imageId ? { ...p, liked: picture.liked } : p
        )
      );
      if (selectedImage?.id === imageId) {
        setSelectedImage((prev) => ({
          ...prev,
          liked: picture.liked,
        }));
      }
    }
  };

  const handleImageClick = (picture) => {
    setSelectedImage(picture);
  };

  // Masonry layout columns
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 2,
  };

  return (
    <div className="explore-page px-4 sm:px-6 lg:px-8 max-w-[1920px] mx-auto bg-white">

      {error && (
        <div className="text-[#D93F43] text-center py-4">
          Error: {error}
        </div>
      )}

      {initialLoading ? (
        <div className="h-40 flex items-center justify-center mt-24">
          <Spinner size="lg" text="Loading gallery..." className="text-[#B0316E]" />
        </div>
      ) : (
        <>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid !-ml-3 sm:!-ml-4"
            columnClassName="my-masonry-grid_column !pl-3 sm:!pl-4"
          >
            {pictures.map((picture, index) => (
              <ImageCard
                key={`${picture.id}-${index}`}
                src={picture.s3Url}
                alt={`Image ${picture.id}`}
                tags={{
                  category: picture.designCategory || 'Wedding Decor',
                  subject: picture.subject || 'Unknown Subject',
                  style: picture.style || 'Unknown Style',
                  time: picture.time || 'Anytime',
                }}
                metadata={{
                  createdAt: picture.createdAt,
                  selectedOptions: picture.selectedOptions
                }}
                isLiked={picture.liked}
                onLike={() => handleLike(picture.id)}
                onExpand={() => handleImageClick(picture)}
                className="transform transition-all duration-300 hover:shadow-lg hover:shadow-[#B0316E]/20"
              />
            ))}
          </Masonry>

          {loading && !initialLoading && (
            <div className="h-20 flex items-center justify-center">
              <Spinner size="sm" text="Loading more images..." />
            </div>
          )}

          {!loading && hasMore && (
            <div ref={loadMoreRef} className="h-20" />
          )}

          {!hasMore && pictures.length > 0 && (
            <div className="text-center text-[#4B1F7C]/60 py-8">
              You've reached the end
            </div>
          )}

          {selectedImage && (
            <ImageDetailsModal
              image={selectedImage}
              onClose={() => setSelectedImage(null)}
              onLike={handleLike}
              isLiked={selectedImage.liked}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Explore;