import React, { useState, useEffect } from 'react';
import { CalendarCheck, KanbanSquare, LayoutDashboard, DollarSign, ClipboardList, ChevronLeft, ChevronRight, CheckCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import { KanbanBoard } from './kanban/KanbanBoard.tsx';
import { CalendarView } from './calendar/CalendarView.tsx';
import { BudgetView } from './budget/BudgetView.tsx';
import { DashboardView } from './dashboard/DashboardView.tsx';
import { WeddingPlanSummary } from './weddingPlan/WeddingPlanSummary.tsx';

// Add background pattern and gradient styles
const pageStyles = `
  .plan-page-background {
    position: relative;
    background: linear-gradient(180deg, #FFF5F0 0%, #FFE4D6 100%);
  }

  .plan-page-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      radial-gradient(circle at 1px 1px, rgba(255, 245, 240, 0.1) 1px, transparent 0),
      linear-gradient(135deg, rgba(255, 214, 182, 0.1) 0%, rgba(231, 216, 240, 0.1) 100%);
    background-size: 24px 24px, 100% 100%;
    pointer-events: none;
    opacity: 0.8;
  }

  .plan-page-background::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='rgba(255, 245, 240, 0.05)' fill-rule='evenodd'/%3E%3C/svg%3E") repeat-x bottom;
    opacity: 0.3;
    animation: wave 20s linear infinite;
  }

  @keyframes wave {
    from { background-position-x: 0; }
    to { background-position-x: 1000px; }
  }
`;

type PlanningTool = 'dashboard' | 'task-board' | 'calendar' | 'budget' | 'wedding-plan';

const PlanPage: React.FC = () => {
  const [selectedTool, setSelectedTool] = useState<PlanningTool>('wedding-plan');
  const [mobileToolIndex, setMobileToolIndex] = useState(0);

  useEffect(() => {
    // Add the styles to the document
    const styleSheet = document.createElement("style");
    styleSheet.innerText = pageStyles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const tools = [
    { id: 'wedding-plan', label: 'Wedding Plan', icon: ClipboardList },
    { id: 'dashboard', label: 'Dashboard', icon: LayoutDashboard },
    { id: 'task-board', label: 'Task Board', icon: KanbanSquare },
    { id: 'calendar', label: 'Calendar View', icon: CalendarCheck },
    { id: 'budget', label: 'Budget', icon: DollarSign },
  ];

  const handleToolChange = (toolId: PlanningTool, index: number) => {
    setSelectedTool(toolId);
    setMobileToolIndex(index);
  };

  const handleMobileNavigation = (direction: 'next' | 'prev') => {
    const newIndex = direction === 'next'
      ? (mobileToolIndex + 1) % tools.length
      : (mobileToolIndex - 1 + tools.length) % tools.length;
    
    setMobileToolIndex(newIndex);
    setSelectedTool(tools[newIndex].id as PlanningTool);
  };

  const renderToolContent = () => {
    switch (selectedTool) {
      case 'dashboard':
        return <DashboardView onNavigate={setSelectedTool} />;
      case 'task-board':
        return <KanbanBoard />;
      case 'calendar':
        return <CalendarView />;
      case 'budget':
        return <BudgetView />;
      case 'wedding-plan':
        return <WeddingPlanSummary />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-[calc(100vh-4rem)] relative overflow-hidden bg-white">
      <motion.div
        className="mx-auto relative z-10"
        initial={false}
        animate={{
          width: '100%',
          maxWidth: '1400px',
          x: 0,
        }}
        transition={{
          type: 'spring',
          stiffness: 150,
          damping: 20,
          mass: 1,
        }}
      >
        <div className="px-4 sm:px-6 py-12 sm:py-24 mt-16">
          <motion.div 
            className="text-center mb-1 sm:mb-4"
            transition={{ duration: 0.3 }}
          >
            <h2 className="font-sans text-[2.75rem] sm:text-[3.5rem] leading-tight font-light text-gray-900
              tracking-wide mb-3 sm:mb-4 px-4">
              Wedding <span className="font-normal italic text-[#FF4D8D]">Planning</span> Tools
            </h2>
            <p className="font-sans text-[0.875rem] sm:text-base text-[#3E3E3E]/80 
              font-light tracking-[0.2em] mb-8 sm:mb-12 max-w-md mx-auto px-4
              leading-relaxed uppercase">
              Organize every detail 
            </p>

            {/* Mobile Navigation */}
            <div className="block sm:hidden">
              <div 
                className="relative overflow-hidden rounded-2xl mb-8"
                style={{
                  background: 'rgba(255, 255, 255, 0.3)',
                  backdropFilter: 'blur(8px)',
                  WebkitBackdropFilter: 'blur(8px)',
                  boxShadow: '0 8px 32px -8px rgba(255, 127, 142, 0.3)',
                  border: '1px solid rgba(255, 255, 255, 0.4)'
                }}
              >
                <div className="absolute inset-0 bg-gradient-to-br from-white/20 to-white/10 pointer-events-none" />
                
                <div className="relative px-2 py-2 sm:px-5 sm:py-6">
                  {/* Section Label */}
                  <h3 className="text-[18px] font-medium text-[#3E3E3E] mb-4 text-center pb-2 
                    border-b border-[#FFD6E0]/30">
                    {tools[mobileToolIndex].label}
                  </h3>

                  <div className="flex items-center justify-between mb-6">
                    <motion.button 
                      onClick={() => handleMobileNavigation('prev')}
                      className="w-[24px] h-[24px] flex items-center justify-center opacity-75
                        hover:opacity-100 transition-all duration-300"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <div className="bg-gradient-to-r from-[#FF7F8E] to-[#FFD6B6] bg-clip-text">
                        <ChevronLeft className="w-[24px] h-[24px] text-transparent stroke-[#FF7F8E]" strokeWidth={1.5} />
                      </div>
                    </motion.button>

                    <div className="flex flex-col items-center">
                      <div className="bg-white/80 p-3 rounded-xl shadow-[0_4px_16px_-4px_rgba(255,127,142,0.3)]
                        border border-white/60">
                        {React.createElement(tools[mobileToolIndex].icon, { 
                          className: "w-6 h-6 text-[#FF7F8E]" 
                        })}
                      </div>
                    </div>

                    <motion.button 
                      onClick={() => handleMobileNavigation('next')}
                      className="w-[24px] h-[24px] flex items-center justify-center opacity-75
                        hover:opacity-100 transition-all duration-300"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <div className="bg-gradient-to-r from-[#FF7F8E] to-[#FFD6B6] bg-clip-text">
                        <ChevronRight className="w-[24px] h-[24px] text-transparent stroke-[#FF7F8E]" strokeWidth={1.5} />
                      </div>
                    </motion.button>
                  </div>

                  <div className="flex justify-center items-center gap-4">
                    {tools.map((tool, index) => (
                      <motion.div
                        key={index}
                        onClick={() => handleToolChange(tool.id as PlanningTool, index)}
                        className="relative cursor-pointer"
                      >
                        <motion.div
                          className={`h-[6px] w-[6px] transition-all duration-300 rounded-full ${
                            index === mobileToolIndex 
                              ? 'bg-gradient-to-r from-[#FF7F8E] to-[#FFD6B6] shadow-[0_2px_8px_rgba(255,127,142,0.3)]' 
                              : 'bg-[#EAEAEA]/60'
                          }`}
                          initial={false}
                          animate={{
                            scale: index === mobileToolIndex ? 1.4 : 1,
                          }}
                          transition={{ 
                            type: "spring", 
                            stiffness: 300, 
                            damping: 20 
                          }}
                        />
                        {index === mobileToolIndex && (
                          <motion.div
                            className="absolute inset-0 rounded-full bg-gradient-to-r from-[#FF7F8E] to-[#FFD6B6] opacity-20"
                            animate={{ 
                              scale: [1, 1.8, 1],
                              opacity: [0.2, 0.15, 0.2]
                            }}
                            transition={{
                              duration: 2,
                              repeat: Infinity,
                              ease: "easeInOut"
                            }}
                          />
                        )}
                      </motion.div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:block bg-[#FDF8F2] rounded-2xl 
              border-2 border-[#FFD3DA]
              p-1.5 mx-auto max-w-4xl mb-8">
              <div className="flex justify-center space-x-10 overflow-x-auto px-6 py-3 scrollbar-hide">
                {tools.map((tool, index) => (
                  <button
                    key={tool.id}
                    onClick={() => handleToolChange(tool.id as PlanningTool, index)}
                    className={`flex flex-col items-center min-w-[84px] transition-all duration-300 group
                      ${selectedTool === tool.id 
                        ? 'text-rose-500' 
                        : 'text-gray-700 hover:text-rose-400'
                      }`}
                  >
                    {React.createElement(tool.icon, { 
                      className: `w-6 h-6 transition-transform duration-300 group-hover:scale-110
                        ${selectedTool === tool.id 
                          ? 'drop-shadow-[0_0_10px_rgba(255,107,129,0.25)]' 
                          : ''
                        }` 
                    })}
                    <span className="text-sm font-medium whitespace-nowrap mt-2.5 transition-colors">
                      {tool.label}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </motion.div>
          
          <motion.div 
            className="backdrop-blur-sm rounded-2xl shadow-lg border border-[#FFD3DA] p-4  min-h-[600px] h-auto hover:shadow-[0_8px_32px_-8px_rgba(110,73,126,0.15)] transition-all duration-300 relative overflow-visible"
            transition={{ duration: 0.3 }}
          >
            <div className="max-w-[1400px] mx-auto px-2 sm:px-4">
              {/* Task Categories */}
              <div className="space-y-3 sm:space-y-4">
                {renderToolContent()}
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default PlanPage;