import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { generateAuthToken } from '../../utils/encryptExternalID';
import PaymentForm from './PaymentForm';
import { getAuth } from 'firebase/auth';
import './PaymentPortal.css';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PRICING_PLANS = [
  {
    name: 'Starter Plan',
    credits: 600,
    price: 0,
    description: 'Included with registration',
    isPopular: false
  },
  {
    name: 'Basic Plan',
    credits: 600,
    price: 1.99,
    description: 'Perfect for getting started',
    isPopular: false
  },
  {
    name: 'Standard Plan',
    credits: 1200,
    price: 3.99,
    description: 'Most popular choice',
    isPopular: true
  },
  {
    name: 'Premium Plan',
    credits: 7500,
    price: 7.99,
    description: 'Best value for power users',
    isPopular: false
  }
];

const PaymentPortal = ({ isOpen, onClose, user, fetchCredits, credits }) => {
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState('initial');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);

  const handleViewPricing = () => {
    onClose();
    navigate('/pricing-plan');
  };

  const handleShowPricing = () => {
    setCurrentView('pricing');
  };

  const handleGetCredits = async (plan) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      
      if (!currentUser) {
        throw new Error('Please sign in to purchase credits');
      }

      // Generate auth token
      const token = await generateAuthToken();
      
      // Create payment intent
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': token
        },
        body: JSON.stringify({
          amount: plan.price,
          currency: 'usd',
          externalClientId: currentUser.uid,
          authService: 'firebase'
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create payment intent');
      }

      const { clientSecret } = await response.json();

      setSelectedPlan(plan);
      setClientSecret(clientSecret);
      setCurrentView('payment');
    } catch (error) {
      console.error('Error creating payment intent:', error);
      setError(error.message);
    }
  };

  // If not open, don't render anything
  if (!isOpen) return null;

  return createPortal(
    <div style={{ position: 'fixed', inset: 0, zIndex: 99999 }} className="backdrop-blur-sm flex items-center justify-center">
      <div className="p-[2px] rounded-2xl m-4 w-full max-w-2xl bg-gradient-to-r from-[#FF4D8D] to-[#FF7F50]">
        <div className="bg-white rounded-2xl shadow-xl w-full h-full overflow-hidden">
          <div className="relative p-6 min-h-[80px]">
            {currentView !== 'initial' && (
              <button 
                className="absolute left-6 top-6 text-gray-500 hover:text-gray-700 transition-colors"
                onClick={() => setCurrentView('initial')}
              >
                ← Back
              </button>
            )}
            <button 
              className="absolute right-6 top-6 text-gray-500 hover:text-gray-700 transition-colors text-2xl font-bold"
              onClick={onClose}
            >
              ×
            </button>
          </div>

          {error && (
            <div className="mx-6 mb-6 p-4 bg-red-50 rounded-lg text-red-600 text-center">
              {error}
              {!user && (
                <button 
                  className="mt-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
                  onClick={() => window.location.href = '/signin'}
                >
                  Sign In
                </button>
              )}
            </div>
          )}

          {/* Initial View */}
          {currentView === 'initial' && (
            <div className="p-6 text-center">
              <h3 className="text-2xl font-bold mb-6 bg-gradient-to-r from-[#FF4D8D] to-[#FF7F50] bg-clip-text text-transparent">
                Current Credits
              </h3>
              <div className="text-5xl font-bold mb-8 text-gray-900">
                {credits !== null ? credits : 'Loading...'}
              </div>
              <div className="space-y-3">
                <button 
                  className="w-full py-3 px-4 rounded-lg bg-gradient-to-r from-[#FF4D8D] to-[#FF7F50] text-white font-medium hover:opacity-90 transition-opacity"
                  onClick={handleViewPricing}
                >
                  View Pricing
                </button>
                <div className="p-[2px] rounded-lg bg-gradient-to-r from-[#FF4D8D] to-[#FF7F50] w-full">
                  <button 
                    className="w-full py-3 px-4 rounded-lg bg-white text-[#FF4D8D] font-medium hover:bg-[#FF4D8D]/5 transition-colors"
                    onClick={handleShowPricing}
                  >
                    Get More Credits
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Pricing View */}
          {currentView === 'pricing' && (
            <div className="p-3">
              <h3 className="text-lg font-bold text-center mb-2 bg-gradient-to-r from-[#FF4D8D] to-[#FF7F50] bg-clip-text text-transparent">
                Choose Your Plan
              </h3>
              <div className="grid grid-cols-2 gap-2">
                {PRICING_PLANS.map((plan) => (
                  <div 
                    key={plan.name} 
                    className={`relative p-2 rounded-xl border ${
                      plan.isPopular ? 'border-[#FF4D8D]' : 'border-gray-200'
                    } hover:border-[#FF4D8D] transition-colors`}
                  >
                    {plan.isPopular && (
                      <div className="absolute -top-2 left-1/2 -translate-x-1/2 px-2 py-0.5 bg-gradient-to-r from-[#FF4D8D] to-[#FF7F50] text-white text-[10px] rounded-full">
                        Popular
                      </div>
                    )}
                    <div className="text-center">
                      <div className="text-lg font-bold text-black mb-0.5">{plan.credits}</div>
                      <div className="text-base font-bold text-[#FF4D8D] mb-0.5">
                        ${plan.price.toFixed(2)}
                        {plan.price === 0 && <span className="text-[10px] ml-1 text-green-500">FREE</span>}
                      </div>
                      <p className="text-[10px] text-gray-600 mb-1">{plan.description}</p>
                      <button 
                        className={`w-full py-1.5 px-2 rounded-lg font-medium text-xs transition-colors ${
                          plan.price === 0 
                            ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                            : 'bg-gradient-to-r from-[#FF4D8D] to-[#FF7F50] text-white hover:opacity-90'
                        }`}
                        onClick={() => handleGetCredits(plan)}
                        disabled={plan.price === 0}
                      >
                        {plan.price === 0 ? 'Included' : 'Select'}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Payment View */}
          {currentView === 'payment' && selectedPlan && clientSecret && (
            <div className="p-6">
              <div className="text-center mb-8 p-6 rounded-lg bg-gray-50">
                <h3 className="text-xl font-bold mb-2">{selectedPlan.name}</h3>
                <div className="text-3xl font-bold text-[#FF4D8D] mb-1">
                  {selectedPlan.credits} Credits
                </div>
                <div className="text-2xl font-bold text-gray-900">
                  ${selectedPlan.price.toFixed(2)}
                </div>
              </div>
              <Elements stripe={stripePromise} options={{
                clientSecret,
                appearance: {
                  theme: 'stripe',
                  variables: {
                    colorPrimary: '#FF4D8D',
                  },
                },
              }}>
                <PaymentForm 
                  clientSecret={clientSecret}
                  plan={selectedPlan}
                  onSuccess={async () => {
                    try {
                      await new Promise(resolve => setTimeout(resolve, 1000));
                      if (fetchCredits) {
                        await fetchCredits();
                      }
                      setCurrentView('initial');
                      onClose();
                    } catch (error) {
                      console.error('Error updating credits after payment:', error);
                      setError('Payment successful but failed to update credits. Please refresh the page.');
                    }
                  }}
                />
              </Elements>
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default PaymentPortal; 