import axios from 'axios';

export const UserProfileAPI = {
  /**
   * Fetches user's generated images with pagination
   * @param {string} token - Authentication token
   * @param {Object} params - Request parameters
   * @param {string} params.externalClientId - User's Firebase UID
   * @param {number} params.page - Page number for pagination
   * @returns {Promise<Array>} Array of user's generated images
   */
  getUserImages: async (token, { externalClientId, page }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/images/get/pagination/external/client/id`,
        {
          params: {
            externalClientId,
            authenticationService: 'firebase',
            page
          },
          headers: {
            'X-Auth-Token': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching user images:", error);
      throw error;
    }
  },

  /**
   * Fetches user's liked images with pagination
   * @param {string} token - Authentication token
   * @param {Object} params - Request parameters
   * @param {string} params.externalClientId - User's Firebase UID
   * @param {number} params.page - Page number for pagination
   * @returns {Promise<Array>} Array of user's liked images
   */
  getLikedImages: async (token, { externalClientId, page }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/favorites/get/image/external`,
        {
          params: {
            authenticationService: 'firebase',
            externalClientId,
            page
          },
          headers: {
            'X-Auth-Token': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching liked images:", error);
      throw error;
    }
  },

  /**
   * Deletes a generated image
   * @param {string} token - Authentication token
   * @param {string} imageId - ID of the image to delete
   * @returns {Promise<Object>} Response from the API
   */
  deleteGeneratedImage: async (token, imageId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/images/delete/image/${imageId}`,
        {
          headers: { 
            'X-Auth-Token': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      return response;  // Return full response to check status
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
    }
  },

  /**
   * Removes an image from liked images
   * @param {string} token - Authentication token
   * @param {Object} params - Request parameters
   * @param {string} params.externalClientId - User's Firebase UID
   * @param {string} params.imageId - ID of the image to unlike
   * @returns {Promise<Object>} Response from the API
   */
  deleteLikedImage: async (token, { externalClientId, imageId }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/favorites/delete/external`,
        {
          params: {
            externalClientId,
            imageId,
            authenticationService: 'firebase'
          },
          headers: { 
            'X-Auth-Token': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
      return response;  // Return full response to check status
    } catch (error) {
      console.error('Error removing liked image:', error);
      throw error;
    }
  }
};

export default UserProfileAPI; 