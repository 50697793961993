import axios from 'axios';
import { Task, TaskBoardCategories } from '../pages/plan/kanban/types.ts';
import { getAuth } from 'firebase/auth';
import { generateAuthToken } from './encryptExternalID.js';

export const saveTask = async (body: Task) => {
  const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.log("User needs to log in before planning");
      return;
    }
    const token = await generateAuthToken();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/tasks`, body, {
        headers: {
          'content-type': 'application/json',
          'X-Auth-Token': token,
          'externalClientId' : currentUser.uid,
          'authenticationService': 'firebase',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error sending message to Discord:', error);
      throw error;
    }
  };

  
export const getTasks = async () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (!currentUser) {
    console.log("User needs to log in to fetch tasks");
    return;
  }

  const token = await generateAuthToken();
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/tasks/user`, {
      headers: {
        'X-Auth-Token': token,
        'externalClientId': currentUser.uid,
        'authenticationService': 'firebase',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }
};

export const updateTask = async (id: number, updates: Partial<Task>) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (!currentUser) {
    console.log("User needs to log in to fetch tasks");
    return;
  }

  const token = await generateAuthToken();
  const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/tasks/${id}`, updates, {
    headers: {
      'X-Auth-Token': token,
      'externalClientId': currentUser.uid,
      'authenticationService': 'firebase',
    },
  });
  return response.data;
};

export const deleteTask = async (id: number) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (!currentUser) {
    console.log("User needs to log in to delete tasks");
    return;
  }

  const token = await generateAuthToken();
  try {
    await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/tasks/${id}`, {
      headers: {
        'X-Auth-Token': token,
        'externalClientId': currentUser.uid,
        'authenticationService': 'firebase',
      },
    });
    console.log(`Task with ID ${id} deleted successfully.`);
  } catch (error) {
    console.error('Error deleting task:', error);
    throw error; // Rethrow the error for further handling if needed
  }
};