import axios from 'axios';

const DISCORD_WEBHOOK_URL = process.env.REACT_APP_DISCORD_WEBHOOK_URL;

const sendToDiscord = async (message) => {
  try {
    const response = await axios.post(DISCORD_WEBHOOK_URL, message, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Discord API Error:', {
      message: error.message,
      status: error.response?.status,
      statusText: error.response?.statusText,
      responseData: error.response?.data,
    });
    throw new Error('Failed to send suggestion to Discord');
  }
};

export const sendSuggestionToDiscord = async (suggestionData) => {
  const { category, subcategory, suggestion, userEmail, additionalNotes } = suggestionData;
  
  const message = {
    username: "Wedify Suggestions Bot",
    embeds: [{
      title: `New ${category} Suggestion`,
      description: suggestion,
      fields: [
        {
          name: 'Category',
          value: category,
          inline: true
        },
        {
          name: 'Subcategory',
          value: subcategory,
          inline: true
        },
        {
          name: 'Submitted by',
          value: userEmail || 'Anonymous',
          inline: true
        },
        ...(additionalNotes ? [{
          name: 'Additional Notes',
          value: additionalNotes
        }] : [])
      ],
      color: 3066993, // Green color
      timestamp: new Date().toISOString()
    }]
  };

  return sendToDiscord(message);
};

export default sendToDiscord;
        