import axios from 'axios';
import { CalendarTask } from '../pages/plan/calendar/types.ts';
import { getAuth } from 'firebase/auth';
import { generateAuthToken } from './encryptExternalID.js';


export const saveCalenderEvent = async (body: CalendarTask) => {
  const auth = getAuth();       
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.log("User needs to log in before planning");
      return;
    }
    
    const token = await generateAuthToken();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/calendar/events`, body, {
        headers: {
          'content-type': 'application/json',
          'X-Auth-Token': token,
          'externalClientId' : currentUser.uid,
          'authenticationService': 'firebase',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error sending message to Discord:', error);
      throw error;
    }
  };

export const getCalenderEvents = async () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (!currentUser) {
    console.log("User needs to log in to fetch calednerevents");
    return;
  }

  const token = await generateAuthToken();
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/calendar/events`, {
      headers: {
        'X-Auth-Token': token,
        'externalClientId': currentUser.uid,
        'authenticationService': 'firebase',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }
};