import { generateAuthToken } from '../../utils/encryptExternalID';

export const generateToken = async () => {
  try {
    if (!process.env.REACT_APP_WEDIFY_APP_SECRET_KEY) {
      console.error("Missing REACT_APP_WEDIFY_APP_SECRET_KEY environment variable");
    }
    
    const token = await generateAuthToken();
    return token;
  } catch (error) {
    console.error("Error generating auth token:", error);
    throw error;
  }
};
