import { encrypt } from '../../utils/encryptExternalID';
import { getAuth } from 'firebase/auth';
import { generateToken } from '../shared/generateToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_WEDIFY_APP_SECRET_KEY;

// Define an enum-like object for model options
export const ModelOptions = {
  LIGHTING: 'lighting',
  PHOENIX: 'phoenix',
};

// Encrypt prompt function
export const encryptPrompt = (plaintext, key) => {
  try {
    return encrypt(plaintext, key);
  } catch (error) {
    console.error('Error encrypting prompt:', error);
    return null;
  }
};


// Check generation status function 
export const checkGenerationStatus = async (generationId) => {
  let attempts = 0;
  const maxAttempts = 30;
  const initialDelay = 1000;
  const maxDelay = 10000;

  const poll = async () => {
    if (attempts >= maxAttempts) {
      throw new Error('Generation timed out');
    }

    attempts++;

    try {
      const token = await generateToken();
      
      const response = await fetch(`${API_BASE_URL}/api/images/get/image/retrieve`, {
        method: 'GET',
        headers: {
          'imageId': generationId,
          'X-Auth-Token': token,
        },
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get('Content-Type');
      const contentLength = response.headers.get('Content-Length');

      if (contentType && contentType.includes('image') && contentLength && parseInt(contentLength) > 0) {
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      } else {
        const delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
        await new Promise(resolve => setTimeout(resolve, delay));
        return poll();
      }
    } catch (error) {
      const delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
      await new Promise(resolve => setTimeout(resolve, delay));
      return poll();
    }
  }

  return poll();
};

export const generateImage = async (prompt, token, userId, model) => {
  if (!Object.values(ModelOptions).includes(model)) {
    throw new Error(`Invalid model: ${model}. Valid options are: ${Object.values(ModelOptions).join(', ')}`);
  }

  try {
    const encryptedPrompt = encryptPrompt(prompt, SECRET_KEY);
    
    const generateResponse = await fetch(`${API_BASE_URL}/api/images/get/image/generate`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': token,
        'whatYouLookinAt': encryptedPrompt, 
        'authId': userId,
        'authenticationService': 'firebase',
        'model': model
      },
      body: JSON.stringify({ prompt: encryptedPrompt }) 
    });

    if (!generateResponse.ok) {
      const responseText = await generateResponse.text();
      throw new Error(`HTTP error! status: ${generateResponse.status}, body: ${responseText}`);
    }

    const generateData = await generateResponse.json();

    if (generateData && generateData.sdGenerationJob && generateData.sdGenerationJob.generationId) {
      const generationId = generateData.sdGenerationJob.generationId;

      const imageUrl = await checkGenerationStatus(generationId);
      return imageUrl;
    } else {
      throw new Error('No generationId returned');
    }
  } catch (error) {
    throw error;
  }
}; 