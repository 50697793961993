import React, { useState, useEffect, useCallback } from 'react';
import { Wand2, RefreshCw, Heart, PlusCircle } from 'lucide-react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { fetchUserCredits, updateCreditsAfterGeneration } from '../../services/creditsService.js';
import { sendSuggestionToDiscord } from '../../utils/discordWebhook';
import { Button } from '../ui/Button.tsx';
import { StepComponent } from './StepComponent.tsx';
import { Carousel } from './Carousel.tsx';
import { CreditsSection } from './CreditsSection.tsx';
import { ProgressBar } from './GradientProgress.tsx';
import PaymentPortal from '../payment/PaymentPortal.js';
import DiscordModal from '../DiscordModal';


// Constants
const GRADIENT_COLORS = {
  pink: '#FF69B4',
  orange: '#FF7F50',
};

const styles = `
  @keyframes gradient-x {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  @keyframes text-shimmer {
    0% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  .animate-gradient-x {
    background-size: 200% 200%;
    animation: gradient-x 3s ease infinite;
  }

  .animate-text {
    background: linear-gradient(to right, #FF4D8D, #FF7F50, #FF4D8D);
    background-size: 200% auto;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: text-shimmer 2s linear infinite;
  }
`;

interface DesignProps {
  steps: Array<{
    id: string;
    title: string;
    type: 'grid' | 'color-picker';
    options: Array<{ name: string; image?: string; hex?: string; subOptions?: string[] }>;
    allowCustom?: boolean;
    allowMultiple?: boolean;
    maxSelections?: number;
  }>;
  selections: Record<string, any>;
  subSelections: Record<string, any>;
  previewItems: Array<{ url: string; label: string; prompt?: string }>;
  loading: boolean;
  progress: number;
  error: string;
  credits: number;
  title: string;
  onSelectionChange: (stepId: string, value: any) => void;
  onSubSelectionChange: (stepId: string, value: any) => void;
  onGenerate: () => Promise<void>;
  onSave: (item: { url: string; label: string; prompt?: string }) => Promise<void>;
}

export function DesktopDesign({
  steps,
  selections,
  subSelections,
  previewItems,
  loading,
  progress,
  error,
  title,
  onSelectionChange,
  onSubSelectionChange,
  onGenerate,
  onSave,
}: DesignProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [user, setUser] = useState(null);
  const [localCredits, setLocalCredits] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [simulatedProgress, setSimulatedProgress] = useState(0);
  const [savedItems, setSavedItems] = useState<Record<string, boolean>>({});
  const [showError, setShowError] = useState(false);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [currentSuggestionStep, setCurrentSuggestionStep] = useState<{
    id: string;
    title: string;
  } | null>(null);

  // Fetch user and credits on component mount
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchUserCredits(currentUser.uid)
          .then((credits) => setLocalCredits(credits))
          .catch((error) => console.error('Error fetching credits:', error));
      }
    });

    return () => unsubscribe();
  }, []);

  // Simulate progress for testing
  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setSimulatedProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevProgress + 10; // Increment by 10% every second
        });
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setSimulatedProgress(0); // Reset progress when loading is complete
    }
  }, [loading]);

  // Handle error display
  useEffect(() => {
    if (error) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 3000); // Hide after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [error]);

  // Load saved states on mount
  useEffect(() => {
    const savedState = localStorage.getItem('savedItems');
    if (savedState) {
      setSavedItems(JSON.parse(savedState));
    }
  }, []);

  // Handle image navigation
  const handleNextImage = useCallback(() => {
    setCurrentImageIndex((prev) => (prev + 1) % previewItems.length);
  }, [previewItems.length]);

  const handlePrevImage = useCallback(() => {
    setCurrentImageIndex((prev) => (prev - 1 + previewItems.length) % previewItems.length);
  }, [previewItems.length]);



  // Handle generation completion
  const handleGenerate = useCallback(async () => {
    try {
      await onGenerate(); // Trigger the generation process
      if (user) {
        const updatedCredits = await updateCreditsAfterGeneration(user.uid, true);
        if (updatedCredits !== null) {
          setLocalCredits(updatedCredits); // Update local credits state
        }
      }
    } catch (error) {
      console.error('Error during generation or credit update:', error);
    }
  }, [onGenerate, user]);

  // Handle save
  const handleSave = async (item) => {
    try {
      await onSave(item);
      setSavedItems(prev => {
        const newState = {
          ...prev,
          [item.url]: true
        };
        localStorage.setItem('savedItems', JSON.stringify(newState));
        return newState;
      });
    } catch (error) {
      console.error('Error saving:', error);
    }
  };

  const progressToDisplay = loading ? simulatedProgress : progress;

  const handleAddClick = (stepId: string, stepTitle: string) => {
    setCurrentSuggestionStep({ id: stepId, title: stepTitle });
    setShowSuggestionModal(true);
  };

  const handleSuggestionSubmit = async (suggestionData: any) => {
    try {
      const enrichedData = {
        ...suggestionData,
        stepId: currentSuggestionStep?.id,
        category: title,
        subcategory: currentSuggestionStep?.title
      };
      
      await sendSuggestionToDiscord(enrichedData);
      setShowSuggestionModal(false);
    } catch (error) {
      console.error('Failed to submit suggestion:', error);
      throw new Error('Failed to submit suggestion');
    }
  };

  return (
    <div className="min-h-screen bg-white pt-16">
      <style>{styles}</style>
      <div className="h-[calc(100vh-7rem)] flex mt-10 border-t border-[#FF4D8D]/10">
        {/* Left Side - Controls */}
        <div className="w-[540px] border-r border-[#FF4D8D]/10 bg-gradient-to-b from-white to-[#FF4D8D]/[0.02] p-6 overflow-y-auto shadow-[1px_0_0_0_rgba(255,77,141,0.05)]">
          <div className="space-y-8">
            <div className="text-center">
              <h1 className="text-2xl md:text-3xl font-bold mb-1.5 bg-gradient-to-r from-[#FF4D8D] to-[#FF7F50] bg-clip-text text-transparent">
                {title}
              </h1>
              <div className="w-12 h-0.5 mx-auto rounded-full bg-gradient-to-r from-[#FF4D8D] to-[#FF7F50] opacity-80"></div>
            </div>
            {/* Render Steps */}
            {steps.map((step) => (
              <div key={step.id} className="space-y-4">
                <StepComponent
                  step={step}
                  selections={selections}
                  subSelections={subSelections}
                  onSelectionChange={onSelectionChange}
                  onSubSelectionChange={onSubSelectionChange}
                />
                
                {/* Add suggestion button */}
                <button
                  onClick={() => handleAddClick(step.id, step.title)}
                  className="w-full py-2 border border-dashed border-[#FF4D8D]/30 rounded-md text-[#FF4D8D] text-sm transition-colors flex items-center justify-center gap-1.5"
                >
                  <PlusCircle className="w-5 h-5" />
                  <span>Suggest {step.id.charAt(0).toUpperCase() + step.id.slice(1)}</span>
                </button>
              </div>
            ))}

            {/* Generate Button */}
            <Button
              onClick={handleGenerate}
              disabled={loading}
              gradientFrom={GRADIENT_COLORS.pink}
              gradientTo={GRADIENT_COLORS.orange}
            >
              {loading ? (
                <>
                  <RefreshCw className="w-5 h-5 animate-spin" />
                  Generating...
                </>
              ) : (
                <>
                  <Wand2 className="w-5 h-5" />
                  Generate Design
                </>
              )}
            </Button>
          </div>
        </div>

        {/* Right Side - Canvas */}
        <div className="flex-1 bg-white">
          <div className="h-16 border-b border-[#FF4D8D]/10 shadow-[0_1px_0_0_rgba(255,77,141,0.05)] flex items-center justify-between px-8 bg-[#h-16 border-b border-[#FF4D8D]/10 shadow-[0_1px_0_0_rgba(255,77,141,0.05)] flex items-center justify-between px-8 bg-[#FF7F50]/[0.08]]/[0.08]">
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 rounded-full bg-gradient-to-r from-[#FF69B4] to-[#FF7F50] animate-gradient-x" />
              <span className="text-sm font-medium text-gray-600">
                Powered by wedify.<span className="animate-text">ai</span>
              </span>
            </div>
            <CreditsSection
              localCredits={localCredits}
              setShowPaymentModal={setShowPaymentModal}
            />
          </div>
          <Canvas
            loading={loading}
            progressToDisplay={progressToDisplay}
            previewItems={previewItems}
            currentImageIndex={currentImageIndex}
            setCurrentImageIndex={setCurrentImageIndex}
            onSave={handleSave}
            savedItems={savedItems}
            handlePrevImage={handlePrevImage}
            handleNextImage={handleNextImage}
          />
        </div>
      </div>

      {/* Error Display */}
      {error && showError && <ErrorDisplay error={error} />}

      {/* Payment Portal */}
      <PaymentPortal
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        user={user}
        fetchCredits={async () => {
          if (user) {
            const updatedCredits = await fetchUserCredits(user.uid);
            setLocalCredits(updatedCredits);
          }
        }}
        credits={localCredits}
      />

      {/* Discord Suggestion Modal */}
      <DiscordModal
        isOpen={showSuggestionModal}
        onClose={() => setShowSuggestionModal(false)}
        onSubmit={handleSuggestionSubmit}
        category={steps.id}
        subcategory={currentSuggestionStep?.title || ''}
      />
    </div>
  );
}

const Canvas = ({
  loading,
  progressToDisplay,
  previewItems,
  currentImageIndex,
  setCurrentImageIndex,
  onSave,
  savedItems,
  handlePrevImage,
  handleNextImage,
}) => (
  <div className="p-16 h-[calc(100%-4rem)] flex flex-col">
    {loading ? (
      <ProgressBar progress={progressToDisplay} />
    ) : previewItems.length > 0 ? (
      <Carousel
        previewItems={previewItems}
        currentImageIndex={currentImageIndex}
        setCurrentImageIndex={setCurrentImageIndex}
        onSave={onSave}
        savedItems={savedItems}
        handlePrevImage={handlePrevImage}
        handleNextImage={handleNextImage}
      />
    ) : (
      <EmptyState />
    )}
  </div>
);

const EmptyState = () => (
  <div className="flex-1 flex items-center justify-center">
    <div className="max-w-2xl mx-auto px-8 py-12 text-center">
      <div className="space-y-8">
        <div className="relative w-24 h-24 mx-auto">
          <div className="absolute inset-0 bg-[#FF69B4] rounded-full opacity-20"></div>
          <Wand2 className="w-12 h-12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[#FF69B4] animate-pulse" />
        </div>
        <div className="space-y-4">
          <h2 className="text-3xl font-bold text-gray-900">
            Transform Your Wedding Vision
          </h2>
          <p className="text-xl text-gray-600 max-w-xl mx-auto">
            Our AI-powered design assistant is ready to create your perfect wedding atmosphere
          </p>
        </div>
      </div>
    </div>
  </div>
);

const ErrorDisplay = ({ error }) => (
  <div className="fixed bottom-4 left-1/2 -translate-x-1/2 bg-red-100 text-red-600 px-4 py-2 rounded-lg shadow-lg">
    {error}
  </div>
);