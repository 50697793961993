import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DesktopDesign } from '../../../components/design/desktopDesign.tsx';
import { MobileDesign } from '../../../components/design/mobileDesign.tsx';
import getImageUrl from '../../../utils/imageUtils';
import { getAuth } from 'firebase/auth';
import { fetchUserCredits, updateCreditsAfterGeneration } from '../../../services/creditsService';
import { generateToken } from '../../../services/shared/generateToken';
import { generateImage, ModelOptions } from '../../../services/api/designApi';
import { uploadBookmark } from '../../../services/api/bookmarkApi';
import { useSharedDesignState } from '../../../hooks/useSharedDesignState';

const Decor = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // Use shared state
  const {
    selections,
    subSelections,
    previewItems,
    setSelections,
    setSubSelections,
    setPreviewItems,
  } = useSharedDesignState('Decor');

  // Local state
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [credits, setCredits] = useState(null);

  // Define design options
  const venueOptions = [
    { name: 'Indoor', image: getImageUrl('themesOption/Indoor.jpg') },
    { name: 'Outdoor', image: getImageUrl('themesOption/Outdoor.jpg') },
  ];

  const themeOptions = [
    { name: 'Mandap', image: getImageUrl('themesOption/Mandap.jpg') },
    { name: 'Rustic', image: getImageUrl('themesOption/Rustic.jpg') },
    { name: 'Bohemian', image: getImageUrl('themesOption/Bohemian.jpg') },
    { name: 'Minimalist', image: getImageUrl('themesOption/Minimalistic.jpg') },
    { name: 'Vineyard', image: getImageUrl('themesOption/Vineyard.jpg') },
    { name: 'Beach', image: getImageUrl('themesOption/Beach.jpg') },
    { name: 'Southwestern', image: getImageUrl('themesOption/Southwestern.jpg') },
    { name: 'Luxury', image: getImageUrl('themesOption/Luxury.jpg') },
    { name: 'Classic', image: getImageUrl('themesOption/Classic.jpg') },
    { name: 'Modern', image: getImageUrl('themesOption/Modern.jpg') },
    { name: 'Tropical', image: getImageUrl('themesOption/Tropical.jpg') },
    { name: 'Woodland', image: getImageUrl('themesOption/Woodland.jpg') },
  ];

  const colorPresets = [
    { name: 'Gold', hex: '#dcc282' },
    { name: 'Silver', hex: '#C0C0C0' },
    { name: 'Blush Pink', hex: '#FFB6C1' },
    { name: 'Sage Green', hex: '#9DC183' },
    { name: 'Navy Blue', hex: '#000080' },
    { name: 'Burgundy', hex: '#800020' },
    { name: 'Dusty Blue', hex: '#6699CC' },
    { name: 'Champagne', hex: '#F7E7CE' },
    { name: 'Lavender', hex: '#E6E6FA' },
    { name: 'Pearl White', hex: '#F0EAD6' },
    { name: 'Terracotta', hex: '#E2725B' },
    { name: 'Rust', hex: '#B7410E' },
    { name: 'Gray', hex: '#808080' },
    { name: 'Emerald', hex: '#50C878' },
    { name: 'Coral', hex: '#FF7F50' },
    { name: 'Mint', hex: '#98FF98' },
    { name: 'Peach', hex: '#FFE5B4' },
  ];

  const flowerOptions = [
    { name: 'Wisteria', image: getImageUrl('wisteria.jpg') },
    { name: 'Hydrangeas', image: getImageUrl('hydrangea.jpg') },
    { name: 'Roses', image: getImageUrl('roses.jpg') },
    { name: 'Tulips', image: getImageUrl('tulips.jpg') },
    { name: "Baby's Breath", image: getImageUrl('BabysBreath.jpg') },
    { name: 'Orchids', image: getImageUrl('Orchids.jpg') },
    { name: 'Marigolds', image: getImageUrl('Marigolds.jpg') },
    { name: 'Lilies', image: getImageUrl('Lilies.jpg') },
    { name: 'Peonies', image: getImageUrl('Peonies.jpg') },
    { name: 'Sunflowers', image: getImageUrl('Sunflowers.jpg') },
  ];

  const seatingOptions = [
    {
      name: 'Chivari Chair',
      image: getImageUrl('Chiavari.jpg'),
      subOptions: ['Gold', 'Silver', 'Wooden'],
    },
    {
      name: 'Ghost Chair',
      image: getImageUrl('GhostChair.jpg'),
      description: 'A transparent, elegant chair designed with a smooth, curved backrest and minimalist style.',
    },
    {
      name: 'Bentwood Chair',
      image: getImageUrl('Bentwood.jpg'),
    },
    {
      name: 'Wooden Chair',
      image: getImageUrl('Folding.jpg'),
    },
  ];

  // Define steps for the design process
  const steps = [
    {
      id: 'venue',
      title: 'Select Venue',
      type: 'grid',
      options: venueOptions,
    },
    {
      id: 'theme',
      title: 'Select Theme',
      type: 'grid',
      options: themeOptions,
      allowCustom: true,
    },
    {
      id: 'color',
      title: 'Select Color (Up to 3)',
      type: 'color-picker',
      options: colorPresets,
      allowMultiple: true,
      maxSelections: 3,
    },
    {
      id: 'flowers',
      title: 'Select Flowers (Up to 3)',
      type: 'grid',
      options: flowerOptions,
      allowCustom: true,
      allowMultiple: true,
      maxSelections: 2,
    },
    {
      id: 'seating',
      title: 'Select Seating',
      type: 'grid',
      options: seatingOptions,
      allowCustom: true,
    },
  ];

  // Handlers
  const handleSelectionChange = (stepId, value) => {
    if (stepId === 'color' || stepId === 'flowers') {
      // Handle multiple selections for color and flowers
      setSelections((prev) => {
        const currentSelections = prev[stepId] || [];
        const updatedSelections = currentSelections.includes(value)
          ? currentSelections.filter((item) => item !== value) // Deselect if already selected
          : [...currentSelections, value]; // Add to selection

        // Ensure maxSelections is respected
        const maxSelections = steps.find((step) => step.id === stepId)?.maxSelections || Infinity;
        if (updatedSelections.length > maxSelections) {
          setError(`You can only select up to ${maxSelections} ${stepId === 'color' ? 'colors' : 'flowers'}.`);
          return prev;
        }

        return { ...prev, [stepId]: updatedSelections };
      });
    } else {
      // Handle single selections for other steps
      setSelections((prev) => ({ ...prev, [stepId]: value }));
    }
  };

  const handleSubSelectionChange = (stepId, value) => {
    setSubSelections((prev) => ({ ...prev, [stepId]: value }));
  };

  const generateVisualizations = async () => {
    const isAllSelectionsComplete = steps.every(step => selections[step.id]);

    if (!isAllSelectionsComplete) {
      setError('Please complete all design options');
      return;
    }

    const auth = getAuth();
    const currentUser = auth.currentUser;
    
    if (!currentUser) {
      setError('Please log in to generate designs');
      return;
    }

    const userCredits = await fetchUserCredits(currentUser.uid);
    if (userCredits < 30) {
      setError('Out of credits, Please Purchase Credits');
      return;
    }

    setLoading(true);
    setProgress(0);
    setError('');

    try {
      // Get the selected seating option
      const selectedSeating = seatingOptions.find(option => option.name === selections.seating);

      // Include the seating description in the prompt if it exists
      const seatingDescription = selectedSeating?.description ? `, featuring ${selectedSeating.description}` : '';

      const stagePrompt = `A wide angle view of a ${selections.theme} ${selections.venue} wedding stage setup with ${selections.color} color theme and ${selections.flowers} floral arrangements`;
      const tablePrompt = `A 60-degree angle from above and slightly diagonal towards the upper-left captures the entire ${selections.theme} ${selections.venue} wedding table setup, featuring a ${selections.color} color theme, adorned with ${selections.flowers} floral arrangements, and completed with ${selections.seating} ${subSelections.seating ? `(${subSelections.seating})` : ''} chairs${seatingDescription}`;

      setProgress(10);
      
      const token = await generateToken();

      const [stageImageUrl, tableImageUrl] = await Promise.all([
        generateImage(stagePrompt, token, currentUser.uid, ModelOptions.LIGHTING),
        generateImage(tablePrompt, token, currentUser.uid, ModelOptions.LIGHTING)
      ]);

      setPreviewItems([
        { url: stageImageUrl, label: 'Stage Decor', prompt: stagePrompt },
        { url: tableImageUrl, label: 'Table Decor', prompt: tablePrompt }
      ]);

      setProgress(100);

      const updatedCredits = await updateCreditsAfterGeneration(currentUser.uid);
      setCredits(updatedCredits);
    } catch (error) {
      console.error('Error generating visualizations:', error);
      setError('Failed to generate visualizations');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (item) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        setError('Please log in to save designs');
        return;
      }

      await uploadBookmark(item, 'Decor', selections, currentUser);
    } catch (error) {
      console.error('Error saving design:', error);
      setError('Failed to save design');
    }
  };

  return (
    <>
      {isMobile ? (
        <MobileDesign
          steps={steps}
          title="Design Your Wedding Decor"
          selections={selections}
          subSelections={subSelections}
          previewItems={previewItems}
          loading={loading}
          progress={progress}
          error={error}
          credits={credits}
          onSelectionChange={handleSelectionChange}
          onSubSelectionChange={handleSubSelectionChange}
          onGenerate={generateVisualizations}
          onSave={handleSave}
        />
      ) : (
        <DesktopDesign
          steps={steps}
          selections={selections}
          subSelections={subSelections}
          previewItems={previewItems}
          loading={loading}
          progress={progress}
          error={error}
          title="Design Your Wedding Decor"
          credits={credits}
          onSelectionChange={handleSelectionChange}
          onSubSelectionChange={handleSubSelectionChange}
          onGenerate={generateVisualizations}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default Decor;