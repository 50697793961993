import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { BudgetCategory } from './types';
import { createPortal } from 'react-dom';
interface BudgetPercentageEditorProps {
  isOpen: boolean;
  onClose: () => void;
  categories: BudgetCategory[];
  totalBudget: number;
  onSave: (updatedCategories: BudgetCategory[]) => Promise<void>;
}

export const BudgetPercentageEditor: React.FC<BudgetPercentageEditorProps> = ({
  isOpen,
  onClose,
  categories,
  totalBudget,
  onSave,
}) => {
  const [editedCategories, setEditedCategories] = useState<BudgetCategory[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setEditedCategories(categories);
  }, [categories]);

  const handlePriceChange = (id: number, newPrice: string) => {
    const price = Math.max(0, Number(newPrice) || 0);
    
    setEditedCategories(prev => {
      const updatedCategories = prev.map(cat => 
        cat.id === id ? { ...cat, amount: price } : cat
      );
      
      // Calculate new total and percentages
      const newTotal = updatedCategories.reduce((sum, cat) => sum + cat.amount, 0);
      return updatedCategories.map(cat => ({
        ...cat,
        percentage: Number(((cat.amount / newTotal) * 100))
      }));
    });
  };

  const calculateTotal = () => {
    const total = editedCategories.reduce((sum, cat) => sum + cat.percentage, 0);
    return Number(total.toFixed(1));
  };

  const handleSave = async () => {
    await onSave(editedCategories);
    onClose();
  };

  if (!isOpen) return null;

  const currentTotal = editedCategories.reduce((sum, cat) => sum + cat.amount, 0);

  return createPortal(
    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="w-[280px] bg-gradient-to-b from-[#F8E8FF] to-[#E8F0FF] rounded-lg shadow-lg relative animate-fadeIn mx-4">
        <div className="absolute inset-0 bg-white/40 backdrop-blur-md rounded-lg" />
        
        {/* Header */}
        <div className="relative flex items-center justify-between px-2.5 py-1.5 border-b border-pink-100/50">
          <h2 className="text-xs font-medium text-gray-800">Edit Category Budgets</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <X className="w-3.5 h-3.5" />
          </button>
        </div>

        {/* Content */}
        <div className="relative px-2.5 py-2">
          {error && (
            <div className="mb-1.5 p-1 bg-red-50/80 backdrop-blur-sm border border-red-200 rounded text-red-600 text-[10px]">
              {error}
            </div>
          )}

          <div className="grid grid-cols-1 gap-1">
            {editedCategories.map((category) => (
              <div key={category.id} className="flex items-center gap-1.5">
                <div className="w-2 h-2 rounded-full flex-shrink-0" style={{ backgroundColor: category.color }} />
                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between">
                    <label className="block text-[10px] font-medium text-gray-700 truncate">
                      {category.name}
                    </label>
                    <span className="text-[9px] text-gray-500 ml-1">{category.percentage.toFixed(1)}%</span>
                  </div>
                  <div className="relative rounded">
                    <div className="absolute inset-y-0 left-0 pl-1.5 flex items-center pointer-events-none">
                      <span className="text-gray-500 text-[9px]">$</span>
                    </div>
                    <input
                      type="number"
                      value={category.amount}
                      onChange={(e) => handlePriceChange(category.id, e.target.value)}
                      className="block w-full pl-4 pr-1.5 py-0.5 bg-white/90 border border-pink-100/50 rounded focus:outline-none focus:ring-1 focus:ring-[#FF4D8D]/20 focus:border-[#FF4D8D]/30 transition-all text-[10px] text-gray-700 backdrop-blur-sm"
                      placeholder="0"
                      min="0"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Footer */}
          <div className="mt-2 pt-1.5 border-t border-pink-100/50">
            <div className="flex items-center justify-between mb-1.5">
              <span className="font-medium text-gray-700 text-[10px]">Total Budget:</span>
              <span className={`text-xs font-bold ${Math.abs(calculateTotal() - 100) > 0.1 ? 'text-red-500' : 'text-green-500'}`}>
                ${currentTotal.toLocaleString()}
              </span>
            </div>

            <div className="flex justify-end gap-1.5">
              <button
                type="button"
                onClick={onClose}
                className="px-2 py-0.5 text-gray-600 hover:text-gray-800 text-[9px] transition-colors rounded hover:bg-gray-100/50"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSave}
                className="px-2 py-0.5 bg-gradient-to-r from-[#FF4D8D] to-[#FF7F8E] text-white text-[9px] rounded hover:from-[#FF3D7D] hover:to-[#FF6F7E] transition-all shadow-sm hover:shadow-[0_2px_8px_rgba(255,77,141,0.3)]"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};