import React, { useEffect, useState } from 'react';
import { CheckCircle, DollarSign } from 'lucide-react';
import { CategoryChecklist } from './CategoryChecklist.tsx';
import { getTasks } from '../../../utils/tasks.ts'; // Adjust the import path as necessary
import { Task } from '../kanban/types.ts';
import { getBudget } from '../../../utils/budget.ts';
import { updateTask } from '../../../utils/tasks.ts'

interface Category {
  id: string;
  title: string;
  icon: string;
  tasks: Task[];
}

export const categories: Category[] = [
  {
    id: 'venue',
    title: 'Venue & Ceremony',
    icon: '🏰',
    tasks: [] // Initially empty
  },
  {
    id: 'website',
    title: 'Wedding Website',
    icon: '💻',
    tasks: [] // Initially empty
  },
  {
    id: 'photo',
    title: 'Photos & Videos',
    icon: '📸',
    tasks: [] // Initially empty
  },
  {
    id: 'food',
    title: 'Food & Drink',
    icon: '🍽️',
    tasks: [] // Initially empty
  },
  {
    id: 'attire',
    title: 'Attire & Accessories',
    icon: '👗',
    tasks: [] // Initially empty
  },
  {
    id: 'music',
    title: 'Music & Entertainment',
    icon: '🎵',
    tasks: [] // Initially empty
  },
  {
    id: 'flowers',
    title: 'Flowers & Decor',
    icon: '💐',
    tasks: [] // Initially empty
  },
  {
    id: 'invitations',
    title: 'Invitations & Paper',
    icon: '✉️',
    tasks: [] // Initially empty
  },
  {
    id: 'planning',
    title: 'Planning & Admin',
    icon: '📋',
    tasks: [] // Initially empty
  },
  {
    id: 'honeymoon',
    title: 'Honeymoon',
    icon: '✈️',
    tasks: [] // Initially empty
  }
];

// Add keyframe animations
const glowingText = `
  @keyframes gradientGlow {
    0% {
      background-position: 0% 50%;
      text-shadow: 0px 0px 8px rgba(255, 127, 142, 0.4);
      opacity: 1;
    }
    50% {
      background-position: 100% 50%;
      text-shadow: 0px 0px 12px rgba(255, 214, 182, 0.5);
      opacity: 0.7;
    }
    100% {
      background-position: 0% 50%;
      text-shadow: 0px 0px 8px rgba(231, 216, 240, 0.5);
      opacity: 1;
    }
  }

  .gradient-text {
    background: linear-gradient(135deg, #FF7F8E, #FFD6B6, #E7D8F0);
    background-size: 200% 200%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradientGlow 4s infinite alternate ease-in-out;
  }
`;

export const WeddingPlanSummary: React.FC = () => {
  const [updatedCategories, setUpdatedCategories] = useState(categories);
  const [completedTasks, setCompletedTasks] = useState<Task[]>([]);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [totalTasks, setTotalTasks] = useState(0); // State to hold total tasks
  const [budget, setBudget] = useState(0); // State to hold total tasks
  
  
  const overallProgress = totalTasks === 0 ? 0 : Math.round((completedTasks.length / totalTasks) * 100);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const budgetSections = await getBudget();
        const totalCost = budgetSections.reduce((acc, item) => acc + (item.cost || 0), 0);
        setBudget(totalCost)
        const tasks = await getTasks();
        if (tasks && tasks.length > 0) {
          const completedTasks = tasks.filter(task => task.status === 'DONE');
          setCompletedTasks(completedTasks);
          // Map tasks to categories
          const tasksByCategory = updatedCategories.map(category => {
            const categoryTasks = tasks
              .filter(task => task.taskBoard.toLowerCase().includes(category.id.toLowerCase()))
            return { ...category, tasks: categoryTasks }; // Update category with task names
          });
          setUpdatedCategories(tasksByCategory); // Update state with tasks

          // Set totalTasks based on the length of tasks
          const total = tasks.length; // Get the total number of tasks
          setTotalTasks(total); // Update the totalTasks state
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks(); // Call the async function
  }, []); // Empty dependency array to run only on mount

  useEffect(() => {
    // Add the styles to the document
    const styleSheet = document.createElement("style");
    styleSheet.innerText = glowingText;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const toggleTask = async (task: Task, isCompleted: boolean) => {
    const updatedTask: Task = {
      name: task.name,
      description: task.description || '',
      priority: task.priority || 'MEDIUM',
      dueDate: task.dueDate || null,
      updated_at: new Date().toISOString(),
      status: isCompleted ? 'DONE' : 'TODO'
    };

    await updateTask(task.id, updatedTask)
    setCompletedTasks(prev => 
      prev.includes(task) 
        ? prev.filter(t => t !== task)
        : [...prev, task]
    );
  };

  const toggleCategory = (categoryId: string) => {
    setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
  };

  return (
    <div className="mx-auto mt-8 sm:mt-16">
      <div className="text-center mb-4 sm:mb-8">
      </div>

      <div className="grid grid-cols-2 gap-2 sm:gap-4 mb-6 sm:mb-8 px-2 sm:px-0">
        <div className="bg-white rounded-xl sm:rounded-2xl p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow border border-[#FFD3DA]">
          <div className="flex items-start sm:items-center justify-between">
            <div className="flex items-center">
              <CheckCircle className="w-6 h-6 sm:w-6 sm:h-6 text-[#E74C88] mr-2" />
              <span className="text-sm sm:text-sm md:text-base text-[#6E497E]">Overall Progress</span>
            </div>
            <span className="text-base sm:text-lg font-semibold text-[#E74C88]">
              {overallProgress}%
            </span>
          </div>
          <div className="mt-2.5 sm:mt-2 bg-[#FFD3DA]/30 rounded-full h-2.5 sm:h-2.5">
            <div 
              className="bg-[#6E497E] h-2.5 sm:h-2.5 rounded-full transition-all duration-300"
              style={{ width: `${overallProgress}%` }}
            />
          </div>
        </div>

        <div className="bg-white rounded-xl sm:rounded-2xl p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow border border-[#FFD3DA]">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 sm:gap-3">
            <div className="flex items-center">
              <DollarSign className="w-5 h-5 sm:w-5 sm:h-5 text-rose-500 mr-2 flex-shrink-0" />
              <span className="text-sm sm:text-sm md:text-base text-gray-700 whitespace-nowrap">Budget Spent</span>
            </div>
            <span className="text-base sm:text-lg font-semibold text-rose-500">
              ${budget.toLocaleString()}
            </span>
          </div>
        </div>
      </div>

      <div className="space-y-4 sm:space-y-4 mb-8 sm:mb-24">
        {updatedCategories.map((category) => (
          <CategoryChecklist
            key={category.id}
            category={category}
            isExpanded={expandedCategory === category.id}
            onToggle={() => toggleCategory(category.id)}
            completedTasks={completedTasks}
            onToggleTask={toggleTask}
          />
        ))}
      </div>

      <div className="h-12 sm:h-24" />
    </div>
  );
};

export default WeddingPlanSummary;