import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail
} from 'firebase/auth';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../utils/firebase';
import CryptoJS from 'crypto-js';

export const AuthAPI = {
  // Token generation
  encrypt: (plaintext, key) => {
    try {
      if (!key) {
        throw new Error('Encryption key is missing');
      }
      
      const keyBytes = CryptoJS.enc.Hex.parse(key);
      const encrypted = CryptoJS.AES.encrypt(plaintext, keyBytes, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      
      return encrypted.toString();
    } catch (error) {
      console.error('Encryption error:', error);
      throw error;
    }
  },

  generateToken: () => {
    try {
      const externalId = '221312312'; // Hardcoded external ID
      const utcTime = new Date().toISOString();
      const combinedString = `${externalId}----------${utcTime}`;
      
      const secretKey = process.env.REACT_APP_WEDIFY_APP_SECRET_KEY;
      if (!secretKey) {
        throw new Error('Secret key is not configured');
      }

      return AuthAPI.encrypt(combinedString, secretKey);
    } catch (error) {
      console.error('Error generating auth token:', error);
      throw error;
    }
  },

  // Sign In with Email
  signInWithEmail: async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
    return { userCredential, userDoc };
  },

  // Google Sign In
  signInWithGoogle: async () => {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const userDoc = await getDoc(doc(db, "users", result.user.uid));
    return { result, userDoc };
  },

  // Sign Up
  signUp: async (email, password, userData, apiUserData) => {
    try {
      const token = AuthAPI.generateToken();
      if (!token) {
        throw new Error('Failed to generate authentication token');
      }

      // First API call to add user
      const apiResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/login/addUser`, {
        method: 'POST',
        headers: {
          'accept': '*/*',
          'accept-language': 'en-US,en;q=0.9',
          'content-type': 'application/json',
          'origin': 'http://localhost:3000',
          'priority': 'u=1, i',
          'referer': 'http://localhost:3000/',
          'X-Auth-Token': token
        },
        body: JSON.stringify({
          emailAddress: email,
          venderType: userData.userType === 'vendor' ? 'vendor' : null,
          companyName: userData.userType === 'vendor' ? userData.companyName : null,
          phoneNumber: userData.userType === 'vendor' ? userData.phoneNumber : null,
          location: userData.userType === 'vendor' ? userData.locationName : null,
          wedify_user_type: userData.userType === 'vendor' ? 'vender' : 'couple'
        })
      });

      if (!apiResponse.ok) {
        const errorText = await apiResponse.text();
        throw new Error(`Failed to add user to API: ${apiResponse.status} ${errorText}`);
      }

      // Create Firebase user without email verification
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Update profile
      if (userData.userType === 'vendor') {
        await updateProfile(userCredential.user, { displayName: userData.companyName });
      } else {
        await updateProfile(userCredential.user, { displayName: email.split('@')[0] });
      }

      // Save to Firestore
      await setDoc(doc(db, "users", userCredential.user.uid), {
        ...userData,
        uid: userCredential.user.uid
      });

      // Update API with Firebase UID
      const updateApiResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/login/users/modify/email`, {
        method: 'PUT',
        headers: {
          'accept': '*/*',
          'accept-language': 'en-US,en;q=0.9',
          'content-type': 'application/json',
          'origin': 'http://localhost:3000',
          'email': email,
          'X-Auth-Token': token
        },
        body: JSON.stringify({
          externalClientId: userCredential.user.uid,
          authenticationService: "firebase"
        })
      });

      if (!updateApiResponse.ok) {
        const updateErrorText = await updateApiResponse.text();
        throw new Error(`Failed to update user in API: ${updateApiResponse.status} ${updateErrorText}`);
      }

      return userCredential;
    } catch (error) {
      if (auth.currentUser) {
        await auth.currentUser.delete();
      }
      throw error;
    }
  },

  // Password Reset
  sendPasswordReset: async (email) => {
    await sendPasswordResetEmail(auth, email);
  }
}; 