import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DesktopDesign } from '../../../components/design/desktopDesign.tsx';
import { MobileDesign } from '../../../components/design/mobileDesign.tsx';
import { getAuth } from 'firebase/auth';
import { fetchUserCredits, updateCreditsAfterGeneration } from '../../../services/creditsService';
import { generateToken } from '../../../services/shared/generateToken';
import { generateImage, ModelOptions } from '../../../services/api/designApi';
import { uploadBookmark } from '../../../services/api/bookmarkApi';
import { useSharedDesignState } from '../../../hooks/useSharedDesignState';
import { getClosestColor } from '../../../utils/colorUtils';
import creditEventEmitter from '../../../components/credits/creditEventEmitter';

const Proposals = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // Use shared state
  const {
    selections,
    subSelections,
    previewItems,
    setSelections,
    setSubSelections,
    setPreviewItems,
  } = useSharedDesignState('Proposal');

  // Local state
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [credits, setCredits] = useState(null);
  const [showCreditsModal, setShowCreditsModal] = useState(false);

  // Define steps for the design process
  const steps = [
    {
      id: 'location',
      title: 'Choose Your Location',
      type: 'grid',
      options: [
        { name: 'Beach', image: '/proposalPics/location/beach.jpg' },
        { name: 'Restaurant', image: '/proposalPics/location/restaurant.jpg' },
        { name: 'Garden', image: '/proposalPics/location/garden.jpg' },
        { name: 'Rooftop', image: '/proposalPics/location/rooftop.jpg' },
        { name: 'Mountain', image: '/proposalPics/location/mountain.jpg' },
        { name: 'Lake', image: '/proposalPics/location/lake.jpg' },
      ],
      allowCustom: true
    },
    {
      id: 'time',
      title: 'Select Time',
      type: 'grid',
      options: [
        { name: 'Sunset', image: '/proposalPics/time/sunset.jpg' },
        { name: 'Sunrise', image: '/proposalPics/time/sunrise.jpg' },
        { name: 'Daytime', image: '/proposalPics/time/dayTime.jpg' },
        { name: 'Night', image: '/proposalPics/time/nightTime.jpg' },
        { name: 'Golden Hour', image: '/proposalPics/time/goldenHour.jpg' },
      ],
      allowCustom: true
    },
    {
      id: 'scene',
      title: 'Choose Your Scene',
      type: 'grid',
      options: [
        {
          name: 'Picnic',
          image: '/proposalPics/scene/picnic.jpg',
          subOptions: ['Blanket Setup', 'Low Table'],
          dependencies: { location: ['Beach', 'Garden', 'Lake'] }
        },
        {
          name: 'Dinner Table',
          image: '/proposalPics/scene/dinnerTable.jpg',
          subOptions: ['Formal Table', 'Rustic Table', 'Candlelit Table'],
          dependencies: { location: ['Restaurant', 'Rooftop'] }
        },
        {
          name: 'Garden Path',
          image: '/proposalPics/scene/gardenPath.jpg',
          subOptions: ['Flower Arch', 'Lanterns', 'String Lights'],
          dependencies: { location: ['Garden'] }
        },
        {
          name: 'Gazebo',
          image: '/proposalPics/scene/gazebo.jpg',
          subOptions: ['Floral Gazebo', 'Lighted Gazebo', 'Minimalist Gazebo'],
          dependencies: { location: ['Garden', 'Lake'] }
        },
        {
          name: 'Bonfire Setup',
          image: '/proposalPics/scene/bonfire.jpg',
          dependencies: { location: ['Beach', 'Mountain', 'Lake'] }
        },
        {
          name: 'Floral Arch',
          image: '/proposalPics/scene/floralArch.jpg',
          subOptions: ['Rose Arch', 'Wildflower Arch', 'Greenery Arch'],
          dependencies: { location: ['Garden', 'Lake'] }
        },
        {
          name: 'Boat Setup',
          image: '/proposalPics/scene/boat.jpg',
          dependencies: { location: ['Lake'] }
        },
        {
          name: 'Scenic Overlook',
          image: '/proposalPics/scene/sceneOverlook.jpg',
          subOptions: ['Mountain View', 'Valley View', 'Sunset Spot'],
          dependencies: { location: ['Mountain', 'Rooftop'] }
        },
       
      ],
      allowCustom: true
    },
    {
      id: 'decor',
      title: 'Select Decor',
      type: 'grid',
      options: [
        {
          name: 'Rose Petals',
          image: '/proposalPics/decor/petals.jpg',
          subOptions: ['Red Petals', 'White Petals', 'Mixed Petals'],
        },
        {
          name: 'Candles',
          image: '/proposalPics/decor/candles.jpg',
          subOptions: ['Pillar Candles', 'Tea Lights', 'Floating Candles'],
        },
        {
          name: 'String Lights',
          image: '/proposalPics/decor/stringLights.jpg',
          subOptions: ['Warm White', 'Cool White', 'Colored Lights'],
        },
        {
          name: 'Flowers',
          image: '/proposalPics/decor/flowers.jpg',
          subOptions: ['Roses', 'Peonies', 'Wildflowers'],
        },
        {
          name: 'Lanterns',
          image: '/proposalPics/decor/lanterns.jpg',
          subOptions: ['Paper Lanterns', 'Metal Lanterns', 'Hanging Lanterns'],
        },
        {
          name: 'Balloons',
          image: '/proposalPics/decor/baloons.jpg',
        },
     
      ],
      allowCustom: true
    },
    {
      id: 'style',
      title: 'Choose Your Style',
      type: 'grid',
      options: [
        { name: 'Romantic', image: '/proposalPics/style/romantic.jpg' },
        { name: 'Cinematic', image: '/proposalPics/style/cinematic.jpg' },
        { name: 'Dreamy', image: '/proposalPics/style/dreamy.jpg' },
        { name: 'Classic', image: '/proposalPics/style/classic.jpg' },
      ],
      allowCustom: true
    },
    {
      id: 'color',
      title: 'Choose Your Color',
      type: 'color-picker',
      options: [
        { name: 'Rose Gold', hex: '#B76E79' },
        { name: 'Blush Pink', hex: '#FFB6C1' },
        { name: 'Burgundy', hex: '#800020' },
        { name: 'Deep Red', hex: '#DC143C' },
        { name: 'Navy Blue', hex: '#000080' },
        { name: 'Royal Purple', hex: '#7851A9' },
        { name: 'Deep Purple', hex: '#301934' },
        { name: 'Midnight Blue', hex: '#191970' },
        { name: 'Forest Green', hex: '#228B22' },
        { name: 'Sage', hex: '#9DC183' },
        { name: 'Ocean Blue', hex: '#4F97A3' },
        { name: 'Sunset Orange', hex: '#FD5E53' },
        { name: 'Gold', hex: '#dcc282' },
        { name: 'Silver', hex: '#C0C0C0' },
        { name: 'Champagne', hex: '#F7E7CE' },
        { name: 'Warm White', hex: '#FAF9F6' },
      ],
      allowMultiple: true,
      maxSelections: 3
    }
  ];

  const handleSelectionChange = (stepId, value) => {
    if (stepId === 'color') {
      // Handle multiple selections for color
      setSelections((prev) => {
        const currentSelections = prev[stepId] || [];
        const updatedSelections = currentSelections.includes(value)
          ? currentSelections.filter((item) => item !== value) // Deselect if already selected
          : [...currentSelections, value]; // Add to selection

        // Ensure maxSelections is respected
        const maxSelections = steps.find((step) => step.id === stepId)?.maxSelections || Infinity;
        if (updatedSelections.length > maxSelections) {
          setError(`You can only select up to ${maxSelections} colors.`);
          return prev;
        }

        return { ...prev, [stepId]: updatedSelections };
      });
    } else {
      // Handle single selections for other steps
      setSelections((prev) => ({ ...prev, [stepId]: value }));
    }

    // Reset subSelections if the location changes
    if (stepId === 'location') {
      setSubSelections((prev) => ({ ...prev, scene: null }));
    }
  };

  const handleSubSelectionChange = (stepId, value) => {
    setSubSelections((prev) => ({ ...prev, [stepId]: value }));
  };

  const getFilteredOptions = (stepId) => {
    const step = steps.find((s) => s.id === stepId);
    if (!step) return [];

    if (stepId === 'scene') {
      const location = selections.location;
      return step.options.filter((option) => {
        if (!option.dependencies) return true;
        return option.dependencies.location.includes(location);
      });
    }

    return step.options;
  };

  const generateVisualizations = async () => {
    const isAllSelectionsComplete = steps.every(step => selections[step.id]);

    if (!isAllSelectionsComplete) {
      setError('Please complete all design options');
      return;
    }

    const auth = getAuth();
    const currentUser = auth.currentUser;
    
    if (!currentUser) {
      setError('Please log in to generate designs');
      return;
    }

    const userCredits = await fetchUserCredits(currentUser.uid);
    if (userCredits <= 0) {
      setShowCreditsModal(true);
      return;
    }

    setLoading(true);
    setProgress(0);
    setError('');

    try {
      const colorTheme = selections.color ? `with ${selections.color.join(' and ')} color theme` : '';
      const sceneSubOptions = subSelections.scene ? `with ${subSelections.scene}` : '';
      const decorSubOptions = subSelections.decor ? `with ${subSelections.decor}` : '';
      const stylePhrase = selections.style ? `in a ${selections.style} style` : '';

      const proposalPrompt = `A beautifully arranged ${selections.location} proposal setup during ${selections.time}, featuring a stunning ${selections.scene} ${sceneSubOptions} ${decorSubOptions}, ${stylePhrase} ${colorTheme} capturing the ambiance with professional photography.`;

      setProgress(10);
      const token = await generateToken();

      const imageUrl = await generateImage(proposalPrompt, token, currentUser.uid, ModelOptions.PHOENIX);

      setPreviewItems([{ url: imageUrl, label: 'Proposal Setup', prompt: proposalPrompt }]);

      setProgress(100);

      const updatedCredits = await updateCreditsAfterGeneration(currentUser.uid);
      creditEventEmitter.emit(updatedCredits);
      setCredits(updatedCredits);
    } catch (error) {
      console.error('Error generating visualizations:', error);
      setError('Failed to generate visualizations');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (item) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        setError('Please log in to save designs');
        return;
      }

      await uploadBookmark(item, 'Proposal', selections, currentUser);
    } catch (error) {
      console.error('Error saving design:', error);
      setError('Failed to save design');
    }
  };

  return (
    <>
      {isMobile ? (
        <MobileDesign
          steps={steps}
          title="Design Your Wedding Proposal"
          selections={selections}
          subSelections={subSelections}
          previewItems={previewItems}
          loading={loading}
          progress={progress}
          error={error}
          credits={credits}
          onSelectionChange={handleSelectionChange}
          onSubSelectionChange={handleSubSelectionChange}
          onGenerate={generateVisualizations}
          onSave={handleSave}
          getFilteredOptions={getFilteredOptions}
        />
      ) : (
        <DesktopDesign
          steps={steps}
          title="Design Your Wedding Proposal"
          selections={selections}
          subSelections={subSelections}
          previewItems={previewItems}
          loading={loading}
          progress={progress}
          error={error}
          credits={credits}
          onSelectionChange={handleSelectionChange}
          onSubSelectionChange={handleSubSelectionChange}
          onGenerate={generateVisualizations}
          onSave={handleSave}
          getFilteredOptions={getFilteredOptions}
        />
      )}
    </>
  );
};

export default Proposals;