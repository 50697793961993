import React from 'react';
import { 
  Heart, 
  Camera, 
  Clock, 
  Palette, 
  Tag,
  MapPin,
  Sparkles,
  Flower2 as Flower,
  Armchair as Chair,
  Square,
  Home,
  User,
  Cake,
  Maximize2
} from 'lucide-react';

function ImageCard({ src, alt, tags, isLiked, onLike, onExpand, metadata }) {
  // Group keys by category for better organization
  const categoryGroups = {
    decor: ['theme', 'color', 'flowers', 'seating', 'venue', 'greenery', 'decor'],
    cake: ['size', 'shape', 'flavor', 'decoration'],
    suit: ['fit', 'lapel', 'material', 'pattern', 'jacket'],
    dress: ['style', 'neckline', 'fabric', 'length', 'details'],
    proposal: ['location', 'scene', 'style']
  };

  // Get metadata values if they exist
  const getMetadataValues = () => {
    let metadataValuesList;
    const selectedOptions = metadata?.selectedOptions;

    if (typeof selectedOptions === 'string') {
      try {
        const parsedOptions = JSON.parse(selectedOptions);
        metadataValuesList = parsedOptions.metadataValuesList;
      } catch (error) {
        console.error('Error parsing selectedOptions:', error);
        return [];
      }
    } else if (selectedOptions?.metadataValuesList) {
      metadataValuesList = selectedOptions.metadataValuesList;
    }

    if (!Array.isArray(metadataValuesList)) {
      console.warn('metadataValuesList is not an array:', metadataValuesList);
      return [];
    }

    // Flatten all valid keys
    const relevantKeys = Object.values(categoryGroups).flat();

    return metadataValuesList.filter(option => 
      relevantKeys.includes(option.key.toLowerCase())
    );
  };

  // Get appropriate icon for a metadata key
  const getIconForKey = (key) => {
    const lowerKey = key.toLowerCase();
    
    switch (lowerKey) {
      case 'theme':
        return <Sparkles className="explore-icon" />;
      case 'color':
        return <Palette className="explore-icon" />;
      case 'flowers':
        return <Flower className="explore-icon" />;
      case 'seating':
        return <Chair className="explore-icon" />;
      case 'venue':
        return <Home className="explore-icon" />;
      case 'greenery':
        return <Flower className="explore-icon" />;
      case 'decor':
        return <Home className="explore-icon" />;
      case 'size':
      case 'shape':
        return <Square className="explore-icon" />;
      case 'flavor':
        return <Cake className="explore-icon" />;
      case 'decoration':
        return <Sparkles className="explore-icon" />;
      case 'fit':
      case 'lapel':
      case 'material':
      case 'pattern':
      case 'jacket':
        return <User className="explore-icon" />;
      case 'style':
      case 'neckline':
      case 'fabric':
      case 'length':
      case 'details':
        return <User className="explore-icon" />;
      case 'location':
      case 'scene':
        return <MapPin className="explore-icon" />;
      default:
        return <Tag className="explore-icon" />;
    }
  };

  const metadataValues = getMetadataValues();

  return (
    <div className={`break-inside-avoid ${
      tags.category.toLowerCase().includes('decor') 
        ? 'col-span-3 mb-2 sm:mb-8'
        : 'mb-1.5 sm:mb-6'
    }`}>
      <div className="relative group rounded-lg sm:rounded-xl overflow-hidden bg-gray-50">
        <img
          src={src}
          alt={alt}
          className={`w-full ${
            tags.category.toLowerCase().includes('cake') || 
            tags.category.toLowerCase().includes('dress') || 
            tags.category.toLowerCase().includes('suit')
              ? 'aspect-[3/4]' 
              : tags.category.toLowerCase().includes('decor')
                ? 'aspect-auto max-h-[800px]'
                : 'aspect-square'
          } object-cover transition-transform duration-200 group-hover:scale-105`}
          onClick={onExpand}
        />

        {/* Like (heart) button */}
        <button
          className="absolute top-2 right-2 p-2 bg-white/90 backdrop-blur-sm rounded-full shadow-lg hover:bg-white z-10"
          onClick={(e) => {
            e.stopPropagation();
            onLike();
          }}
        >
          <Heart
            className={`w-5 h-5 ${
              isLiked ? 'fill-rose-500 text-rose-500' : 'text-gray-600'
            }`}
          />
        </button>

        {/* Updated Tags overlay with additional metadata */}
        <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-all duration-200">
          <div className="absolute bottom-0 left-0 right-0 p-3 space-y-2 bg-gradient-to-t from-black/80 to-transparent translate-y-full group-hover:translate-y-0 transition-transform duration-200">
            {/* Show prompt if available */}
            {metadata?.prompt && (
              <div className="flex items-center gap-2 text-sm">
                <MapPin className="w-4 h-4 text-white/70" />
                <span className="font-medium text-white line-clamp-2">{metadata.prompt}</span>
              </div>
            )}
            
            <div className="grid grid-cols-2 gap-2 text-xs">
              {metadataValues.length > 0 ? (
                metadataValues.slice(0, 4).map((item) => (
                  <div key={`${item.key}-${item.value}`} className="flex items-center gap-1.5">
                    {getIconForKey(item.key)}
                    <span className="text-white/90">
                      {item.value.split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(' ')}
                    </span>
                  </div>
                ))
              ) : (
                // Fallback to original tags with icons
                <>
                  <div className="flex items-center gap-1.5">
                    <Camera className="w-3.5 h-3.5 text-white/70" />
                    <span className="text-white/90">{tags.subject}</span>
                  </div>
                  <div className="flex items-center gap-1.5">
                    <Palette className="w-3.5 h-3.5 text-white/70" />
                    <span className="text-white/90">{tags.style}</span>
                  </div>
                  <div className="flex items-center gap-1.5">
                    <Clock className="w-3.5 h-3.5 text-white/70" />
                    <span className="text-white/90">{tags.time}</span>
                  </div>
                </>
              )}
              {metadata?.createdAt && (
                <div className="flex items-center gap-1.5">
                  <Clock className="w-3.5 h-3.5 text-white/70" />
                  <span className="text-white/90">
                    {new Date(metadata.createdAt).toLocaleDateString()}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Expand button overlay */}
        <div
          className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200"
          onClick={onExpand}
        >
          <button className="bg-white/90 backdrop-blur-sm text-gray-800 px-3 sm:px-4 py-2 rounded-full font-medium transform -translate-y-2 group-hover:translate-y-0 transition-transform duration-200 flex items-center gap-1.5">
            <Maximize2 className="w-4 h-4 sm:hidden" />
            <span className="hidden sm:inline">Expand</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImageCard;