import React, { useState, useEffect } from 'react';
import { Plus, Minus } from 'lucide-react';
import { BudgetBreakdown, BudgetCategory } from './types';
import { updateTotalBudget } from '../../../utils/budget.ts';

interface BudgetDonutProps {
  budget: number;
  categories: BudgetCategory[];
  selectedCategory: BudgetCategory | null;
  onSelectCategory: (category: BudgetCategory | null) => void;
  onUpdateBudget: (newTotal: number) => void;
  onTotalBudgetChange: (newTotal: number) => void;
}

export const BudgetDonut: React.FC<BudgetDonutProps> = ({
  budget,
  categories,
  selectedCategory,
  onSelectCategory,
  onUpdateBudget,
  onTotalBudgetChange,
}) => {
  const radius = 180;
  const strokeWidth = 60;
  const innerRadius = radius - strokeWidth;
  const [hoveredCategory, setHoveredCategory] = useState<string | null>(null);
  const [isEditingBudget, setIsEditingBudget] = useState(false);
  const [totalBudget, setTotalBudget] = useState<number>(budget);

  useEffect(() => {
    setTotalBudget(budget);
  }, [budget]);

  const calculateArcPath = (percentage: number, startPercentage: number) => {
    const angleOffset = -90;
    const startAngle = (startPercentage * 3.6 + angleOffset) * (Math.PI / 180);
    const endAngle = ((startPercentage + percentage) * 3.6 + angleOffset) * (Math.PI / 180);

    const startOuterX = radius + Math.cos(startAngle) * radius;
    const startOuterY = radius + Math.sin(startAngle) * radius;
    const endOuterX = radius + Math.cos(endAngle) * radius;
    const endOuterY = radius + Math.sin(endAngle) * radius;
    const startInnerX = radius + Math.cos(startAngle) * innerRadius;
    const startInnerY = radius + Math.sin(startAngle) * innerRadius;
    const endInnerX = radius + Math.cos(endAngle) * innerRadius;
    const endInnerY = radius + Math.sin(endAngle) * innerRadius;

    const largeArcFlag = percentage > 50 ? 1 : 0;

    return `
      M ${startOuterX},${startOuterY}
      A ${radius},${radius} 0 ${largeArcFlag} 1 ${endOuterX},${endOuterY}
      L ${endInnerX},${endInnerY}
      A ${innerRadius},${innerRadius} 0 ${largeArcFlag} 0 ${startInnerX},${startInnerY}
      Z
    `.trim();
  };

  const handleBudgetChange = async (amount: number) => {
    const newBudget = Math.max(0, totalBudget + amount);
    onTotalBudgetChange(newBudget);
    setTotalBudget(newBudget);
    onUpdateBudget(newBudget);
    await updateTotalBudget(newBudget);
  };

  const handleBudgetSubmit = async () => {
    const newBudget = Math.max(0, totalBudget || 0);
    onTotalBudgetChange(newBudget);
    setTotalBudget(newBudget);
    onUpdateBudget(newBudget);
    await updateTotalBudget(newBudget);
    setIsEditingBudget(false);
  };

  const handleCategoryClick = (category: BudgetCategory) => {
    onSelectCategory(selectedCategory?.id === category.id ? null : category);
  };

  let currentPercentage = 0;

  return (
    <div className="relative flex justify-center items-center min-h-[420px] overflow-visible">
      <div className="relative p-6 mt-12">
        <svg 
          width={radius * 2} 
          height={radius * 2} 
          viewBox={`0 0 ${radius * 2} ${radius * 2}`}
          className="transform -rotate-90 overflow-visible"
        >
          <g>
            {categories.map((category) => {
              const path = calculateArcPath(category.percentage, currentPercentage);
              const isSelected = selectedCategory?.id === category.id;
              const isHovered = hoveredCategory === category.id;
              
              const element = (
                <path
                  key={category.id}
                  d={path}
                  fill={category.color}
                  className={`transition-all duration-200 cursor-pointer ${
                    isSelected || isHovered ? 'opacity-100 scale-[1.02] origin-center' : 'opacity-80 hover:opacity-100'
                  }`}
                  onClick={() => handleCategoryClick(category)}
                  onMouseEnter={() => setHoveredCategory(category.id)}
                  onMouseLeave={() => setHoveredCategory(null)}
                />
              );
              
              currentPercentage += category.percentage;
              return element;
            })}
          </g>
          <circle
            cx={radius}
            cy={radius}
            r={innerRadius}
            fill="white"
            className="cursor-pointer"
            onClick={() => onSelectCategory(null)}
          />
        </svg>

        <div className="absolute inset-0 flex flex-col items-center justify-center text-center pointer-events-none">
          <div className="flex items-center space-x-3">
            <button
              onClick={async () => await handleBudgetChange(-1000)}
              className="p-1 text-pink-600 hover:bg-pink-50 rounded-full transition-colors pointer-events-auto"
            >
              <Minus className="w-4 h-4" />
            </button>
            
            {isEditingBudget ? (
              <input
                type="number"
                value={totalBudget === 0 ? '' : totalBudget}
                onChange={(e) => {
                  const cleanedValue = e.target.value.replace(/,/g, '');
                  setTotalBudget(parseFloat(cleanedValue) || 0);
                }}
                onBlur={handleBudgetSubmit}
                onKeyDown={async (e) => e.key === 'Enter' && await handleBudgetSubmit()}
                className="w-32 text-center text-2xl font-bold text-gray-800 bg-transparent border-b-2 border-pink-200 focus:border-pink-500 focus:outline-none pointer-events-auto"
                autoFocus
              />
            ) : (
              <span
                onClick={() => {
                  setTotalBudget(budget);
                  setIsEditingBudget(true);
                }}
                className="text-3xl font-bold text-gray-800 cursor-pointer hover:text-pink-600 transition-colors pointer-events-auto"
              >
                ${totalBudget.toLocaleString()}
              </span>
            )}

            <button
              onClick={async () => await handleBudgetChange(1000)}
              className="p-1 text-pink-600 hover:bg-pink-50 rounded-full transition-colors pointer-events-auto"
            >
              <Plus className="w-4 h-4" />
            </button>
          </div>
          <span className="text-sm text-gray-500">Total Budget</span>
        </div>

        {hoveredCategory && (
          <div 
            className="absolute left-1/2 transform -translate-x-1/2 bg-white px-3 py-1 rounded-full shadow-sm text-sm pointer-events-none"
            style={{ top: '-2rem' }}
          >
            {categories.find(c => c.id === hoveredCategory)?.name}
          </div>
        )}
      </div>
    </div>
  );
};