import React from 'react';
import { CreditCard, Coins } from 'lucide-react';

interface CreditsSectionProps {
  localCredits: number | null;
  setShowPaymentModal: (show: boolean) => void;
  handleFetchCredits: () => void;
}

export const CreditsSection: React.FC<CreditsSectionProps> = ({
  localCredits,
  setShowPaymentModal,
  handleFetchCredits,
}) => (
  <div className="flex items-center gap-4">
    <button 
      className="flex items-center gap-1.5 px-3 py-1.5 bg-[#FF7F50] text-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 hover:scale-105 group text-sm" 
      onClick={() => setShowPaymentModal(true)}
    >
      <CreditCard className="w-3.5 h-3.5 group-hover:rotate-6 transition-transform duration-300" />
      <span className="font-medium">Get Credits</span>
    </button>
    <div className="flex items-center gap-1.5 px-3 py-1.5 bg-gradient-to-r from-[#FF69B4]/10 to-[#FF7F50]/10 rounded-lg border border-[#FF69B4]/20">
      <Coins className="w-3.5 h-3.5 text-[#FF69B4]" />
      <span className="text-sm font-medium bg-gradient-to-r from-[#FF69B4] to-[#FF7F50] bg-clip-text text-transparent">
        {localCredits !== null ? `${localCredits} credits` : 'Loading credits...'}
      </span>
    </div>
  </div>
); 