import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, useScroll, useTransform } from 'framer-motion';
import { ArrowRight, Globe, Paintbrush, Search, Sparkles, ChevronLeft, ChevronRight } from 'lucide-react';
import Footer from './Footer';
import CRMFeatures from '../../components/features/CRMFeatures';

const categories = [
  {
    id: 'plan',
    name: 'Plan',
    images: [
      {
        id: 1,
        url: '/heroVideos/Planning.mov',
        alt: 'Wedding planning tools',
        duration: 6000 // Duration in milliseconds
      }
    ]
  },
  {
    id: 'design',
    name: 'Design',
    images: [
      {
        id: 2,
        url: '/heroVideos/Design.mov',
        alt: 'Wedding design interface',
        duration: 6000
      }
    ]
  },
  {
    id: 'explore',
    name: 'Explore',
    images: [
      {
        id: 3,
        url: '/heroVideos/Explore.mov',
        alt: 'Wedding inspiration gallery',
        duration: 6000
      }
    ]
  }
];

// Add gradient colors constant
const GRADIENT_COLORS = {
  pink: '#FF69B4',
  orange: '#FF7F50',
};

const tabs = [
  {
    id: 'imageGeneration',
    name: 'Image Generation',
    title: 'Envision The Extraordinary',
    description: `Take creativity a step further with the transformative power of our Image Generation tool. It's not just about bringing your concepts to life — redefine the impossible. From beginners to professionals, we offer a spectrum of categories that can be intuitively tailored to your needs.`,
    subDescription: 'Discover an unprecedented fusion of simplicity and power, designed to cater to creative minds at all levels.',
    images: [
      '/heroVideos/genOne.png',
      '/heroVideos/genTwo.png',
      '/heroVideos/genThree.png',
      '/heroVideos/genFour.png'
    ]
  },
  {
    id: 'aiCanvas',
    name: 'Planning',
    title: 'Planning Made Easy',
    description: 'Our planning tool is designed to help you create a seamless and stress-free wedding planning experience. From To Do Lists to Budget Management, we have you covered.',
    subDescription: 'Your all-in-one digital wedding planner, powered by artificial intelligence.',
    images: [
      '/heroVideos/planOne.png',
      '/heroVideos/planTwo.png',
      '/heroVideos/planThree.png',
      '/heroVideos/planFour.png'
    ]
  },
  {
    id: 'vendorConnect',
    name: 'Vendor Network',
    comingSoon: true,
    title: 'Connect With Top Wedding Vendors',
    description: 'Transform your AI-generated designs into reality through our exclusive network of premium wedding vendors. Our intelligent matching system connects your unique vision with vetted professionals who can bring it to life. Coming Soon: A revolutionary way to bridge the gap between digital inspiration and real-world execution.',
    subDescription: 'Coming Soon: The future of vendor collaboration, powered by AI matching.',
    video: '/heroVideos/Connect.mov'
  }
];

const Home = ({ containerRef }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [images, setImages] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState(0);
  const [activeImage, setActiveImage] = useState(0);
  const [videoRef, setVideoRef] = useState(null);
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [currentSlide, setCurrentSlide] = useState(0);

  const navigate = useNavigate();
  const { scrollYProgress } = useScroll({ container: containerRef });
  const opacity = useTransform(scrollYProgress, [0.15, 0.25, 0.75, 0.85], [0, 1, 1, 0]);
  const scale = useTransform(scrollYProgress, [0.1, 0.4], [1.2, 1]);
  const y = useTransform(scrollYProgress, [0.1, 0.4], [100, 0]);

  const sections = [
    {
      icon: <Globe className="w-6 h-6 text-white" />,
      title: 'Plan',
      description: 'Plan your perfect day with our comprehensive tools and expert guidance',
    },
    {
      icon: <Paintbrush className="w-6 h-6 text-white" />,
      title: 'Design',
      description: 'Create stunning, personalized designs for every aspect of your wedding',
    },
    {
      icon: <Search className="w-6 h-6 text-white" />,
      title: 'Explore',
      description: 'Discover inspiration and trends from realistic AI-Generated wedding themes',
    },
  ];

  const desktopVideos = [
   
    '/heroVideos/hero2.mp4',
    '/heroVideos/hero3.mp4',
    '/heroVideos/hero5.mp4',
 
 
  ];

  const mobileVideos = [
    '/heroVideos/MobileHero1.mp4',
    '/heroVideos/MobileHero2.mp4',
    '/heroVideos/MobileHero3.mp4',
  ];

  useEffect(() => {
    const mediaFiles = isMobile ? mobileVideos : desktopVideos;
    setImages(mediaFiles);
    setIsLoading(true);
    setCurrentImage(0);

    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % mediaFiles.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [isMobile]);

  useEffect(() => {
    const currentCategory = categories[activeCategory];
    const currentVideo = currentCategory.images[activeImage];
    
    const timeout = setTimeout(() => {
      setActiveCategory((prev) => (prev + 1) % categories.length);
    }, currentVideo.duration);

    return () => clearTimeout(timeout);
  }, [activeCategory, activeImage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextImage = () => {
    setActiveImage((prev) => 
      (prev + 1) % categories[activeCategory].images.length
    );
  };

  const prevImage = () => {
    setActiveImage((prev) => 
      prev === 0 ? categories[activeCategory].images.length - 1 : prev - 1
    );
  };

  const handleCategoryClick = (index) => {
    setActiveCategory(index);
    setActiveImage(0);
    setTimeout(() => {
      if (videoRef) {
        videoRef.currentTime = 0;
        videoRef.playbackRate = 2.0; // Speed up video (2x speed)
        videoRef.play().catch(err => console.log('Video play error:', err));
      }
    }, 50);
  };

  // Add useEffect to set initial playback rate when video loads
  useEffect(() => {
    if (videoRef) {
      videoRef.playbackRate = 2.0; // Speed up video (2x speed)
    }
  }, [activeCategory, videoRef]);

  // Update the auto-advance effect to check for images
  useEffect(() => {
    const interval = setInterval(() => {
      const activeTabContent = tabs.find(tab => tab.id === activeTab);
      if (activeTabContent?.images?.length > 0) {  // Only run for tabs with images
        setCurrentSlide(current => 
          current === activeTabContent.images.length - 1 ? 0 : current + 1
        );
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [activeTab, tabs]);

  // Reset slide index when changing tabs
  useEffect(() => {
    setCurrentSlide(0);
  }, [activeTab]);

  // Also update the navigation handlers
  const handleNextSlide = () => {
    const activeTabContent = tabs.find(tab => tab.id === activeTab);
    if (activeTabContent?.images?.length > 0) {  // Only run for tabs with images
      setCurrentSlide(current => 
        current === activeTabContent.images.length - 1 ? 0 : current + 1
      );
    }
  };

  const handlePrevSlide = () => {
    const activeTabContent = tabs.find(tab => tab.id === activeTab);
    if (activeTabContent?.images?.length > 0) {  // Only run for tabs with images
      setCurrentSlide(current => 
        current === 0 ? activeTabContent.images.length - 1 : current - 1
      );
    }
  };

  if (isLoading && images.length === 0) {
    return <div>Loading hero content...</div>;
  }

  return (
    <div className="relative">
      {/* Hero Section */}
      <section className="background-image-container relative h-[100vh] w-full">
        <div className="absolute inset-0 z-0 overflow-hidden">
          {/* Add tint/gradient overlay */}
          <div 
            className="absolute inset-0 z-10"
            style={{
              background: 'linear-gradient(to bottom, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.5) 10%, rgba(0,0,0,0.2) 20%, rgba(0,0,0,0.1) 30%, rgba(0,0,0,0.05) 50%)'
            }}
          />
          
          {images.map((videoPath, index) => (
            <div
              key={videoPath}
              className={`absolute inset-0 transition-opacity duration-1000 ${
                index === currentImage ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <video
                data-index={index}
                autoPlay
                muted
                loop
                playsInline
                preload="auto"
                className="w-full h-full object-cover"
              >
                <source src={videoPath} type="video/mp4" />
              </video>
            </div>
          ))}
        </div>

        <div className="relative z-10 flex flex-col justify-center items-center h-full text-center pt-16 md:pt-24">
          <h1 className="font-['Playfair_Display'] text-4xl md:text-5xl lg:text-6xl font-medium tracking-wide text-white px-4">
            Your Dream Wedding Starts Here
            <span className="block font-['Montserrat'] text-lg md:text-xl lg:text-2xl text-white mt-2 md:mt-4 font-light tracking-wider">
              From AI-powered designs to unique inspirations
            </span>
          </h1>

          <div className="mt-6 md:mt-8 flex flex-wrap gap-3 md:gap-4 justify-center px-4">
            <button
              className="px-4 md:px-6 py-2 md:py-3 rounded-full bg-gradient-to-r hover:opacity-90 text-white font-medium flex items-center text-sm md:text-base"
              style={{
                background: `linear-gradient(to right, ${GRADIENT_COLORS.pink}, ${GRADIENT_COLORS.orange})`
              }}
              onClick={() => navigate('/design')}
            >
              Start Designing
              <ArrowRight className="ml-2 w-3 h-3 md:w-4 md:h-4" />
            </button>
            <button
              className="px-4 md:px-6 py-2 md:py-3 rounded-full text-white border border-black hover:bg-black 0 font-medium text-sm md:text-base"
              onClick={() => {
                const categorySection = document.querySelector('#category-section');
                categorySection?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              How It Works
            </button>
          </div>
        </div>
      </section>

      {/* Parallax Section with Features Overlay */}
      <section className="relative hidden md:flex h-screen items-center justify-center overflow-hidden">
        <motion.div
          className="w-[60%] max-w-4xl aspect-[4/3] rounded-3xl overflow-hidden shadow-2xl"
          style={{ scale, y }}
        >
          <div className="relative h-full w-full">
            <img
              src="/Leonardo_Phoenix_10_A_modern_elegant_digital_wedding_planning_0.jpg"
              alt="Wedding couple"
              className="w-full h-full object-cover md:object-center object-top"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/60 md:from-black/30 md:to-black/50" />
          </div>
        </motion.div>

        <motion.div
          style={{ opacity }}
          className="absolute inset-0 flex items-center justify-center pointer-events-none"
        >
          <div className="max-w-7xl w-full mx-auto space-y-16 pointer-events-auto">
            <div className="text-center space-y-4">
             
              <p className="font-['Montserrat'] text-xl text-white max-w-2xl mx-auto font-semibold">
                Comprehensive tools and features to make your wedding planning journey smooth and enjoyable
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {sections.map((section) => (
                <div
                  key={section.title}
                  className="group p-6 space-y-4 hover:shadow-lg transition-all duration-300 cursor-pointer border-0 shadow-md hover:-translate-y-1 bg-white backdrop-blur-sm hover:bg-gradient-to-br hover:from-rose-100 hover:to-amber-100 rounded-xl"
                >
                  <div className="p-3 rounded-2xl w-fit"
                       style={{
                         background: `linear-gradient(to bottom right, ${GRADIENT_COLORS.pink}, ${GRADIENT_COLORS.orange})`
                       }}>
                    {section.icon}
                  </div>
                  <h3 className="text-xl font-semibold text-rose-500">
                    {section.title}
                  </h3>
                  <p className="text-rose-900/80">{section.description}</p>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      </section>

      {/* Categories Section */}
      <div id="category-section" className="bg-black text-white">
        <div className="max-w-7xl mx-auto px-4 py-12 md:py-20">
          <h1 className="text-4xl md:text-6xl font-bold text-center mb-6 md:mb-8">
            Use our tools today for
          </h1>
          
          {/* Categories */}
          <div className="flex md:flex-row justify-center md:gap-8 mb-8 md:mb-12">
            {categories.map((category, index) => (
              <button
                key={category.id}
                onClick={() => handleCategoryClick(index)}
                className={`text-2xl md:text-4xl font-bold transition-all duration-300 py-2 md:py-0 ${
                  index === activeCategory
                    ? 'bg-clip-text text-transparent scale-105 md:scale-110'
                    : 'text-gray-500 hover:text-gray-300'
                }`}
                style={{
                  backgroundImage: index === activeCategory 
                    ? `linear-gradient(to right, ${GRADIENT_COLORS.pink}, ${GRADIENT_COLORS.orange})`
                    : 'none',
                  WebkitBackgroundClip: index === activeCategory ? 'text' : 'none',
                  WebkitTextFillColor: index === activeCategory ? 'transparent' : 'inherit'
                }}
              >
                {category.name}
              </button>
            ))}
          </div>

          {/* Image Display */}
          <div className="relative aspect-[16/9] max-w-5xl mx-auto rounded-xl md:rounded-2xl overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-900/20 to-blue-900/20" />
            
            {/* Images */}
            <div className="relative w-full h-full">
              <video
                ref={(el) => setVideoRef(el)}
                key={categories[activeCategory].images[0].url}
                src={categories[activeCategory].images[0].url}
                autoPlay
                muted
                loop
                playsInline
                preload="auto"
                className="w-full h-full bg-black"
                style={{ 
               
                  imageRendering: 'crisp-edges',
                  WebkitImageRendering: 'crisp-edges'
                }}
                onLoadedMetadata={(e) => {
                  const video = e.target;
                  video.playbackRate = 2.0; // Speed up video (2x speed)
                  categories[activeCategory].images[0].duration = (video.duration * 1000) / 2; // Adjust duration for speed
                }}
              >
                <source src={categories[activeCategory].images[0].url} type="video/quicktime" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>

      {/* New Tabbed Section */}
      <section className="bg-white text-black py-8 md:py-16 lg:py-20">
        <div className="max-w-7xl mx-auto px-4">
          {/* Tabs */}
          <div className="flex border-b border-gray-200 mb-12 overflow-x-auto pb-4 scrollbar-hide">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`whitespace-nowrap pb-4 relative flex items-center gap-2 px-3 text-xs md:text-base ${
                  activeTab === tab.id 
                    ? 'text-black font-medium' 
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                {tab.name}
                {tab.comingSoon && (
                  <span className="px-1.5 py-0.5 text-[10px] md:text-xs font-medium bg-gradient-to-r from-[#FF4D8D] to-[#FF7F50] text-white rounded-full animate-pulse">
                    Coming Soon
                  </span>
                )}
                {activeTab === tab.id && (
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-[#FF4D8D] to-[#FF7F50]" />
                )}
              </button>
            ))}
          </div>

          {/* Content */}
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`grid md:grid-cols-2 gap-12 items-center ${
                activeTab === tab.id ? 'block' : 'hidden'
              }`}
            >
              <div>
                <h3 className="text-sm text-[#FF4D8D] mb-4">{tab.name}</h3>
                <h2 className="text-4xl font-bold text-black mb-6">{tab.title}</h2>
                <p className="text-gray-600 mb-4">{tab.description}</p>
                <p className="text-gray-600">{tab.subDescription}</p>
              </div>
              
              <div className="relative aspect-[16/9] rounded-xl overflow-hidden bg-gradient-to-br from-purple-500/10 to-blue-500/10">
                {tab.video ? (
                  // Video content
                  <video
                    src={tab.video}
                    autoPlay
                    muted
                    loop
                    playsInline
                    preload="auto"
                    className="w-full h-full"
                    style={{ 
                  
                      imageRendering: 'crisp-edges',
                      WebkitImageRendering: 'crisp-edges'
                    }}
                  >
                    <source src={tab.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  // Image slideshow content
                  <>
                    {tab.images.map((image, index) => (
                      <div
                        key={image}
                        className={`absolute inset-0 transition-opacity duration-500 ${
                          index === currentSlide ? 'opacity-100' : 'opacity-0'
                        }`}
                      >
                        <img
                          src={image}
                          alt={`${tab.name} slide ${index + 1}`}
                          className="w-full h-full"
                        />
                      </div>
                    ))}
                    
                    {/* Navigation arrows */}
                    {tab.images?.length > 1 && (
                      <>
                        <button
                          onClick={handlePrevSlide}
                          className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
                        >
                          <ChevronLeft className="w-6 h-6" />
                        </button>
                        <button
                          onClick={handleNextSlide}
                          className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
                        >
                          <ChevronRight className="w-6 h-6" />
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Add CRM Features section before the Footer */}
      <CRMFeatures />
      
      <Footer />
    </div>
  );
};

export default Home;