import React, { ReactNode } from "react";

interface DashboardCardProps {
  title: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  compact?: boolean;
  className?: string;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  icon,
  children,
  compact,
  className = "",
}) => {
  return (
    <div
      className={`bg-[#FFF7FA] rounded-2xl overflow-hidden transition-all duration-300 
        group border border-[#C8C8C8] shadow-sm hover:shadow-md ${className}`}
      style={{
        background:
          "linear-gradient(135deg, rgba(245, 245, 245, 0.3), rgba(255, 255, 255, 0.5))",
        backdropFilter: "blur(12px)",
        WebkitBackdropFilter: "blur(12px)",
        border: "1px solid rgba(200, 200, 200, 0.6)", // Soft Gray Border
      }}
    >
      <div
        className="px-10 py-6 flex items-center justify-between border-b border-[#C8C8C8]"
      >
        <div className="flex items-center space-x-4">
          {icon &&
            React.cloneElement(icon as React.ReactElement, {
              className: "w-7 h-7 text-[#6E497E]", // Even larger icon
              strokeWidth: 2,
            })}
          <h3 className="text-xl font-medium text-[#6E497E]">
            {title}
          </h3>
        </div>
      </div>
      <div
        className={`px-10 ${compact ? "pb-8" : "py-8"}`}
        style={{
          background:
            "linear-gradient(135deg, rgba(245, 245, 245, 0.3), rgba(255, 255, 255, 0.5))",
        }}
      >
        {children}
      </div>
    </div>
  );
};
