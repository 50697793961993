import { generateToken } from '../shared/generateToken';
import { getColorInfo } from '../../utils/colorUtils';
import { getClosestColor } from '../../utils/colorUtils';

export const uploadBookmark = async (item, category, selections, user) => {
  if (!user) {
    throw new Error('User must be authenticated to bookmark images');
  }

  try {
    const response = await fetch(item.url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();

    const formData = new FormData();
    formData.append('file', blob, 'image.jpg');

    const modifiedSelections = { ...selections };
    
    // Handle color selection(s)
    if (selections.color) {
      if (Array.isArray(selections.color)) {
        // Handle multiple colors
        modifiedSelections.color = selections.color
          .map(color => {
            if (typeof color === 'object') {
              return color.name || color.hex;
            }
            return color;
          })
          .join(', ');
      } else if (typeof selections.color === 'object') {
        // Handle single color object
        modifiedSelections.color = selections.color.name || selections.color.hex;
      } else {
        // Handle single color string
        modifiedSelections.color = selections.color;
      }
    }

    // Create metadata using the modified selections
    const metadataValuesList = Object.entries(modifiedSelections).map(([key, value]) => ({
      key,
      value: value?.toString() || '',
    }));

    const selectedOptions = JSON.stringify({ metadataValuesList });

    // Get color info - handle multiple colors
    let colorInfo = null;
    if (selections.color) {
      const primaryColor = Array.isArray(selections.color) 
        ? selections.color[0] 
        : selections.color;
      
      const colorHex = typeof primaryColor === 'object' 
        ? primaryColor.hex 
        : primaryColor;
      
      colorInfo = colorHex ? getColorInfo(colorHex) : null;
    }

    const token = await generateToken();
    if (!token) {
      throw new Error('Failed to generate authentication token');
    }

    const headers = {
      'contentType': 'jpeg',
      'authService': 'firebase',
      'externalClientId': user.uid,
      'prompt': item.prompt,
      'explorePage': 'true',
      'selectedOptions': selectedOptions,
      'designCategory': `wedding ${category.toLowerCase()}`,
      'X-Auth-Token': token,
    };

    // Add RGB values to headers if colorInfo is valid
    if (colorInfo && colorInfo.rgb) {
      headers.cwRed = colorInfo.rgb.r.toString();
      headers.cwGreen = colorInfo.rgb.g.toString();
      headers.cwBlue = colorInfo.rgb.b.toString();
    }

    const uploadResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/images/upload/auth/id`, {
      method: 'POST',
      headers: headers,
      body: formData,
    });

    if (!uploadResponse.ok) {
      throw new Error(`HTTP error! status: ${uploadResponse.status}`);
    }

    return { success: true };
  } catch (error) {
    console.error('Upload error:', error);
    return { success: false, error: error.message };
  }
};