import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { UserCheck, ChevronDown, Sparkles } from 'lucide-react';
import './DesktopHeader.css';
import { CreditIcon } from '../../components/credits/CreditIcon'
import { fetchCredits } from '../../utils/credits';

import { fetchUserCredits } from '../../services/creditsService';
import PaymentPortal from '../payment/PaymentPortal';


const DesktopHeader = ({ user, handleAuthAction }) => {
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [credits, setCredits] = useState(0);
    const [showCreditsModal, setShowCreditsModal] = useState(false)

    useEffect(() => {
        if (user) {
            fetchUserCredits(user.uid)
                .then(credits => setCredits(credits))
                .catch(console.error);
        }

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsProfileOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <nav className="nav">
            {/* SVG Gradient Definition */}
            <svg width="0" height="0">
                <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" stopColor="#FF7F8E" />
                        <stop offset="50%" stopColor="#FFD6B6" />
                        <stop offset="100%" stopColor="#E7D8F0" />
                    </linearGradient>
                </defs>
            </svg>

            <ul className="nav-list">
                <li>
                    <Link to="/" className="nav-link">
                        Home
                    </Link>
                </li>
                {user && (
                    <>
                         <li><Link to="/plan" className="nav-link">Plan</Link></li>
                        <li><Link to="/design" className="nav-link">Design</Link></li>
                        <li><Link to="/explore" className="nav-link">Explore</Link></li>
                        <li>
                            <div className="relative inline-block" ref={dropdownRef}>
                                <button
                                    onClick={() => setIsProfileOpen(!isProfileOpen)}
                                    className="nav-link appearance-none bg-transparent border-0 cursor-pointer flex items-center transition-all"
                                >
                                    <div className="profile-icon">
                                        <UserCheck size={28} />
                                    </div>
                                </button>

                                {isProfileOpen && (
                                    <ul
                                        className="absolute mt-2 py-1.5 rounded-xl shadow-lg bg-white"
                                        style={{
                                            border: '1px solid rgba(255, 77, 141, 0.08)',
                                            boxShadow: '0 4px 24px rgba(0, 0, 0, 0.06)',
                                            width: '180px',
                                            right: 0,
                                            zIndex: 50,
                                        }}
                                    >
                                        <li className="px-1">
                                            <Link 
                                                to="/user-profile" 
                                                className="block px-3 py-2 text-gray-900 hover:text-[#FF4D8D] hover:bg-[#FFF7FA] rounded-lg text-sm font-medium transition-all duration-200 mx-1"
                                            >
                                                Profile
                                            </Link>
                                        </li>
                                        <li className="px-1">
                                            <button 
                                                onClick={() => setShowCreditsModal(true)}
                                                className='w-full px-3 py-2 text-gray-900 hover:text-[#FF4D8D] hover:bg-[#FFF7FA] rounded-lg text-sm font-medium text-left transition-all duration-200 mx-1 flex items-center gap-2'
                                            >
                                                <div className="flex items-center justify-center w-5 h-5 rounded-full bg-gradient-to-r from-[#FF69B4] to-[#FF7F50]">
                                                    <Sparkles className="w-3 h-3 text-white" />
                                                </div>
                                                <span className="flex items-center gap-1.5">
                                                    <span>{credits}</span>
                                                    <span className="text-gray-500">Credits</span>
                                                </span>
                                            </button>
                                        </li>
                                        <li className="px-1">
                                            <button 
                                                onClick={handleAuthAction}
                                                className='w-full px-3 py-2 text-gray-900 hover:text-[#FF4D8D] hover:bg-[#FFF7FA] rounded-lg text-sm font-medium text-left transition-all duration-200 mx-1'>
                                                Logout
                                            </button>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </li>
                    </>
                )}
                {!user && (
                    <li>
                        <button onClick={handleAuthAction} className="nav-link">
                            Sign Up
                        </button>
                    </li>
                )}
            </ul>
            {showCreditsModal && (
                <PaymentPortal
                    isOpen={showCreditsModal}
                    onClose={() => setShowCreditsModal(false)}
                    user={user}
                    fetchCredits={() => fetchCredits(user)}
                    credits={credits}
                />
            )}
        </nav>
    );
};

export default DesktopHeader; 