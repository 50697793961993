import React from 'react';
import PaymentPortal from '../payment/PaymentPortal';
import { CreditIcon } from './CreditIcon';

const CreditsDisplay = ({ source, credits, showCreditsModal, setShowCreditsModal, user, fetchCredits, isDesktop }) => {
  return (
    <>
      <div 
        className="absolute right-[60px] top-1/2 -translate-y-1/2 flex items-center gap-1.5 
          px-3 py-1.5 bg-[#FF453A]/20 backdrop-blur-[8px] rounded-2xl cursor-pointer
          shadow-[0_0_10px_rgba(255,69,58,0.15)] border border-[#FF453A]/20
          hover:shadow-[0_0_15px_rgba(255,69,58,0.2)] hover:border-[#FF453A]/30
          transition-all duration-300"
        onClick={() => setShowCreditsModal(true)}
      >
        <CreditIcon />
        <span className="text-[#FFE5E5] text-[13px] font-medium">{credits || 0}</span>
      </div>

      <PaymentPortal 
        isOpen={showCreditsModal}
        onClose={() => setShowCreditsModal(false)}
        user={user}
        fetchCredits={fetchCredits}
        credits={credits}
      />
    </>
  );
};

export default CreditsDisplay;

