import React, { useEffect, useState } from 'react';
import { Clock, Calendar, DollarSign, CheckCircle, AlertCircle, ListTodo } from 'lucide-react';
import { DashboardCard } from './DashboardCard.tsx';
import { DashboardMetric } from './DashboardMetric.tsx';
import { TaskList } from './TaskList.tsx';
import { BudgetSummary } from './BudgetSummary.tsx';
import { useTaskContext } from '../../../context/TaskContext.tsx';
import { useBudgetContext } from '../../../context/BudgetContext.tsx';
import './dashboard.css';
import { getTasks } from '../../../utils/tasks.ts';
import { getBudget } from '../../../utils/budget.ts';

interface DashboardViewProps {
  onNavigate: (view: 'dashboard' | 'task-board' | 'calendar' | 'budget' | 'wedding-plan') => void;
}

export const DashboardView: React.FC<DashboardViewProps> = ({ onNavigate }) => {
  const [tasks, setTasks] = useState({
    todoTasks: [],
    inProgressTasks: [],
    completedTasks: [],
  });
  const [budget, setBudget] = useState<number>(0);

  useEffect(() => {
    const fetchTasksAndBudget = async () => {
      const allTasks = await getTasks();
      const getRecentTasks = (status: string) => allTasks
        .filter(task => task.status === status)
        .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
        .slice(0, 3);

      const todoTasks = getRecentTasks('TODO');
      const inProgressTasks = getRecentTasks('IN_PROGRESS');
      const completedTasks = getRecentTasks('DONE');

      setTasks({ todoTasks, inProgressTasks, completedTasks });

      try {
        const budgetSections = await getBudget();
        const totalCost = budgetSections.reduce((acc, item) => acc + (item.cost || 0), 0);
        if (totalCost !== null) {
          setBudget(totalCost);
        }
      } catch (error) {
        console.error('Error fetching budget:', error);
      }
    };
    fetchTasksAndBudget();
  }, []);

  
  return (
    <div className="mt-30">
      <div className="bg-white rounded-2xl p-4 sm:p-6 min-h-screen sm:min-h-0 overflow-y-auto border shadow-sm">
        {/* Quick Stats */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mb-6">
          <DashboardMetric
            icon={<ListTodo className="w-5 h-5 text-[#E74C88]" />}
            label="Total Tasks"
            value={tasks.todoTasks.length + tasks.inProgressTasks.length + tasks.completedTasks.length}
            bgColor="bg-[#F8F8F8]"
          />
          <DashboardMetric
            icon={<AlertCircle className="w-5 h-5 text-[#E74C88]" />}
            label="High Priority"
            value={tasks.todoTasks.filter(task => task.priority === 'high').length}
            bgColor="bg-[#F8F8F8]"
          />
          <DashboardMetric
            icon={<CheckCircle className="w-5 h-5 text-[#E74C88]" />}
            label="Completed"
            value={tasks.completedTasks.length}
            bgColor="bg-[#F8F8F8]"
          />
          <DashboardMetric
            icon={<DollarSign className="w-5 h-5 text-[#E74C88]" />}
            label="Total Budget"
            value={`$${budget.toLocaleString()}`}
            bgColor="bg-[#F8F8F8]"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Left Column - Task Lists */}
          <div className="space-y-4">
            {/* Upcoming Tasks */}
            <DashboardCard
              title="Upcoming Tasks"
              icon={<Calendar className="w-5 h-5 text-[#E74C88]" />}
              className="bg-white shadow-sm hover:shadow-md transition-shadow"
              compact
            >
              <TaskList 
                tasks={tasks.todoTasks} 
                emptyMessage="No upcoming tasks"
                onNavigateToTaskboard={() => onNavigate('task-board')}
              />
            </DashboardCard>

            {/* Pending Tasks */}
            <DashboardCard
              title="Pending Tasks"
              icon={<Clock className="w-5 h-5 text-[#E74C88]" />}
              className="bg-white shadow-sm hover:shadow-md transition-shadow"
              compact
            >
              <TaskList 
                tasks={tasks.inProgressTasks} 
                emptyMessage="No pending tasks"
                onNavigateToTaskboard={() => onNavigate('task-board')}
              />
            </DashboardCard>

            {/* Completed Tasks */}
            <DashboardCard
              title="Completed Tasks"
              icon={<CheckCircle className="w-5 h-5 text-[#E74C88]" />}
              className="bg-white shadow-sm hover:shadow-md transition-shadow"
              compact
            >
              <TaskList 
                tasks={tasks.completedTasks} 
                emptyMessage="No completed tasks"
                onNavigateToTaskboard={() => onNavigate('task-board')}
              />
            </DashboardCard>
          </div>

          {/* Right Column - Budget Overview */}
          <DashboardCard
            title="Budget Overview"
            icon={<DollarSign className="w-5 h-5 text-[#E74C88]" />}
            className="bg-white shadow-sm hover:shadow-md transition-shadow"
          >
            <BudgetSummary onNavigateToBudget={() => onNavigate('budget')} />
          </DashboardCard>
        </div>
      </div>
    </div>
  );
};