import React from 'react';
import { X, Download } from 'lucide-react';
import './ImagePopup.css';

const ImagePopup = ({ image, onClose }) => {
  
  return (
    <div className="image-popup-overlay">
      <div className="image-popup-content">
        <button className="close-button" onClick={onClose}>
          <X size={24} />
        </button>
        <img src={image.s3Url} alt={`Image ${image.id}`} className="popup-image" />
      </div>
    </div>
  );
};

export default ImagePopup;

