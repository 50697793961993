import React from 'react';
import { format } from 'date-fns';
import { Task } from '../kanban/types.ts'
import { Calendar, Flag, ArrowRight } from 'lucide-react';

const getPriorityStyle = (priority: string) => {
  switch (priority.toLowerCase()) {
    case 'low':
      return {
        background: '#E8F7F2',
        color: '#2B9B76',
        border: '1px solid #A8E6CF'
      };
    case 'medium':
      return {
        background: '#FFF3EA',
        color: '#E67E22',
        border: '1px solid #EFB087'
      };
    case 'high':
      return {
        background: '#FFE9EB',
        color: '#E74C3C',
        border: '1px solid #FF7F8E'
      };
    default:
      return {
        background: '#E8F7F2',
        color: '#2B9B76',
        border: '1px solid #A8E6CF'
      };
  }
};

interface TaskListProps {
  tasks: Task[];
  emptyMessage: string;
  onNavigateToTaskboard: () => void;
}

export const TaskList: React.FC<TaskListProps> = ({ tasks, emptyMessage, onNavigateToTaskboard }) => {
  if (tasks.length === 0) {
    return (
      <div className="text-center py-2 text-gray-500/80 text-sm">
        <p>{emptyMessage}</p>
      </div>
    );
  }

  return (
    <div className="space-y-2.5">
      {tasks.map(task => {
        const priorityStyle = getPriorityStyle(task.priority);
        
        return (
          <div
            key={task.id}
            className="group transition-all duration-300 bg-white border border-[#E6A5B5] rounded-xl shadow-sm hover:shadow-md hover:bg-[#FAFAFA] mt-6 first:mt-2"
          >
            <div className="p-3 pt-4 relative overflow-hidden">
              <div className="relative flex items-center justify-between">
                <h4 className="font-medium text-gray-800 text-sm truncate max-w-[60%]">{task.name}</h4>
                <div className="flex items-center space-x-2 flex-shrink-0">
                  {task.dueDate && (
                    <div className="flex items-center text-gray-600 bg-white/30 px-2 py-1 rounded-full whitespace-nowrap">
                      <Calendar className="w-3 h-3 mr-1 flex-shrink-0" />
                      <span className="text-xs">
                        {format(new Date(task.dueDate), 'MMM d')}
                      </span>
                    </div>
                  )}
                  <div 
                    className="flex items-center px-2 py-1 rounded-full flex-shrink-0"
                    style={priorityStyle}
                  >
                    <Flag className="w-3 h-3 mr-1 flex-shrink-0" style={{ color: priorityStyle.color }} />
                    <span className="text-xs whitespace-nowrap" style={{ color: priorityStyle.color }}>
                      {task.priority.toUpperCase()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      
      <button
        onClick={onNavigateToTaskboard}
        className="w-full mt-3 flex items-center justify-center space-x-2 px-4 py-2.5 rounded-xl 
          bg-[#FDF8F2] border border-[#FFD3DA] shadow-sm hover:shadow-md hover:bg-[#FDF8F2]
          transition-all duration-300 group relative overflow-hidden"
      >
        <span className="text-sm font-medium text-[#FF4D8D] group-hover:text-[#FF4D8D] transition-colors">
          View Taskboard
        </span>
        <ArrowRight className="w-4 h-4 text-[#6E497E] transform group-hover:translate-x-1 transition-transform" />
      </button>
    </div>
  );
};