import { generateAuthToken } from '../utils/encryptExternalID';

/**
 * Fetches the credits for a given user from the API.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<number|null>} - The number of credits, or null if the user ID is missing.
 * @throws {Error} - If the API request fails or the response is invalid.
 */
export const fetchUserCredits = async (userId) => {
  // Validate userId
  if (!userId) {
    console.error('User ID is missing');
    return null;
  }

  try {
    // Generate authentication token
    const token = await generateAuthToken();

    // Make API request to fetch credits
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/login/generation/credits/left?authService=firebase&externalClientId=${userId}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'X-Auth-Token': token,
        },
      }
    );

    // Check if the response is successful
    if (response.ok) {
      const creditsData = await response.text();

      // Convert the response to a number
      const creditsNumber = Number(creditsData);

      // Validate the credits value
      if (isNaN(creditsNumber)) {
        throw new Error('Invalid credits value received from API');
      }

      return creditsNumber;
    }

    // Handle non-OK responses
    throw new Error(`Failed to fetch credits: ${response.statusText}`);
  } catch (error) {
    console.error('Error fetching credits:', error);
    throw error;
  }
};

/**
 * Updates the credits for a user after a generation process is complete.
 * @param {string} userId - The ID of the user.
 * @param {boolean} isGenerationComplete - Whether the generation process is complete.
 * @returns {Promise<number|null>} - The updated number of credits, or null if generation is not complete.
 * @throws {Error} - If the credit update fails.
 */
export const updateCreditsAfterGeneration = async (userId, isGenerationComplete) => {
  try {
    // Check if generation is complete
    if (!isGenerationComplete) {
      console.log('Generation not complete, skipping credit update');
      return null;
    }

  
    const updatedCredits = await fetchUserCredits(userId);

    return updatedCredits;
  } catch (error) {
    console.error('Error updating credits after generation:', error);
    throw error;
  }
};