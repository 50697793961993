import React from 'react';
import { Plus, ChevronLeft, ChevronRight } from 'lucide-react';
import { EventFilter } from './EventFilter.tsx';

interface CalendarHeaderProps {
  selectedFilter: 'all' | 'tasks' | 'events';
  onFilterChange: (filter: 'all' | 'tasks' | 'events') => void;
  onAddEvent: () => void;
  onYearChange: (direction: 1 | -1) => void;
  selectedYear: number;
}

export const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  selectedFilter,
  onFilterChange,
  onAddEvent,
  onYearChange,
}) => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-2">
          <EventFilter selectedFilter={selectedFilter} onFilterChange={onFilterChange} />
          <button
            onClick={onAddEvent}
            className="flex items-center px-2 py-1.5 text-xs sm:text-sm text-white rounded-lg 
              bg-[#6E497E]
              hover:shadow-[0_4px_12px_rgba(184,167,233,0.3)] 
              transition-all duration-300"
          >
            <Plus className="w-3 h-3 sm:w-4 sm:h-4 mr-1" />
            Add Event
          </button>
        </div>
        
        <div className="flex items-center gap-4">
          <button
            onClick={() => onYearChange(-1)}
            className="p-1.5 rounded-full text-[#6E497E] hover:bg-[#FFF7FA] 
              transition-all duration-300 group"
          >
            <ChevronLeft className="w-4 h-4 group-hover:scale-110 transition-transform" />
          </button>
          <button
            onClick={() => onYearChange(1)}
            className="p-1.5 rounded-full text-[#6E497E] hover:bg-[#FFF7FA] 
              transition-all duration-300 group"
          >
            <ChevronRight className="w-4 h-4 group-hover:scale-110 transition-transform" />
          </button>
        </div>
      </div>
    </div>
  );
};