import React from 'react';

interface StepComponentProps {
  step: {
    id: string;
    title: string;
    type: 'grid' | 'color-picker';
    options: Array<{
      name: string;
      image?: string;
      hex?: string;
      subOptions?: string[];
    }>;
  };
  selections: Record<string, any>;
  subSelections: Record<string, any>;
  onSelectionChange: (stepId: string, value: any) => void;
  onSubSelectionChange: (stepId: string, value: any) => void;
}

export const StepComponent: React.FC<StepComponentProps> = ({
  step,
  selections,
  subSelections,
  onSelectionChange,
  onSubSelectionChange,
}) => (
  <div>
    <label className="block text-xl font-xlarge text-gray-700 mb-3">{step.title}</label>
    {step.type === 'grid' && (
      <div className="grid grid-cols-2 gap-4">
        {step.options.map((option) => (
          <div key={option.name} className="flex flex-col">
            <div className="relative">
              <button
                onClick={() => onSelectionChange(step.id, option.name.toLowerCase())}
                className={`relative group overflow-hidden rounded-lg aspect-square w-full transition-all duration-300 ${
                  selections[step.id]?.includes(option.name.toLowerCase())
                    ? 'ring-2 ring-black scale-105'
                    : 'hover:scale-105'
                }`}
              >
                <div className="absolute inset-0">
                  {option.image && (
                    <img
                      src={option.image}
                      alt={option.name}
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-black/20 to-transparent" />
                <div className="absolute inset-x-0 bottom-0 p-4">
                  <span className="text-white text-lg font-semibold drop-shadow-lg">
                    {option.name}
                  </span>
                </div>
                {selections[step.id]?.includes(option.name.toLowerCase()) && (
                  <div className="absolute top-3 right-3">
                    <div className="bg-white rounded-full p-1">
                      <svg
                        className="w-4 h-4 text-black"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </button>
            </div>
            {option.subOptions && selections[step.id]?.includes(option.name.toLowerCase()) && (
              <select
                value={subSelections[step.id] || ''}
                onChange={(e) => onSubSelectionChange(step.id, e.target.value)}
                className="mt-3 w-full p-3 border-2 border-gray-200 rounded-lg bg-white 
                shadow-md hover:border-gray-300 focus:border-[#FF4D8D] focus:ring-2 
                focus:ring-[#FF4D8D]/20 focus:outline-none transition-all duration-200 
                appearance-none cursor-pointer text-gray-700 font-medium"
                style={{
                  backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right 1rem center',
                  backgroundSize: '1em',
                  paddingRight: '2.5rem'
                }}
              >
                <option value="" className="text-gray-500">Select {option.name} Style</option>
                {option.subOptions.map((subOption) => (
                  <option 
                    key={subOption} 
                    value={subOption.toLowerCase()}
                    className="py-2"
                  >
                    {subOption}
                  </option>
                ))}
              </select>
            )}
          </div>
        ))}
      </div>
    )}
    {step.type === 'color-picker' && (
      <div className="grid grid-cols-4 gap-4">
        {step.options.map((color) => {
          const isSelected = Array.isArray(selections[step.id]) 
            ? selections[step.id]?.includes(color.name.toLowerCase())
            : selections[step.id] === color.name.toLowerCase();
            
          return (
            <button
              key={color.name}
              onClick={() => onSelectionChange(step.id, color.name.toLowerCase())}
              className={`relative w-full aspect-square rounded-lg border-2 border-solid transition-all ${
                isSelected ? 'border-black scale-105' : 'border-gray-300 hover:scale-105'
              }`}
              style={{ backgroundColor: color.hex || 'transparent' }}
              title={color.name}
            >
              {isSelected && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/20 rounded-lg">
                  <svg
                    className="w-6 h-6 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              )}
              <div className="absolute inset-x-0 bottom-0 p-2">
                <span className="text-[10px] sm:text-xs font-semibold text-white drop-shadow-lg [text-shadow:_0.5px_0.5px_0.5px_rgb(0_0_0),_-0.5px_-0.5px_0.5px_rgb(0_0_0),_0.5px_-0.5px_0.5px_rgb(0_0_0),_-0.5px_0.5px_0.5px_rgb(0_0_0)]">
                  {color.name}
                </span>
              </div>
            </button>
          );
        })}
      </div>
    )}
  </div>
); 