import React, { ReactNode } from 'react';

interface DashboardMetricProps {
  icon: ReactNode;
  label: string;
  value: string | number;
  type: 'tasks' | 'priority' | 'completed' | 'budget';
}

const getIconColor = (type: 'tasks' | 'priority' | 'completed' | 'budget') => {
  switch (type) {
    case 'tasks':
      return '#E7D8F0'; // Muted Lavender
    case 'priority':
      return '#FF7F8E'; // Soft Coral
    case 'completed':
      return '#D0E3FF'; // Pastel Sky Blue
    case 'budget':
      return '#FFD6B6'; // Warm Peach
    default:
      return '#FF7F8E';
  }
};

export const DashboardMetric: React.FC<DashboardMetricProps> = ({
  icon,
  label,
  value,
  type,
}) => {
  return (
    <div 
      className="relative overflow-hidden rounded-xl transition-all duration-300 group bg-white border border-[#FFD3DA] shadow-sm hover:shadow-md hover:bg-[#FAFAFA]"
    >
      <div 
        className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        style={{
         
          pointerEvents: 'none'
        }}
      />
      
      <div className="p-4 relative">
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-3">
          <div 
            className="p-2.5 sm:p-3 rounded-xl"
            style={{
              background: 'rgba(255, 255, 255, 0.1)',
              backdropFilter: 'blur(8px)',
              WebkitBackdropFilter: 'blur(8px)',
              border: '1px solid rgba(255, 255, 255, 0.2)',
            }}
          >
            {React.cloneElement(icon as React.ReactElement, {
              style: { color: getIconColor(type) },
              strokeWidth: 2
            })}
          </div>
          <div className="text-center sm:text-left">
            <p className="text-sm text-gray-600">{label}</p>
            <p className="text-lg sm:text-xl font-bold text-[#FF4D8D]">
              {value}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};