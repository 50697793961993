import React from 'react';

const Spinner = ({ text = 'Loading...', color = 'text-yellow-500' }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen mt-20">
      <div 
        className={`w-48 h-48 border-8 border-t-8 border-transparent rounded-full animate-spin ${color}`}
        style={{ borderTopColor: 'currentColor' }}
      ></div>
      <p className={`mt-10 text-2xl font-semibold ${color}`}>{text}</p>
    </div>
  );
};

export default Spinner;
