import React, { useEffect, useState } from 'react';
import { ArrowRight } from 'lucide-react';
import { useBudgetContext } from '../../../context/BudgetContext.tsx';
import { BudgetVisualization } from './BudgetVisualization.tsx';
import { getBudget } from '../../../utils/budget.ts';

interface BudgetSummaryProps {
  onNavigateToBudget: () => void;
}

export const BudgetSummary: React.FC<BudgetSummaryProps> = ({ onNavigateToBudget }) => {
  const { budgetData } = useBudgetContext();
  const [budget, setBudget] = useState<number>(0);
  const [categories, setCategories] = useState<{ id: string; name: string; percentage: number; color: string; }[]>([]);

  const colorDictionary = budgetData.categories.reduce((acc, category) => {
    acc[category.name] = category.color; // Map name to color
    return acc;
  }, {} as Record<string, string>); // Initialize as an empty object with string keys and values

  useEffect(() => {
    const fetchBudgetData = async () => {
      try {
        const budgetSections = await getBudget();
        const totalCost = budgetSections.reduce((acc, item) => acc + (item.cost || 0), 0);
        if (totalCost !== null) {
          setBudget(totalCost);
        }
        
        const transformedCategories = budgetSections.map(item => ({
            id: item.id, 
            name: item.name,
            percentage: item.percentage,
            color: colorDictionary[item.name], 
        })); 
        setCategories(transformedCategories); 

      } catch (error) {
        console.error('Error fetching budget:', error);
      }
    };
    fetchBudgetData()
  }, [])

  // Get top spending categories
  const topCategories = [...categories]
    .sort((a, b) => b.percentage - a.percentage)
    .slice(0, 4);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-sm text-gray-500">Total Budget</p>
          <p className="text-2xl font-bold text-gray-800">
            ${budget.toLocaleString()}
          </p>
        </div>
        <div className="text-right">
        
        </div>
      </div>

      <div>
        <h4 className="text-sm font-medium text-gray-700 mb-3">Top Expenses</h4>
        <div className="space-y-2">
          {topCategories.map(category => {
            const amount = Math.round((category.percentage / 100) * budget);
            return (
              <div key={category.id} className="flex items-center">
                <div className="flex-1">
                  <div className="flex items-center justify-between mb-1">
                    <span className="text-sm text-gray-600">{category.name}</span>
                    <span className="text-sm font-medium text-gray-800">
                      ${amount.toLocaleString()}
                    </span>
                  </div>
                  <div className="w-full bg-gray-100 rounded-full h-2">
                    <div
                      className="h-2 rounded-full"
                      style={{
                        width: `${category.percentage}%`,
                        backgroundColor: category.color,
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <BudgetVisualization categories={categories} />
      
      <button
        onClick={onNavigateToBudget}
        className="w-full mt-2 sm:mt-4 flex items-center justify-center space-x-2 px-3 sm:px-4 py-1.5 sm:py-2 
          bg-[#FF4D8D] hover:bg-[#E74C88] 
          text-white rounded-lg shadow-sm 
          hover:shadow-[0_2px_8px_rgba(255,77,141,0.3)]
          transition-all duration-300 group text-sm sm:text-base"
      >
        <span>View Full Budget Details</span>
        <ArrowRight className="w-3 h-3 sm:w-4 sm:h-4 transform group-hover:translate-x-1 transition-transform" />
      </button>
    </div>
  );
};