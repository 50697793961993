import React from 'react';

interface ProgressBarProps {
  progress: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => (
  <div className="w-full h-full rounded-3xl relative overflow-hidden bg-gradient-to-r from-[#FF69B4] to-[#FF7F50] animate-gradient-x">
    <style jsx>{`
      @keyframes gradient-x {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
      }
      .animate-gradient-x {
        background-size: 200% 200%;
        animation: gradient-x 3s ease infinite;
      }
    `}</style>
    <div className="loading-gradient" />
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
      <svg className="w-10 h-10 text-white opacity-80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M21 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H21C22.1046 21 23 20.1046 23 19V5C23 3.89543 22.1046 3 21 3Z" />
        <path d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z" />
        <path d="M21 15L16 10L5 21" />
      </svg>
    </div>
    <div className="absolute bottom-8 left-1/2 -translate-x-1/2 w-3/4 z-20">
      <div className="relative w-full h-1 bg-white/30 rounded-full overflow-hidden">
        <div 
          className="absolute top-0 left-0 h-full bg-white rounded-full transition-all duration-300 ease-in-out" 
          style={{ width: `${progress}%` }}
        />
      </div>
      <div className="mt-4 text-center">
        <span className="text-white text-lg font-medium">{progress}%</span>
      </div>
    </div>
  </div>
); 